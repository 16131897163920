import { encode } from 'querystring';
import api from '~/config/api';
import { baseURL } from '~/config/api';
import { downloadDocuments, openItauShopline } from '~/utils/util';

export const getNegotiations = async ({ data_inicial, data_final, one_status, emp_codigo, emp_tipo }) => {
  try {
    const { data } = await api.post('/api/orcamentos/carregar-todos', {
      data_inicial,
      data_final,
      one_status,
      emp_codigo,
      emp_tipo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getNegotiationHistory = async (orc_codigo) => {
  try {
    const { data } = await api.post('/api/orcamentos/carregar-historico', {
      orc_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const sendNegotiationResponse = async ({ emp_codigo, orc_codigo, ofe_valor, one_status, one_descricao }) => {
  try {
    const { data } = await api.post('/api/orcamentos/enviar', {
      emp_codigo,
      orc_codigo,
      ofe_valor,
      one_status,
      one_descricao,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getPaymentOptions = async (emp_codigo) => {
  try {
    const { data } = await api.post('/api/orcamentos/carregar-formas-pagamento', {
      emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const permuteOfferBuy = async ({
  ofe_codigo,
  orc_codigo,
  fpa_codigo,
  ofe_valor,
  usu_codigo,
  emp_codigo,
  emp_codmatriz,
  one_descricao,
  asaas_ativo = 'N',
}) => {
  try {
    const { data } = await api.post('/api/orcamentos/salvar-transacao-compra', {
      ofe_codigo,
      fpa_codigo,
      ofe_valor,
      usu_codigo,
      emp_codigo,
      emp_codmatriz,
      orc_codigo,
      one_descricao,
      asaas_ativo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const permuteOfferSell = async ({
  ofe_codigo,
  orc_codigo,
  fpa_codigo,
  ofe_valor,
  usu_codigo,
  emp_codigo,
  emp_codmatriz,
  tra_codigo,
}) => {
  try {
    const { data } = await api.post('/api/orcamentos/salvar-transacao-venda', {
      ofe_codigo,
      fpa_codigo,
      ofe_valor,
      usu_codigo,
      emp_codigo,
      emp_codmatriz,
      orc_codigo,
      tra_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const requestQuote = async ({ emp_codigo, ofe_codigo, ofe_valor, one_descricao, emp_codmatriz }) => {
  try {
    const { data } = await api.post('/api/orcamentos/solicitar-orcamento', {
      emp_codigo,
      ofe_codigo,
      ofe_valor,
      one_descricao,
      emp_codmatriz,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const requestQuoteAndPermuteOfferBuy = async ({
  ofe_codigo,
  fpa_codigo,
  ofe_valor,
  usu_codigo,
  emp_codigo,
  emp_codmatriz,
  asaas_ativo = 'N',
}) => {
  try {
    const { data } = await api.post('/api/orcamentos/salvar-orcamento-transacao-compra', {
      ofe_codigo,
      fpa_codigo,
      ofe_valor,
      usu_codigo,
      emp_codigo,
      emp_codmatriz,
      asaas_ativo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const loginAndPermute = async ({
  usu_login,
  usu_senha,
  fpa_descricao,
  one_descricao,
  ofe_codigo,
  check_compra_hoje,
}) => {
  try {
    const { data } = await api.post('/api/orcamentos/login-permutar', {
      usu_login,
      usu_senha,
      fpa_descricao,
      one_descricao,
      ofe_codigo,
      check_compra_hoje,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const checkBuyOfferToday = async ({ ofe_codigo, emp_codigo }) => {
  try {
    const { data } = await api.post('/api/orcamentos/verificar-compra-da-oferta-hoje', {
      ofe_codigo,
      emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const resendPayment = async ({ ofe_codigo, emp_codigo, emp_codmatriz, tra_tipo, tra_codigo }) => {
  try {
    const { data } = await api.post('/api/orcamentos/reenviar-pagamento', {
      ofe_codigo,
      emp_codigo,
      emp_codmatriz,
      tra_tipo,
      tra_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const autoCompleteAssociatePerFranchise = async ({ value, emp_codigo }) => {
  try {
    const { data } = await api.get('/api/orcamentos/auto-complete-associados', {
      params: {
        q: value,
        e: emp_codigo,
      },
    });
    return data;
  } catch (error) {
    return { error };
  }
};

export const exportAssociateStatementToPDF = async ({ emp_codigo, inicio, fim, filtro_data, status }) => {
  const url = `${baseURL}/api/orcamentos/exportar-extrato-associado-da-franquia?${encode({
    emp_codigo,
    inicio,
    fim,
    filtro_data,
    status,
  })}`;

  downloadDocuments(url, `ExtratoAssociado.pdf`, false);
};

export const exportAssociateStatementToXLS = async ({ emp_codigo, disableFilterDate, dateStart, dateEnd, status }) => {
  const url = `${baseURL}/api/orcamentos/exportar-extrato-associado-da-franquia-xls?${encode({
    e: emp_codigo,
    f: disableFilterDate,
    di: dateStart,
    df: dateEnd,
    b: status,
  })}`;

  downloadDocuments(url, `ExtratoAssociado.xls`, false);
};

export const exportAssociatesStatementToXLS = async ({ emp_codigo, disableFilterDate, dateStart, dateEnd, status }) => {
  const url = `${baseURL}/api/orcamentos/exportar-extrato-associados-da-franquia-xls?${encode({
    e: emp_codigo,
    f: disableFilterDate,
    di: dateStart,
    df: dateEnd,
    b: status,
  })}`;

  downloadDocuments(url, `ExtratoAssociado.xls`, false);
};

export const calcCommission = async ({ ofe_codigo, emp_codigo, e_compra, valor, e_vpmix }) => {
  try {
    const { data } = await api.get('/api/orcamentos/calcula-comissao', {
      params: {
        ofe_codigo,
        emp_codigo,
        e_compra,
        valor,
        e_vpmix,
      },
    });

    return data;
  } catch (error) {
    return { success: false, message: 'Erro interno. Por favor, recarregue a página e tente novamente.' };
  }
};

/**
 *
 * @param {{
 *  ofe_codigo: string,
 *  emp_codigo: string,
 *  emp_codmatriz: string,
 *  tra_tipo: 'Compra' | 'Venda',
 *  tra_codigo: string,
 * }} param0
 */
export const handleResendPayment = async ({ ofe_codigo, emp_codigo, emp_codmatriz, tra_tipo, tra_codigo }) => {
  const {
    payload: { dados },
  } = await resendPayment({
    ofe_codigo,
    emp_codigo,
    emp_codmatriz,
    tra_tipo,
    tra_codigo,
  });
  openItauShopline(dados);
  location.reload();
};
