import api from '~/config/api';

export const getCashFlowData = async ({ emp_codigo, cba_codigo, mes, ano }) => {
  try {
    const { data } = await api.post('/api/fluxo-caixa/carregar', {
      emp_codigo,
      cba_codigo,
      mes,
      ano,
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const getRegisterPerDay = async ({
  emp_codigo,
  cba_codigo,
  dia,
  mes,
  ano,
}) => {
  try {
    const { data } = await api.post('/api/fluxo-caixa/carregar-parcelas-por-dia', {
      emp_codigo,
      cba_codigo,
      dia,
      mes,
      ano,
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
}