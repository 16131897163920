import React, { useRef } from 'react';
import { SelectControlledOption, SelectControlledProps } from '~/types';
import { Controller } from 'react-hook-form';
import Select, { ActionMeta, createFilter, FocusEventHandler, OptionsType, OptionTypeBase, ValueType } from 'react-select';

  const SelectControlled: <T extends SelectControlledOption>(props: SelectControlledProps<T>) => JSX.Element = ({
  hasError,
  label,
  isRequired,
  name,
  control,
  defaultValue,
  isLoading,
  options,
  isDisabled,
  onBlur,
  isMulti,
  formatOptionLabel,
  filterOptions,
  placeholder = 'Selecione',
  isClearable,
  ...rest
}) => {
  const selectRef = useRef<HTMLDivElement | any>(null);

  return (
    <div className={`form-group ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      <label className="label-with-loading">
        {label}
        {isRequired && <span className="star" />}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        onFocus={() => {
          selectRef.current.focus();
        }}
        render={({ onChange, value }) => (
          <Select
            {...rest}
            ref={selectRef}
            openMenuOnFocus
            className="react-select-container"
            classNamePrefix="react-select"
            isLoading={isLoading}
            isClearable={isClearable}
            value={
              isMulti
                ? value
                  ? options.filter((o) => value.includes(o.value))
                  : []
                : options.find((o) => o.value === value) || null
            }
            isDisabled={isDisabled}
            placeholder={placeholder}
            onChange={(optionSelected: ValueType<SelectControlledOption, false> | ValueType<SelectControlledOption, true>, actionMeta: ActionMeta<SelectControlledOption>) => {
              if (optionSelected) {
                if (isMulti) {
                  const option = optionSelected as OptionsType<SelectControlledOption>;
                  onChange(option?.map((o) => o.value));
                } else {
                  const option = optionSelected as SelectControlledOption;
                  onChange(option?.value);
                }
              } else {
                onChange(undefined)
              }
            }}
            options={options}
            onBlur={onBlur}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => ''}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            formatOptionLabel={formatOptionLabel}
            filterOption={filterOptions ? createFilter(filterOptions) : undefined}
          />
        )}
      />
    </div>
  );
};

export default SelectControlled;
