import api from '~/config/api';

export const getMyAccountStatement = async ({ emp_codigo, emp_tipo, inicio, fim, filtro_data }) => {
    try {
        const { data } = await api.post('/api/extratos-conta/carregar', {
            emp_codigo,
            emp_tipo,
            inicio,
            fim,
            filtro_data,
        })
        return data
    } catch (error) {
        return error
    }
}