import React from 'react';
import { Helmet } from 'react-helmet';

const Header: React.FC = () => {
  return (
    <>
      <Helmet title={`Login - Socicoin`} />
     {/*  <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute">
        <div className="container">
          <div className="navbar-wrapper">
            <a className="navbar-brand" href="#pablo">
              A plataforma de negócios inteligentes :)
            </a>
          </div>
          <div className="collapse navbar-collapse justify-content-end" id="navbar">
            <ul className="navbar-nav">
              <li className="nav-item  active ">
                <a href="/" className="nav-link align-items-center">
                  <i className="nc-icon nc-mobile"></i> Login
                </a>
              </li>
              <li className="nav-item">
                <a href="http://socicoin.org" target="_blank" className="nav-link align-items-center">
                  <i className="nc-icon nc-chart-pie-35"></i> Site
                </a>
              </li>
              <li className="nav-item ">
                <a href="https://www.facebook.com/socicoin" target="_blank" className="nav-link align-items-center">
                  <i className="nc-icon nc-badge"></i> Facebook
                </a>
              </li>
              <li className="nav-item ">
                <a href="https://www.instagram.com/socicoin" target="_blank" className="nav-link align-items-center">
                  <i className="nc-icon nc-badge"></i> Instagram
                </a>
              </li>
              <li className="nav-item ">
                <a
                  href="https://br.linkedin.com/in/info-in1social-9447471a5"
                  target="_blank"
                  className="nav-link align-items-center"
                >
                  <i className="nc-icon nc-badge"></i> Linkedin
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
    </>
  );
};

export default Header;
