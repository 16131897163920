import React, { useContext, useEffect, useState } from 'react';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';

import PageLoading from '~/components/loading/PageLoading';
import { baseURL } from '~/config/api';
import { AuthContext } from '~/context/AuthContext';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicInput from '~/components/input/BasicInput';
import MaskCPFControlled from '~/components/input/MaskCPFControlled';
import SelectControlled from '~/components/input/SelectControlled';
import MaskPhoneControlled from '~/components/input/MaskPhoneControlled';
import { formatDateToDefaultPattern } from '~/utils/util';
import { getMyProfile, saveMyProfile, uploadProfilePhoto } from '~/service/UserService';
import { EmpresaUsuario } from '~/types';
import Dropzone from 'react-dropzone';
import { showAlertDanger, showAlertSuccess, showAlertWarning } from '~/components/notification';
import moment from 'moment';

interface FormInput {
  usu_nome: string; //required
  usu_login: string; //required
  usu_email: string; //required
  usu_cpf?: string;
  usu_tratamento?: string;
  usu_sexo?: string;
  usu_telefone?: string;
  usu_dataaniver?: Date;
  usu_senha?: string;
  usu_senha_confirma?: string;
  empresa_padrao: number;
}

const validationSchema: yup.SchemaOf<FormInput> = yup.object().shape({
  usu_nome: yup.string().required(),
  usu_login: yup.string().required(),
  usu_email: yup.string().required(),

  usu_cpf: yup.string().matches(/^[0-9]{11}$|^$/),
  usu_tratamento: yup.string(),
  usu_dataaniver: yup.date().default(undefined),
  usu_sexo: yup.string().oneOf(['M', 'F', '']),
  usu_telefone: yup.string().matches(/^[0-9]{11}$|^$/),
  usu_senha: yup.string(),
  usu_senha_confirma: yup.string(),
  empresa_padrao: yup.number().required(),
});

const meuPerfil: React.FunctionComponent = () => {
  const { user: usuario, company: empresa } = useContext(AuthContext);
  const [fotoPerfil, setFotoPerfil] = useState('');
  const [empresasUsuario, setEmpresasUsuario] = useState<EmpresaUsuario[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [awaitResponse, setAwaitResponse] = useState(false);
  const [isSendingPhoto, setIsSendingPhoto] = useState(false);

  useEffect(() => {
    fetchMyProfile();
  }, []);

  const fetchMyProfile = async () => {
    setIsLoading(true);
    const {
      payload: { empresasUsuario, fotoPerfil },
    } = await getMyProfile(usuario.USU_CODIGO);

    setFotoPerfil(fotoPerfil);
    setEmpresasUsuario(empresasUsuario);
    setIsLoading(false);
  };

  const {
    watch,
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formInput: FormInput) => {
    setAwaitResponse(true);
    const { success, message, type, payload } = await saveMyProfile({
      usu_codigo: usuario.USU_CODIGO,
      empresa_padrao: formInput.empresa_padrao,
      usu_nome: formInput.usu_nome,
      usu_email: formInput.usu_email,
      usu_login: formInput.usu_login,
      usu_cpf: formInput.usu_cpf || '',
      usu_dataaniver: formInput.usu_dataaniver ? moment(formInput.usu_dataaniver).format('DD/MM/YYYY') : '',
      usu_senha: formInput.usu_senha || '',
      usu_senha_confirma: formInput.usu_senha_confirma || '',
      usu_sexo: formInput.usu_sexo || '',
      usu_telefone: formInput.usu_telefone || '',
      usu_tratamento: formInput.usu_tratamento || '',
    });
    setAwaitResponse(false);

    if (!success) {
      if (type === 'validationError') showAlertWarning({ message: payload.erro });
      else message ? showAlertWarning({ message }) : showAlertWarning({ message: 'Ocorreu um erro inesperado. Tente novamente' });
    } else {
      showAlertSuccess({ message: 'As alterações terão efeito após o login!', timeout: 10 });
    }
  };

  const handleSubmitPhoto = async (files: File[]) => {
    const file = files[0];

    setIsSendingPhoto(true);
    const res = await uploadProfilePhoto({ photo: file, usu_codigo: usuario.USU_CODIGO });
    setIsSendingPhoto(false);

    if (res.codigo === 'save') showAlertSuccess({ message: 'Imagem alterada' });
    else showAlertDanger({ message: res.msg });
  };

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean, isSendingPhoto: boolean) => {
    return (
      <>
        {(isSendingPhoto || isDragActive || isDragReject) && (
          <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: '#9a9a9a', opacity: '1', zIndex: 1 }}>
            <div
              style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
              }}
            >
              {/* TODO: Escolher ícone */}
              <i className="fas fa-cloud-upload-alt" style={{ fontSize: '45px' }} />
              <p style={{ fontSize: '20px' }}>
                {/* Processando... */}
                {isSendingPhoto ? 'Processando...' : isDragReject ? 'Arquivo não suportado...' : isDragActive && 'Solte a imagem aqui...'}
              </p>
            </div>
          </div>
        )}
        <img className="avatar border-gray" src={baseURL + '/' + fotoPerfil} />
      </>
    );
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Ofertas disponíveis" showBalance />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="section-image" data-image="../../assets/img/bg5.jpg">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 col-sm-6">
                    <form id="genericFormId" onSubmit={handleSubmit(onSubmit)}>
                      <div className="card ">
                        <div className="card-header ">
                          <div className="card-header">
                            <h4 className="card-title">Editar perfil</h4>
                          </div>
                        </div>
                        <div className="card-body ">
                          <div className="row">
                            <div className="col-md-6 pr-1">
                              <BasicInput
                                label="Apelido ou Tratamento"
                                hasError={!!errors.usu_tratamento}
                                control={control}
                                name="usu_tratamento"
                                type="text"
                                placeholder=""
                                defaultValue={usuario.USU_TRATAMENTO || ''}
                              />
                            </div>
                            <div className="col-md-6 pl-1">
                              <BasicInput
                                label="Nome"
                                hasError={!!errors.usu_nome}
                                isRequired
                                control={control}
                                name="usu_nome"
                                type="text"
                                placeholder=""
                                defaultValue={usuario.USU_NOME || ''}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 pr-1">
                              <MaskCPFControlled
                                control={control}
                                defaultValue={usuario.USU_CPF || ''}
                                hasError={!!errors.usu_cpf}
                                name="usu_cpf"
                                label="CPF"
                                // onBlur={handleCheckExistsCPF}
                                // isLoading={isCheckingCPF}
                              />
                            </div>
                            <div className="col-md-8 pl-1">
                              <BasicInput
                                label="Email"
                                hasError={!!errors.usu_email}
                                isRequired
                                control={control}
                                name="usu_email"
                                type="email"
                                placeholder=""
                                defaultValue={usuario.USU_EMAIL || ''}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 pr-1">
                              <SelectControlled
                                control={control}
                                defaultValue={usuario.USU_SEXO || ''}
                                options={[
                                  { value: 'M', label: 'M' },
                                  { value: 'F', label: 'F' },
                                ]}
                                hasError={!!errors.usu_sexo}
                                name="usu_sexo"
                                label="Sexo"
                              />
                            </div>
                            <div className="col-md-4 px-1">
                              <MaskPhoneControlled
                                control={control}
                                defaultValue={usuario.USU_TELEFONE || ''}
                                hasError={!!errors.usu_telefone}
                                name="usu_telefone"
                                label="Telefone"
                              />
                            </div>
                            <div className="col-md-5 pl-1">
                              <BasicInput
                                label="Aniversário"
                                type="date"
                                hasError={!!errors.usu_dataaniver}
                                defaultValue={usuario.USU_DATAANIVER ? formatDateToDefaultPattern(usuario.USU_DATAANIVER).split(' ')[0] : undefined}
                                control={control}
                                name="usu_dataaniver"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 pr-1">
                              <BasicInput
                                label="Login"
                                hasError={!!errors.usu_login}
                                isRequired
                                control={control}
                                name="usu_login"
                                type="text"
                                placeholder=""
                                defaultValue={usuario.USU_LOGIN || ''}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-md-4 px-1">
                              <BasicInput
                                label="Nova senha"
                                hasError={!!errors.usu_senha}
                                control={control}
                                name="usu_senha"
                                type="password"
                                placeholder=""
                                defaultValue={''}
                                autoComplete="new-password"
                              />
                            </div>
                            <div className="col-md-4 pl-1">
                              <BasicInput
                                label="Confirmar Senha"
                                hasError={!!errors.usu_senha_confirma}
                                control={control}
                                name="usu_senha_confirma"
                                type="password"
                                placeholder=""
                                defaultValue={''}
                                autoComplete="new-password"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Empresa Padrão</label>
                                {empresasUsuario.map((empresaUsuario) => (
                                  <div className="form-check form-check-radio" key={empresaUsuario.EMP_CODIGO}>
                                    <label className="form-check-label">
                                      <input
                                        ref={register}
                                        className="form-check-input"
                                        type="radio"
                                        name="empresa_padrao"
                                        value={empresaUsuario.EMP_CODIGO}
                                        defaultChecked={empresaUsuario.UEM_PADRAO === 'S'}
                                      />
                                      <span className="form-check-sign"></span>
                                      {empresaUsuario.EMP_RAZAOSOCIAL}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                          <button type="submit" className="btn btn-wd btn-success btn-fill pull-right">
                            {awaitResponse ? (
                              <>
                                <i className="fas fa-spinner rotating" /> Processando...
                              </>
                            ) : (
                              <>
                                <i className="fa fa-save" /> Atualizar perfil
                              </>
                            )}
                          </button>

                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-4">
                    <div className="card card-user">
                      <Dropzone accept="image/*" maxSize={5242880} multiple={false} onDropAccepted={handleSubmitPhoto}>
                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                          <div
                            {...getRootProps()}
                            className="dropContainter"
                            style={{
                              borderColor: isDragReject ? '#e57878' : isDragActive ? '#78e5d5' : '',
                              display: 'contents',
                            }}
                          >
                            <input {...getInputProps()} />

                            {renderDragMessage(isDragActive, isDragReject, isSendingPhoto)}
                          </div>
                        )}
                      </Dropzone>
                      {/* <img className="avatar border-gray" src={baseURL + '/' + fotoPerfil} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default meuPerfil;
