import React, { useState, useContext, useEffect } from 'react';

import { AuthContext } from '~/context/AuthContext';
import { formatNumberWithoutPrefix, openModal } from '~/utils/util';
import { BankAccount, CashFlowData, CashFlowDataTable, FinanceQuota, FormFilterCashFlow, ViewRelatorioSaldoAssociadosProps } from '~/types';
import { getCashFlowData, getRegisterPerDay } from '~/service/financeiro/CashFlowService';
import { showAlertDanger } from '~/components/notification';
import moment, { Moment } from 'moment';
import { baseURL } from '~/config/api';
import LayoutMain from '~/layout/main';
import CardHeader from '~/components/card/CardHeader';
import TableWithLinks from '~/components/table/TableWithLinks';

const ViewRelatorioSaldoAssociados: React.FunctionComponent<ViewRelatorioSaldoAssociadosProps> = ({ exportToExcel }) => {
  return (
    <LayoutMain title="Saldo Associados">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <CardHeader
              title="Saldo Associados"
              subtitle="Exportar relatório sobre o saldo dos associados"
              actions={[{ iconClass: 'far fa-file-excel', label: ' Exportar relatório para Excel', action: exportToExcel, variant: 'btn-primary' }]}
            />
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewRelatorioSaldoAssociados;
