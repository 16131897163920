import React from 'react';

import CardHeader from '~/components/card/CardHeader/index';
import { openModal, formatCPF, formatPhone } from '~/utils/util';
import { ViewCadastroUsuariosProps } from '~/types';
import TableWithLinks from '~/components/table/TableWithLinks';
import LayoutMain from '~/layout/main';

const status: { [key: number]: { value: string; bagde: string } } = {
  1: { value: 'Ativo', bagde: 'badge-success' },
  2: { value: 'Bloqueado', bagde: 'badge-secondary' },
  3: { value: 'Inativo', bagde: 'badge-danger' },
};

const ViewCadastroUsuarios: React.FunctionComponent<ViewCadastroUsuariosProps> = ({
  headerConfig,
  ModalUser,
  ModalFilterUsers,
  isLoading,
  profile,
  user,
  users,
  openModalUserAdd,
  openModalUserEdit,
  openModalUserFilter,
}) => {
  return (
    <LayoutMain isLoading={isLoading} {...headerConfig}>
      <div className="row">
        <div className="col-md-12">
          <div className="card table-big-boy">
            <CardHeader
              title="Lista de usuários"
              subtitle="Aplique os filtros para encontrar um usuário"
              filterAction={() => openModalUserFilter('ModalFilterUsers')}
              actions={[{ label: 'cadastrar usuário', iconClass: 'fa fa-plus', variant: 'btn-success', action: openModalUserAdd }]}
            />

            {ModalUser}
            {ModalFilterUsers}

            <div className="card-body table-full-width">
              <TableWithLinks
                isLoading={isLoading}
                emptyTableMessage="Nenhum usuário encontrado. Tente alterar os filtros."
                onClickingRow={openModalUserEdit}
                columns={[
                  { field: 'cpfFormatted', label: 'CPF', alignment: 'text-center' },
                  { field: 'USU_NOME', label: 'Nome', alignment: 'text-left' },
                  { field: 'USU_TRATAMENTO', label: 'Tratamento', alignment: 'text-left' },
                  { field: 'birthdayFormatted', label: 'Aniversário', alignment: 'text-center' },
                  { field: 'USU_LOGIN', label: 'login', alignment: 'text-left' },
                  { field: 'status', label: 'Situação', alignment: 'text-center' },
                  { field: 'phoneFormatted', label: 'Telefone', alignment: 'text-center' },
                  { field: 'USU_EMAIL', label: 'Email', alignment: 'text-left' },
                ]}
                data={users.map((user) => ({
                  ...user,
                  cpfFormatted: formatCPF(user.USU_CPF),
                  phoneFormatted: formatPhone(user.USU_TELEFONE),
                  birthdayFormatted: user.USU_DATAANIVER?.split(' ')[0],
                  status: <span className={`badge ${status[+user.ITI_CODIGO].bagde}`}>{status[+user.ITI_CODIGO].value}</span>,
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewCadastroUsuarios;
