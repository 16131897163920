import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ModalFilterAccountStatementProps, HTMLElement } from '~/types';

const ModalFilter: React.FunctionComponent<ModalFilterAccountStatementProps> = ({ filtersState, fetchMyAccountStatement }) => {

  return (
    <div className="modal fade" id="filtro" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Filtrar vouchers...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form method="#" action="#" id="formFilter">
                      <div className="form-group">
                        <label>Sem filtro de data</label>
                        <Select
                          defaultValue={{ value: 'N', label: 'Não' }}
                          onChange={(e) => filtersState.setFilters({ ...filtersState.filters, disableFilterDate: e?.value === 'S' })}
                          closeMenuOnSelect={true}
                          options={[
                            { value: 'N', label: 'Não' },
                            { value: 'S', label: 'Sim' },
                          ]}
                        />
                      </div>
                      <div className="form-group">
                        <label>Início</label>
                        <input
                          type="date"
                          max='2999-12-31'
                          disabled={filtersState.filters.disableFilterDate}
                          value={filtersState.filters.startDate}
                          onChange={(e) => filtersState.setFilters({ ...filtersState.filters, startDate: e.target.value })}
                          placeholder=""
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label>Fim</label>
                        <input
                          type="date"
                          max='2999-12-31'
                          disabled={filtersState.filters.disableFilterDate}
                          value={filtersState.filters.endDate}
                          onChange={(e) => filtersState.setFilters({ ...filtersState.filters, endDate: e.target.value })}
                          placeholder=""
                          className="form-control"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" data-dismiss="modal" onClick={fetchMyAccountStatement} form="formFilter">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilter;
