import React, { useContext, useEffect, useState } from 'react';

import { Address, Associate, FormAssociate, Image, Segment, SelectControlledOption, State } from '~/types';
import { exportAssociatesToXLS, getAssociatesFranchise, saveAssociate } from '~/service/AssociateService';
import { useErrorHandler } from 'react-error-boundary';
import { AuthContext } from '~/context/AuthContext';
import { closeModal, openModal } from '~/utils/util';
import ViewModalAssociate from '~/views/ModalAssociate';
import ModalFilter from './ModalFilter';
import { showAlertDanger, showAlertSuccess, showAlertWarning } from '~/components/notification';
import ViewAssociados from '~/views/associados';

const CadastroAssociados: React.FC = () => {
  const handleError = useErrorHandler();
  const { user: usuario, company: empresa } = useContext(AuthContext);

  const [associate, setAssociate] = useState<(Associate & Address) | undefined>(undefined);
  const [associates, setAssociates] = useState<(Associate & Address)[]>([]);
  const [associatesFiltered, setAssociatesFiltered] = useState<(Associate & Address)[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);

  const [cardInactiveAssociates, setCardInactiveAssociates] = useState('');
  const [cardActiveAssociates, setCardActiveAssociates] = useState('');
  const [cardAssociatesVPMIX, setCardAssociatesVPMIX] = useState('');
  const [filterState, setFilterState] = useState({ iti_codigo: [4] });

  useEffect(() => {
    fetchAssociates(filterState.iti_codigo);
  }, [filterState]);

  useEffect(() => {
    setAssociatesFiltered(
      associates.filter((associate) => {
        const searchBoxValueLower = searchBoxValue.toLowerCase();
        return (
          associate.EMP_RAZAOSOCIAL.toLowerCase().includes(searchBoxValueLower) ||
          associate.EMP_NOMEFANTASIA.toLowerCase().includes(searchBoxValueLower)
        );
      })
    );
  }, [searchBoxValue, associates]);

  const fetchAssociates = async (iti_codigo?: number[]) => {
    try {
      setIsLoading(true);
      const { success, payload, error } = await getAssociatesFranchise({
        emp_codigo: empresa.EMP_CODIGO,
        iti_codigo: iti_codigo && iti_codigo.length > 0 ? `(${iti_codigo})` : '',
      });
      setIsLoading(false);

      if (success) {
        const { associados, segmentos, estados, cardAtivos, cardInativos, cardVPMIX } = payload;

        const uniqueAssociates = associados.filter((associate: Associate & Address, index: number, self: (Associate & Address)[]) =>
          index === self.findIndex((t) => t.EMP_CODIGO === associate.EMP_CODIGO)
        );

        setAssociates(uniqueAssociates);
        setSegments(segmentos);
        setStates(estados);

        setCardInactiveAssociates(cardInativos);
        setCardActiveAssociates(cardAtivos);
        setCardAssociatesVPMIX(cardVPMIX);
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        if (error) console.log(error.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const openModalAssociateAdd = () => {
    openModal('ModalFormAssociado');
    setAssociate(undefined);
  };

  const openModalAssociateEdit = (associate: Associate & Address) => {
    setAssociate(associate);
    openModal('ModalFormAssociado');
  };

  const handleOnSubmit = async (formAssociate: FormAssociate & { image: Image | undefined }) => {
    setIsAwaitResponse(true);
    const { success, payload, error, type, message } = await saveAssociate({
      ...formAssociate,
      emp_codigo: associate?.EMP_CODIGO,
      emp_tipo: 'A',
      emp_logotipo: associate?.EMP_LOGOTIPO,
      newFile: formAssociate.image?.file,

      usu_codigo: usuario.USU_CODIGO,
      current_emp_codigo: empresa.EMP_CODIGO,
      current_emp_codmatriz: empresa.EMP_CODMATRIZ,
      ...(associate ? { len_codigo: associate.LEN_CODIGO } : undefined),
    });
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalFormAssociado');
      await fetchAssociates(filterState.iti_codigo);
    } else if (type === 'saveFileError') {
      showAlertWarning({
        message: (
          <>
            O associado foi salvo, mas ocorreu um problema ao salvar a logo.
            <br />
            {message}
          </>
        ),
        timeout: 10,
      });
      await fetchAssociates(filterState.iti_codigo);
      setAssociate(undefined);
      setAssociate(payload.empresa);
    } else if (type === 'cnpjDuplicado' || type === 'loginDuplicado') {
      showAlertDanger({ message: message });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
    }
  };

  const exportAssociates = () => {
    exportAssociatesToXLS({ emp_codigo: empresa.EMP_CODIGO });
  };

  const statusOptions: SelectControlledOption[] = [
    {
      value: '4',
      label: 'Ativo',
      isDisabled: associate === undefined,
    },
    {
      value: '6',
      label: 'Bloqueado',
      isDisabled: associate === undefined,
    },
    {
      value: '5',
      label: 'Inativo',
      isDisabled: associate === undefined,
    },
  ];

  return (
    <ViewAssociados
      isLoading={isLoading}
      headerConfig={{ title: 'Associados', searchBoxState: { searchBoxValue, setSearchBoxValue } }}
      cards={{
        cardInactiveAssociates,
        cardActiveAssociates,
        cardAssociatesVPMIX,
      }}
      cardHeader={{
        title: 'Lista de associados',
        subtitle: 'Aplique os filtros para encontrar um associado',
        filterAction: () => openModal('filtro'),
        actionAddAssociate: {
          label: 'cadastrar associado',
          iconClass: 'fa fa-plus',
          variant: 'btn-success',
          action: openModalAssociateAdd,
        },
      }}
      ModalAssociate={
        <ViewModalAssociate
          statusOptions={statusOptions}
          associate={associate}
          segments={segments}
          states={states}
          isAwaitResponse={isAwaitResponse}
          onSubmit={handleOnSubmit}
        />
      }
      ModalFilter={<ModalFilter onSubmit={(p) => setFilterState({ iti_codigo: p })} />}
      associates={associatesFiltered.filter((associate) => associate.EMP_TIPO === 'A')}
      openModalAssociateAdd={openModalAssociateAdd}
      openModalAssociateEdit={openModalAssociateEdit}
      exportAssociates={exportAssociates}
    />
    // <div className="wrapper">
    //   <Menu />

    //   <div className="main-panel">
    //     <Header title="Associados" searchBoxState={{ searchBoxValue: searchBoxValue, setSearchBoxValue: setSearchBoxValue }} />

    //     <div className="content">
    //       {isLoading && <PageLoading />}
    //       <div className="container-fluid">
    //         <div className="row">
    //           <div className="col-lg-4 col-sm-6">
    //             <IndicatorCard
    //               icon="nc-icon nc-icon nc-bank text-danger"
    //               label="Inativos"
    //               value={cardInactiveAssociates}
    //               title="Associados inativos"
    //             />
    //           </div>
    //           <div className="col-lg-4 col-sm-6">
    //             <IndicatorCard icon="nc-icon nc-icon nc-bank text-success" label="Ativos" value={cardActiveAssociates} title="Associados ativos" />
    //           </div>
    //           <div className="col-lg-4 col-sm-12">
    //             <IndicatorCard icon="nc-icon nc-icon nc-bank text-danger" label="VPMIX" value={cardAssociatesVPMIX} title="Associados no VPMIX" />
    //           </div>
    //         </div>
    //         <div className="row">
    //           <div className="col-md-12">
    //             <div className="card table-with-links">
    //               <div className="card-header">
    //                 <h4 className="card-title">
    //                   Lista de associados{' '}
    //                   <img className="clickable" data-toggle="modal" data-target="#filtro" src={logoFiltro} style={{ width: 30, height: 30 }} />
    //                 </h4>
    //                 <p className="card-category">Aplique os filtros para encontrar um associado</p>
    //                 <button onClick={openModalAssociateAdd} type="button" className="btn btn-sm btn-success btn-outline" style={{ marginTop: 10 }}>
    //                   <span className="btn-label">
    //                     <i className="fa fa-plus"></i>
    //                   </span>
    //                   cadastrar associado
    //                 </button>
    //               </div>

    //               <ModalAssociate associate={associate} segments={segments} states={states} onSubmit={() => fetchAssociates(filterState.iti_codigo)} autoSet={setAssociate} />
    //               <ModalFilter onSubmit={(p) => setFilterState({iti_codigo: p})} />

    //               <div className="card-body table-full-width">
    //                 <TableWithLinks
    //                   isLoading={isLoading}
    //                   emptyTableMessage="Nenhum associado encontrado. Tente alterar os filtros."
    //                   onClickingRow={openModalAssociateEdit}
    //                   columns={[
    //                     { field: 'CPFCNPJFormatted', label: 'CPF ou CNPJ', alignment: 'text-center', width: '150px' },
    //                     { field: 'EMP_RAZAOSOCIAL', label: 'Razão Social' },
    //                     { field: 'EMP_NOMEFANTASIA', label: 'N.fantasia' },
    //                     { field: 'ITI_DESCRICAO', label: 'Situação', alignment: 'text-center', width: '95px' },
    //                     { field: 'EMP_CONTATOADMIN', label: 'Contato', alignment: 'text-center' },
    //                     { field: 'PhoneFormatted', label: 'Telefone', alignment: 'text-center', width: '120px' },
    //                     { field: 'EMP_EMAILADMIN', label: 'Email', alignment: 'text-right' },
    //                   ]}
    //                   data={associatesFiltered.map((associate) => ({
    //                     ...associate,
    //                     CPFCNPJFormatted: associate.EMP_CPFCNPJ.length === 11 ? formatCPF(associate.EMP_CPFCNPJ) : formatCNPJ(associate.EMP_CPFCNPJ),
    //                     PhoneFormatted: formatPhone(associate.EMP_TELEFONEADMIN),
    //                   }))}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <Footer />
    //   </div>
    // </div>
  );
};

export default CadastroAssociados;
