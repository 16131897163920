import React from 'react';
import './styles.css';
import Carousel from '~/components/carrossel/Carousel';
import { ModalCarouselProps } from '~/types';

const ModalCarousel: React.FunctionComponent<ModalCarouselProps> = ({ imagesSRC }) => {
  return (
    <div className="modal fade carousel-modal" id="carousel-modal" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"></h5>
            <button
              type="button"
              className="close clickable"
              aria-label="Close"
              onClick={() => {
                $('#carousel-modal').modal('hide');
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title"></h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <div className="carousel-container">
                      <Carousel>
                        {imagesSRC.map((src, i) => (
                          <div key={i}>
                            <img src={src} alt="..." />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default ModalCarousel;
