import React, { useContext, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FormBankAccount, ModalBankAccountProps } from '~/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import SelectControlled from '~/components/input/SelectControlled';
import BasicInput from '~/components/input/BasicInput';
import { AuthContext } from '~/context/AuthContext';
import { saveBankAccount } from '~/service/financeiro/BankAccountService';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import { closeModal } from '~/utils/util';

const FormBankAccountSchema: yup.SchemaOf<FormBankAccount> = yup.object().shape({
  ban_codigo: yup.number().required(),
  cba_descricao: yup.string().required(),
  cba_agencia: yup.number().required(),
  cba_dvagencia: yup.number(),
  cba_conta: yup.number().required(),
  cba_dvconta: yup.number(),
});

const ModalBankAccount: React.FC<ModalBankAccountProps> = ({ bankAccount, banks, onSubmit }) => {
  const { company: empresa } = useContext(AuthContext);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);

  const { watch, control, handleSubmit, errors } = useForm<FormBankAccount>({
    resolver: yupResolver(FormBankAccountSchema),
  });

  const handleOnSubmit = async (formBankAccount: FormBankAccount) => {
    setIsAwaitResponse(true);
    const { success, payload, error, message } = await saveBankAccount({
      ...formBankAccount,
      cba_codigo: bankAccount?.CBA_CODIGO,
      emp_codigo: empresa.EMP_CODIGO,
    });
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalBankAccount');
      await onSubmit();
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
    }
  };

  return (
    <div className="modal fade" id="ModalBankAccount" role="dialog" aria-labelledby="usuarioLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Conta Bancária</h4>
                  </div>
                  <div className="card-body ">
                    <form
                      key={bankAccount?.CBA_CODIGO + 'ModalBankAccountFormId'}
                      onSubmit={handleSubmit(handleOnSubmit)}
                      id="ModalBankAccountFormId"
                    >
                      <SelectControlled
                        isRequired
                        control={control}
                        defaultValue={bankAccount?.BAN_CODIGO || ''}
                        isLoading={banks.length === 0}
                        options={banks.map((bank) => ({ value: bank.BAN_CODIGO, label: bank.BAN_DESCRICAO }))}
                        hasError={!!errors.ban_codigo}
                        name="ban_codigo"
                        label="Banco"
                      />

                      <BasicInput
                        isRequired
                        control={control}
                        type="text"
                        defaultValue={bankAccount?.CBA_DESCRICAO || ''}
                        hasError={!!errors.cba_descricao}
                        name="cba_descricao"
                        label="Descrição"
                        placeholder="Nome da conta"
                      />

                      <BasicInput
                        isRequired
                        control={control}
                        type="text"
                        defaultValue={bankAccount?.CBA_AGENCIA || undefined}
                        hasError={!!errors.cba_agencia}
                        name="cba_agencia"
                        label="Agência"
                        placeholder="N° da agência"
                        size={10}
                        maxLength={15}
                      />

                      <BasicInput
                        control={control}
                        type="text"
                        defaultValue={bankAccount?.CBA_DVAGENCIA || undefined}
                        hasError={!!errors.cba_dvagencia}
                        name="cba_dvagencia"
                        label="DV Agência"
                        placeholder="Dígito da agência"
                        size={12}
                        maxLength={2}
                      />

                      <BasicInput
                        isRequired
                        control={control}
                        type="text"
                        defaultValue={bankAccount?.CBA_CONTA || undefined}
                        hasError={!!errors.cba_conta}
                        name="cba_conta"
                        label="Conta"
                        placeholder="N° da conta"
                        size={10}
                        maxLength={10}
                      />

                      <BasicInput
                        control={control}
                        type="text"
                        defaultValue={bankAccount?.CBA_DVCONTA || undefined}
                        hasError={!!errors.cba_dvconta}
                        name="cba_dvconta"
                        label="DV Conta"
                        placeholder="Dígito da conta"
                        maxLength={2}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-success btn-wd" form="ModalBankAccountFormId" type="submit" disabled={isAwaitResponse}>
              {isAwaitResponse ? (
                <>
                  <i className="fas fa-spinner rotating" /> Processando...
                </>
              ) : (
                <>
                  <i className="fa fa-save" /> Salvar
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBankAccount;
