import React from 'react';
import { useForm } from 'react-hook-form';
import { FormFilterCashFlow, ModalRegistersTableProps } from '~/types';
import TableWithLinks from '~/components/table/TableWithLinks';
import Modal from '~/components/modal/Modal';

const ViewModalRegistersTable: React.FunctionComponent<ModalRegistersTableProps> = ({ incomes, expenses, isLoading }) => {
  const { control, handleSubmit } = useForm<FormFilterCashFlow>({});

  return (
    <Modal
      id="ModalRegistersTable"
      size="xl"
      isLoading={isLoading}
      header={() => (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <h4 className="card-title">Lançamentos</h4>
        </div>
      )}
      body={() => (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body " style={{ paddingTop: 0 }}>
                {[
                  { finances: incomes, title: 'Receitas' },
                  { finances: expenses, title: 'Despesas' },
                ].map(({ finances, title }, i) => (
                  <>
                    {i !== 0 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                    <h5 className="card-title">{title}</h5>
                    <TableWithLinks
                      emptyTableMessage="Nenhum registro encontrado."
                      columns={[
                        { field: 'received', label: 'Pago', width: '65px' },
                        { field: 'CFO_RAZAOSOCIAL', label: 'Cliente ou Fornecedor', width: '200px' },
                        { field: 'quota', label: 'Parcela', width: '70px', alignment: 'text-center' },
                        { field: 'LAN_DESCRICAO', label: 'Descrição' },
                        { field: 'LAN_DATACOMPETENCIA', label: 'Competência', width: '95px', alignment: 'text-center' },
                        { field: 'ECC_DESCRICAO', label: 'Conta', width: '200px' },
                        { field: 'CBA_DESCRICAO', label: 'Banco', width: '200px' },
                        { field: 'value', label: 'Valor', width: '100px', alignment: 'text-center' },
                        { field: 'date', label: 'Data', width: '95px', alignment: 'text-center' },
                      ]}
                      data={finances.map((finance) => ({
                        ...finance,
                        received:
                          finance.LAN_DATARECEBIDO !== '' ? (
                            <span className="badge badge-green">
                              <i className="fas fa-check" /> Sim
                            </span>
                          ) : (
                            <span className="badge badge-blue">
                              <i className="fas fa-times" /> Não
                            </span>
                          ),
                        quota: (
                          <span className="badge badge-azure" style={{ fontSize: '85%' }}>
                            {finance.LAN_PARCELA}
                          </span>
                        ),
                        value: <span>{finance.LAN_DATARECEBIDO != '' ? finance.LAN_VALORRECEBIDO : finance.LAN_VALOR}</span>,
                        date: finance.LAN_DATARECEBIDO !== '' ? finance.LAN_DATARECEBIDO : finance.LAN_DATAVENCIMENTO,
                      }))}
                    />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default ViewModalRegistersTable;
