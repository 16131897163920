import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '~/context/AuthContext';
import { getDashboardDataBase } from '~/service/DashboardService';
import { showAlertDanger } from '~/components/notification';
import ViewBase from '~/views/base';
import SelectCompany from '~/components/input/SelectCompany';

const Base: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [activeAssociates, setActiveAssociates] = useState({ value: '', variation: '' });
  const [blockedAssociates, setBlockedAssociates] = useState({ value: '', variation: '' });
  const [inactiveAssociates, setInactiveAssociates] = useState({ value: '', variation: '' });
  const [newers, setNewers] = useState({ value: '', variation: '' });
  const [segments, setSegments] = useState('');

  const [associadosAtivosPorMes, setAssociadosAtivosPorMes] = useState<{ data: string; value: number }[]>([]);

  const [maioresAssociados, setMaioresAssociados] = useState<any[]>([]);
  const [principaisSegmentos, setPrincipaisSegmentos] = useState<any[]>([]);
  const [novosAssociados, setNovosAssociados] = useState<any[]>([]);

  const [selectedCompany, setSelectedCompany] = useState<{ value: number; label: string; labelAux: string }>(
    company.FRANQUIAS
      ? {
          value: company.FRANQUIAS[0].EMP_CODIGO,
          label: company.FRANQUIAS[0].EMP_NOMEFANTASIA,
          labelAux: company.FRANQUIAS[0].EMP_RAZAOSOCIAL,
        }
      : ({} as any)
  );

  useEffect(() => {
    fetchDashboardDataBase();
  }, [selectedCompany]);

  const fetchDashboardDataBase = async () => {
    setIsLoading(true);
    const { success, payload, error } = await getDashboardDataBase({ emp_codigo: selectedCompany.value });
    if (success) {
      const {
        ativos,
        totalBloqueados,
        totalInativos,
        totalNovosNoMes,
        totalSegmentos,
        associadosPorMes,
        totalAtivoAteMes,
        maioresAssociados,
        totalSegmentosPorAssociados,
        novosNoMes,
      } = payload;

      const totalAssociates = +totalBloqueados + +ativos;

      setActiveAssociates({ value: ativos, variation: `${((+ativos / totalAssociates) * 100).toFixed(2)}%` || '' });
      setBlockedAssociates({
        value: totalBloqueados,
        variation: `${((+totalBloqueados / totalAssociates) * 100).toFixed(2)}%` || '',
      });
      setInactiveAssociates({
        value: totalInativos,
        variation: `${((+totalInativos / (+ativos + +totalBloqueados + +totalInativos)) * 100).toFixed(2)}` || '',
      });
      setNewers({ value: totalNovosNoMes, variation: `${((totalNovosNoMes / totalAssociates) * 100).toFixed(2)}%` });
      setSegments(totalSegmentos);

      createAssociadosAtivosPorMes(associadosPorMes, +totalAtivoAteMes);

      setMaioresAssociados(maioresAssociados);
      setPrincipaisSegmentos(totalSegmentosPorAssociados);
      setNovosAssociados(novosNoMes);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro interno. Tente recarregar a página.' });
    }
    setIsLoading(false);
  };

  const createAssociadosAtivosPorMes = (data: { [key: string]: string }, activeAssociates: number) => {
    let totalActiveAssociates = activeAssociates;
    const dataFormatted = Object.keys(data).map((key) => {
      totalActiveAssociates += +data[key];
      return { data: key.split('/').reverse().join('-'), value: totalActiveAssociates };
    });
    setAssociadosAtivosPorMes(dataFormatted);
  };

  return (
    <ViewBase
      key={`ViewBase_${selectedCompany.value}`}
      isLoading={isLoading}
      cards={{
        activeAssociates,
        blockedAssociates,
        inactiveAssociates,
        newers,
        segments,
      }}
      associadosAtivosPorMes={associadosAtivosPorMes}
      maioresAssociados={maioresAssociados}
      principaisSegmentos={principaisSegmentos}
      novosAssociados={novosAssociados}
      headerAppend={
        <SelectCompany
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          franquias={company.FRANQUIAS ?? []}
        />
      }
    />
  );
};

export default Base;
