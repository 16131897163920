import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import {
  calcCommission,
  getPaymentOptions,
  permuteOfferBuy,
  sendNegotiationResponse,
} from '~/service/NegotiationService';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import {
  ControlledMoneyFormatterProps,
  ModalNegotiationResponseProps,
  OptionProps,
  PaymentOption,
  SelectControlledOption,
} from '~/types';
import { AuthContext } from '~/context/AuthContext';
import NumberFormat from 'react-number-format';
import { closeModal, openItauShopline, reactNumberCorrector, reactNumberFormatter } from '~/utils/util';
import { NegotiationSituation, NegotiationType, OfferType } from '~/enum';
import ControlledMoneyFormatter from '~/components/input/ControlledMoneyFormatter';
import ControlledSelect from '~/components/input/ControlledSelect';
import BasicInput from '~/components/input/BasicInput';
import { submitPaymentAsaas } from '~/service/PaymentAsaas';
import SelectControlled from '~/components/input/SelectControlled';
import MinCommissionValue from '~/components/mensagens/MinCommissionValue';

interface FormInput {
  one_descricao?: string;
  ofe_valor?: number;
  one_status: string;
  fpa_codigo?: number;
}

const ModalNegotiationResponse: React.FunctionComponent<ModalNegotiationResponseProps> = ({
  negotiation,
  fetchMyNegotiations,
}) => {
  const status = [
    { value: NegotiationSituation.Negociando, label: 'Em negociação' },
    { value: NegotiationSituation.Fechado, label: 'Fechado' },
    { value: NegotiationSituation.Recusado, label: 'Recusado' },
  ];

  const validationSchema: yup.SchemaOf<FormInput> = yup.object().shape({
    one_descricao: yup
      .string()
      .when('one_status', { is: NegotiationSituation.Negociando, then: yup.string().required() }),
    ofe_valor: yup.number().moreThan(0),
    one_status: yup.string().required(),
    fpa_codigo: yup.number().when('one_status', {
      is: NegotiationSituation.Fechado,
      then: yup.number().required(),
      otherwise: yup.number(),
    }),
  });

  const { handleSubmit, errors, control, watch, reset, getValues, setValue } = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  });

  const { company: empresa, user: usuario } = useContext(AuthContext);
  const formRef = useRef<HTMLFormElement>(null);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [isAwaitPaymentOptions, setIsAwaitPaymentOptions] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<SelectControlledOption[]>([]);
  const [paymentOptionsChecked, setPaymentOptionsChecked] = useState<SelectControlledOption[]>([]);

  const [commission, setCommission] = useState<number | null>(null);
  const [isLoadingCommission, setIsLoadingCommission] = useState(false);
  const [isShowCommissionMessage, setIsShowCommissionMessage] = useState(false);

  useEffect(() => {
    fetchPaymentOptions();
  }, []);

  useEffect(() => {
    if (getValues('one_status') === NegotiationSituation.Fechado && !commission !== null) {
      handleCalcCommission();
    } else {
      setIsShowCommissionMessage(false);
    }
  }, [watch('one_status'), negotiation]);

  useEffect(() => {
    setValue('fpa_codigo', '');

    if (commission !== null && commission < 5 && paymentOptions.length > 0) {
      setPaymentOptionsChecked(
        paymentOptions.map(({ label, ...props }) => ({
          label,
          isDisabled: ['cartão', 'cartao'].includes(label.toLowerCase()),
          ...props,
        }))
      );
    } else {
      setPaymentOptionsChecked(paymentOptions);
    }
  }, [commission, paymentOptions]);

  useEffect(() => {
    const paymentMethod = paymentOptions.find(
      (paymentMethod) => paymentMethod.value === getValues('fpa_codigo') && paymentMethod.label === 'Boleto'
    );

    if (!!paymentMethod) {
      setIsShowCommissionMessage(!!commission && commission < 10);
    } else {
      setIsShowCommissionMessage(false);
    }
  }, [watch('fpa_codigo'), commission]);

  const fetchPaymentOptions = async () => {
    setIsAwaitPaymentOptions(true);

    const {
      payload: { formasPgto },
    } = await getPaymentOptions(empresa.EMP_CODIGO);

    setPaymentOptions(formasPgto.map((f: PaymentOption) => ({ value: f.FPA_CODIGO, label: f.FPA_DESCRICAO })));
    setIsAwaitPaymentOptions(false);
  };

  const onSubmit = async (values: FormInput): Promise<void> => {
    if (negotiation.ORC_TIPO === NegotiationType.COMPRA && watch('one_status') === NegotiationSituation.Fechado) {
      await permuteOfferHandler(values);
    } else {
      await sendResponse(values);
    }
  };

  const sendResponse = async (values: FormInput) => {
    setIsAwaitResponse(true);

    const { success, message, type } = await sendNegotiationResponse({
      one_descricao: values.one_descricao,
      one_status: values.one_status,
      ofe_valor: values.ofe_valor,
      emp_codigo: empresa.EMP_CODIGO,
      orc_codigo: negotiation.ORC_CODIGO,
    });

    setIsAwaitResponse(false);

    if (success == true) {
      showAlertSuccess({ message: 'Mensagem enviada com sucesso!' });
      fetchMyNegotiations();
      closeModal('responder');
      reset();
    } else {
      showAlertDanger({ message });
    }
  };

  const permuteOfferHandler = async (values: FormInput) => {
    setIsAwaitResponse(true);

    const body = {
      emp_codigo: empresa.EMP_CODIGO,
      emp_codmatriz: empresa.EMP_CODMATRIZ,
      usu_codigo: usuario.USU_CODIGO,
      ofe_codigo: negotiation.OFE_CODIGO,
      ofe_valor: negotiation.ONE_VALOR,
      fpa_codigo: values.fpa_codigo,
      orc_codigo: negotiation.ORC_CODIGO,
      one_descricao: values.one_descricao,
      asaas_ativo: negotiation.EMP_FRANQUIA_ASAAS_ATIVO,
    };

    const {
      success,
      message,
      payload: { dados },
    } = await permuteOfferBuy(body);

    setIsAwaitResponse(false);

    if (!success) {
      showAlertDanger({ message });
    } else {
      if (negotiation.EMP_FRANQUIA_ASAAS_ATIVO === 'N') {
        showAlertSuccess({ message: 'Permuta confirmada com sucesso.', timeout: 3 });
        openItauShopline(dados);
      } else {
        const response = await submitPaymentAsaas(
          empresa.EMP_CODIGO,
          empresa.EMP_CODMATRIZ, //franquia
          true,
          dados.fpa_codigo,
          dados.comissaoCompra,
          dados.lan_codigo_compra
        );

        if (response.success) {
          const { payload, success } = await calcCommission({
            ofe_codigo: negotiation.OFE_CODIGO,
            emp_codigo: negotiation.EMP_CODIGO_VENDEDOR,
            e_compra: false,
            valor: negotiation.OFE_VALOR,
            e_vpmix: negotiation.OFE_TIPO === OfferType.VPMIX,
          });

          //gera uma venda para o associado EMP_CODIGO_VENDEDOR
          if (success === '1') {
            await submitPaymentAsaas(
              payload.empresa.EMP_CODIGO,
              payload.empresa.EMP_CODMATRIZ,
              false,
              dados.fpa_codigo,
              payload.comissao,
              dados.lan_codigo
            ); // Independe do resultado, retorna sucesso para o usuario
          }
          showAlertSuccess({ message: response.message, timeout: 0 });
        } else {
          showAlertDanger({ message: response.message, timeout: 0 });
        }

        if (response.payload?.data.idAssas) {
          window.open(response.payload.data.invoiceUrl, '_blank');
        } else {
          let message = '';
          if (Boolean(response.payload?.data?.autenticacao)) {
            message = 'Integração de cobrança não permitida.\nEntre em contato com a franquia do vendedor!';
          } else if (Boolean(response.payload?.data?.associado)) {
            message = 'Seu email ou telefone está inválido.\nVerifique com o responsável!';
          } else {
            message = 'Não conseguimos enviar o email com a cobrança!';
          }

          setTimeout(() => {
            showAlertDanger({ message, timeout: 0 });
          }, 3000);
        }
      }

      fetchMyNegotiations();
      closeModal('responder');
      reset();
    }
  };

  const handleCalcCommission = async () => {
    if (negotiation.ONE_VALOR == '0' || negotiation.ONE_VALOR == null) {
      setCommission(0);
    } else {
      setIsLoadingCommission(true);
      const { success, message, payload } = await calcCommission({
        ofe_codigo: negotiation.OFE_CODIGO,
        emp_codigo: empresa.EMP_CODIGO,
        e_compra: negotiation.ORC_TIPO === NegotiationType.COMPRA,
        valor: negotiation.ONE_VALOR,
        e_vpmix: negotiation.OFE_TIPO === OfferType.VPMIX,
      });

      if (success) {
        setIsLoadingCommission(false);
        setCommission(+payload.comissao);
      } else {
        showAlertDanger({ message: message });
      }
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="responder"
        role="dialog"
        aria-labelledby="responderLabel"
        aria-hidden="true"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body" style={{ paddingTop: 0 }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card stacked-form">
                    <div className="card-header ">
                      <h4 className="card-title">Resposta...</h4>
                    </div>
                    <div className="card-body " style={{ paddingTop: 0 }}>
                      <form
                        key={negotiation.ORC_CODIGO}
                        id="genericFormId"
                        ref={formRef}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <ControlledSelect
                          label="Situação"
                          name="one_status"
                          control={control}
                          defaultValue={negotiation.ONE_STATUS}
                          isRequired
                          placeholder="Escolha..."
                          options={status}
                          isDisabled={negotiation.ORC_TIPO === NegotiationType.VENDA || negotiation.ONE_VALOR === '0'}
                        />
                        <BasicInput
                          label="Descrição da resposta ou pergunta..."
                          control={control}
                          isRequired={watch('one_status') === NegotiationSituation.Negociando}
                          hasError={!!errors.one_descricao}
                          placeholder="Descreva brevemente sua dúvida ou pergunta..."
                          type="text"
                          name="one_descricao"
                          defaultValue=""
                        />
                        <ControlledMoneyFormatter
                          name="ofe_valor"
                          control={control}
                          defaultValue={negotiation.ONE_VALOR === '0' ? undefined : +negotiation.ONE_VALOR}
                          label="Valor"
                          hasError={!!errors.ofe_valor}
                          isRequired
                          prefix=""
                          isDisabled={negotiation.ORC_TIPO === NegotiationType.COMPRA}
                        />
                        {negotiation.ORC_TIPO === NegotiationType.COMPRA &&
                          watch('one_status') === NegotiationSituation.Fechado && (
                            <SelectControlled
                              label="Forma de pagamento"
                              name="fpa_codigo"
                              control={control}
                              defaultValue={''}
                              isRequired
                              hasError={!!errors.fpa_codigo}
                              placeholder="Escolha..."
                              options={paymentOptionsChecked}
                              isDisabled={isAwaitPaymentOptions || isLoadingCommission}
                              isLoading={isAwaitPaymentOptions || isLoadingCommission}
                            />
                          )}
                      </form>
                      {isShowCommissionMessage && <MinCommissionValue />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
                <i className="fa fa-arrow-left"></i> Voltar
              </button>
              <button type="submit" className="btn btn-success btn-wd" form="genericFormId" disabled={isAwaitResponse}>
                {isAwaitResponse ? (
                  <>
                    <i className="fas fa-spinner rotating" /> Processando...
                  </>
                ) : (
                  <>
                    <i className="fa fa-comments"></i> Responder
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNegotiationResponse;
