import React, { CSSProperties } from 'react';
import { LoadingSpinProps } from '~/types';
import './styles.css';

type LoadingFormSpinProps = {
  style?: CSSProperties;
};

const LoadingFormSpin: React.FunctionComponent<LoadingFormSpinProps> = ({ style }) => {
  return (
    <div className="spin-containter" style={style}>
      <div className="dot-flashing" />
    </div>
  );
};

export default LoadingFormSpin;
