import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { closeModal, formatCNPJ, formatCPF } from '~/utils/util';
import SelectControlled from '~/components/input/SelectControlled';
import moment from 'moment';
import { FormFilterAccountsPayableReceivable, ModalFilterAccountsPayableReceivableProps } from '~/types';
import BasicInput from '~/components/input/BasicInput';
import SelectAsync from 'react-select/async';
import { autoCompleteFinances } from '~/service/financeiro/FinanceService';
import { AuthContext } from '~/context/AuthContext';
import SelectAsyncControlled from '~/components/input/SelectAsyncControlled';

const ModalFilterAccountsPayableReceivable: React.FunctionComponent<ModalFilterAccountsPayableReceivableProps> = ({ onSubmit }) => {
  const { control, handleSubmit } = useForm<FormFilterAccountsPayableReceivable>({});
  const { company } = useContext(AuthContext);

  const handleOnSubmit = async (params: FormFilterAccountsPayableReceivable) => {
    onSubmit(params);
    closeModal('ModalFilterAccountsPayableReceivable');
  };

  return (
    <div
      className="modal fade"
      id="ModalFilterAccountsPayableReceivable"
      role="dialog"
      aria-labelledby="Modal-Filter"
      aria-hidden="true"
      tabIndex={-1}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Filtrar contas</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="FormFilterAccountsPayableReceivableId">
                      <SelectAsyncControlled
                        control={control}
                        defaultValue={''}
                        name="campo_pesquisa"
                        label="Nome fantasia"
                        placeholder="Pesquise por nome, telefone ou cpf"
                        loadOptions={async (value: string) => {
                          const response: string = await autoCompleteFinances(value, company.EMP_CODIGO);
                          return response
                            .split('\n')
                            .filter((r) => r !== '')
                            .map((row: string) => {
                              const data = row.split('|');
                              return {
                                value: data[0],
                                label: `${data[0]} ${
                                  data.length >= 3 ? `(${data[2].length === 14 ? formatCNPJ(data[2]) : formatCPF(data[2])})` : ''
                                }`,
                              };
                            });
                        }}
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={'S'}
                        options={[
                          { value: 'S', label: 'Sim' },
                          { value: 'N', label: 'Não' },
                        ]}
                        name="baixados"
                        label="Mostrar baixados?"
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={'T'}
                        options={[
                          { value: 'T', label: 'Tudo' },
                          { value: 'R', label: 'Receita' },
                          { value: 'D', label: 'Despesa' },
                        ]}
                        name="tipo"
                        label="Tipo"
                        placeholder="Tudo"
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={'LAN_DATAVENCIMENTO'}
                        options={[
                          { value: 'LAN_DATAVENCIMENTO', label: 'Vencimento' },
                          { value: 'LAN_DATARECEBIDO', label: 'Pagamento' },
                          { value: 'LAN_DATAGERACAO', label: 'Emissão' },
                        ]}
                        name="tipo_data"
                        label="Data por"
                      />

                      <BasicInput control={control} type="date" defaultValue={moment().format('YYYY-MM-DD')} name="data_inicial" label="Data início" />

                      <BasicInput control={control} type="date" defaultValue={moment().format('YYYY-MM-DD')} name="data_final" label="Data fim" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-default btn-wd" form="FormFilterAccountsPayableReceivableId" type="submit">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterAccountsPayableReceivable;
