import React, { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { formatNumberToMoney } from '~/utils/util';

interface MoneyMaskProps {
  name: string;
  control: Control<Record<string, any>>;
  defaultValue: number | undefined;
  isDisabled: boolean;
  prefix?: string;
  suffix?: string;
}

const MoneyMask: React.FunctionComponent<MoneyMaskProps> = ({ name, control, defaultValue, isDisabled, prefix, suffix }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      onFocus={() => {
        inputRef.current?.focus();
      }}
      render={({ onChange, value }) => (
        <NumberFormat
          disabled={isDisabled}
          className="form-control"
          getInputRef={inputRef}
          placeholder={prefix || suffix}
          prefix={prefix}
          suffix={suffix}
          isNumericString
          format={prefix ? (e) => formatNumberToMoney(+e / 100) : undefined}
          defaultValue={prefix ? value * 100 : value}
          value={prefix ? value * 100 : value}
          onValueChange={(e) => onChange(!e.floatValue ? undefined : prefix ? e.floatValue / 100 : e.floatValue)}
        />
      )}
    />
  );
};

export default MoneyMask;
