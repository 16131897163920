import React, { useContext } from 'react';

import { AuthContext } from '~/context/AuthContext';
import ViewRelatorioSaldoAssociados from '~/views/RelatorioSaldoAssociados';
import { associateBalance } from '~/service/ReportService';

const RelatorioSaldoAssociados: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);
  const exportToExcel = () => {
    associateBalance({
      empCodigo: company.EMP_CODIGO,
      empCodMatriz: company.EMP_CODMATRIZ,
    });
  };

  return <ViewRelatorioSaldoAssociados exportToExcel={exportToExcel} />;
};

export default RelatorioSaldoAssociados;
