import api from '~/config/api';

export const submitPaymentAsaas = async (emp_codigo_associado, emp_codigo_franquia, compra, fpa_codigo, valor_comissao, lan_codigo, payment_id = '', installmentCount = null, installmentValue = null) => {
  try {
    const { data } = await api.post('/api/pagamento-asaas', 
      {
        emp_codigo_associado, 
        emp_codigo_franquia, 
        compra, 
        fpa_codigo, 
        valor_comissao, 
        lan_codigo,
        payment_id,
        installmentCount, 
        installmentValue
      }
    );

    return data
  } catch (error) {
    return { success: false, error }
  }
}