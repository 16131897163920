import api from '~/config/api';

export const getBalance = async (EMP_CODIGO) => {
  try {
    const { data } = await api.post('/api/dashboard/get-balance', {
      EMP_CODIGO,
    });

    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const getDashboardAssociado = async ({ EMP_TIPO, EMP_CODIGO, EMP_CODMATRIZ }) => {
  return getDashboardData({ EMP_TIPO, EMP_CODIGO, EMP_CODMATRIZ });
};

export const getDashboardFranquia = async ({
  EMP_TIPO,
  EMP_CODIGO,
  EMP_CODMATRIZ,
  DATA_INICIO,
  DATA_FIM,
  DATA_INICIO_GRAFICO,
  DATA_FIM_GRAFICO,
}) => {
  return getDashboardData({
    EMP_TIPO,
    EMP_CODIGO,
    EMP_CODMATRIZ,
    DATA_INICIO,
    DATA_FIM,
    DATA_INICIO_GRAFICO,
    DATA_FIM_GRAFICO,
  });
};

const getDashboardData = async ({
  EMP_TIPO,
  EMP_CODIGO,
  EMP_CODMATRIZ,
  DATA_INICIO,
  DATA_FIM,
  DATA_INICIO_GRAFICO,
  DATA_FIM_GRAFICO,
}) => {
  try {
    const { data } = await api.post('/api/dashboard/get-dashboard-data', {
      EMP_TIPO,
      EMP_CODIGO,
      EMP_CODMATRIZ,
      DATA_INICIO,
      DATA_FIM,
      DATA_INICIO_GRAFICO,
      DATA_FIM_GRAFICO,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getDashboardDataBase = async ({ emp_codigo }) => {
  try {
    const { data } = await api.post('/api/dashboard/dashboard-franquia-base', {
      EMP_CODIGO: emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getDashboardTotalizadoresAssociado = async ({ emp_codigo }) => {
  try {
    const { data } = await api.get(`/api/dashboard/totalizadores-associado?emp_codigo=${emp_codigo}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getDashboardDataRaioX = async ({ emp_codigo }) => {
  try {
    const { data } = await api.post('/api/dashboard/dashboard-franquia-visao-associado', {
      emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getDashboardDataRaioXAssociado = async ({ emp_codigo_associado }) => {
  try {
    const { data } = await api.post('/api/dashboard/dashboard-franquia-visao-associado-por-codigo', {
      emp_codigo_associado,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const totalizadoresFranquiaVisaoAssociado = async ( franquia_codigo, emp_associado_codigo ) => {
  try {
    const { data } = await api.get(`/api/dashboard/totalizadores-franquia-visao-associado?emp_codigo=${franquia_codigo}&emp_associado_codigo=${emp_associado_codigo}`);
    return data;
  } catch (error) {
    return error;
  }
};
