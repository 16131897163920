import React from 'react';

const MinCommissionValue = () => {
  return (
    <p style={{ marginTop: 30, background: 'lightgoldenrodyellow', padding: 20, borderRadius: 8 }}>
      Prezado Associado, o valor mínimo para pagamento em boleto é de R$10,00, você pode optar em:
      <br />
      <br />
      <strong>1) </strong> Fazer o pagamento via cartão de crédito, alterando a opção acima;
      <br />
      <br />
      <strong>2) </strong> Prosseguir e fazer o pagamento via PIX (o banco dará esta opção na próxima tela);
      <br />
      <br />
      <strong>3) </strong> Gerar o boleto de R$10,00.
    </p>
  );
};

export default MinCommissionValue;
