import React, { useRef, useState } from 'react';
import { SelectAsyncControlledProps, SelectControlledOption } from '~/types';
import { Controller } from 'react-hook-form';
import Select, { ActionMeta, createFilter, FocusEventHandler, OptionsType, OptionTypeBase, ValueType } from 'react-select';
import SelectAsync from 'react-select/async';

const SelectAsyncControlled: <T extends SelectControlledOption>(props: SelectAsyncControlledProps<T>) => JSX.Element = ({
  hasError,
  label,
  isRequired,
  name,
  control,
  defaultValue,
  isLoading,
  isDisabled,
  onBlur,
  isMulti,
  formatOptionLabel,
  filterOptions,
  placeholder = 'Selecione',
  defaultOptions,
  noOptionsMessage = 'Digite para pesquisar',
  loadOptions,
  checkbox,
  ...rest
}) => {
  const selectRef = useRef<HTMLDivElement | any>(null);
  const [options, setOptions] = useState<SelectControlledOption[]>(Array.isArray(defaultOptions) ? defaultOptions : []);

  return (
    <div className={`form-group ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      <label className="label-with-loading">
        {label}
        {isRequired && <span className="star" />}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        onFocus={() => {
          selectRef.current.focus();
        }}
        render={({ onChange, value }) => (
          <SelectAsync
            {...rest}
            ref={selectRef}
            openMenuOnFocus
            className="react-select-container"
            classNamePrefix="react-select"
            cacheOptions
            noOptionsMessage={() => 'Nenhum resultado encontrado'}
            defaultOptions={defaultOptions}
            isDisabled={isDisabled}
            placeholder={placeholder}
            onBlur={onBlur}
            loadingMessage={() => 'Carregando...'}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            formatOptionLabel={formatOptionLabel}
            filterOption={filterOptions ? createFilter(filterOptions) : undefined}
            loadOptions={async (value, callback) => {
              const optionsReceived = await loadOptions(value);
              setOptions([...options, ...optionsReceived]);
              callback(optionsReceived);
            }}
            value={isMulti ? (value ? options.filter((o) => value.includes(o.value)) : []) : options.find((o) => o.value === value) || null}
            onChange={(
              optionSelected: ValueType<SelectControlledOption, false> | ValueType<SelectControlledOption, true>,
              actionMeta: ActionMeta<SelectControlledOption>
            ) => {
              if (optionSelected) {
                if (isMulti) {
                  const option = optionSelected as OptionsType<SelectControlledOption>;
                  onChange(option?.map((o) => o.value));
                } else {
                  const option = optionSelected as SelectControlledOption;
                  onChange(option?.value);
                }
              }
            }}
          />
        )}
      />
      {checkbox}
    </div>
  );
};

export default SelectAsyncControlled;
