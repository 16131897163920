import api from '~/config/api';

export const getMyProfile = async (usu_codigo) => {
  try {
    const { data } = await api.post('/api/usuarios/perfil', {
      usu_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const saveMyProfile = async ({
  usu_codigo,
  usu_nome,
  usu_login,
  usu_email,
  empresa_padrao,
  usu_cpf,
  usu_tratamento,
  usu_sexo,
  usu_telefone,
  usu_dataaniver,
  usu_senha,
  usu_senha_confirma,
}) => {
  try {
    const { data } = await api.post('/api/usuarios/salvar-meu-perfil', {
      usu_codigo,
      usu_nome,
      usu_login,
      usu_email,
      empresa_padrao,
      usu_cpf,
      usu_tratamento,
      usu_sexo,
      usu_telefone,
      usu_dataaniver,
      usu_senha,
      usu_senha_confirma,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const uploadProfilePhoto = async ({ photo, usu_codigo }) => {
  const formData = new FormData()
  formData.append('input_fotoPerfil', photo)
  formData.append('usu_codigo', usu_codigo)

  try {
    const { data } = await api.post('/api/usuarios/anexar', formData);
    return data;
  } catch (error) {
    return error;
  }
}

export const getAllUsers = async (emp_codigo, itiCodigos) => {
  try {
    const { data } = await api.post('/api/usuarios/carregar-todos', {
      emp_codigo,
      iti_codigos: itiCodigos
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
}

export const saveUser = async (user) => {
  try {
    const { data } = await api.post('/api/usuarios/salvar', user);
    return data;
  } catch (error) {
    return { success: false, error };
  }
}