import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormFilterBalanceSheet, ModalFilterBalanceSheetProps } from '~/types';
import { closeModal } from '~/utils/util';
import SelectControlled from '~/components/input/SelectControlled';
import moment from 'moment';
import Datetime from 'react-datetime';

const ModalFilterBalanceSheet: React.FunctionComponent<ModalFilterBalanceSheetProps> = ({ onSubmit }) => {
  const { control, handleSubmit } = useForm<FormFilterBalanceSheet>({});

  const handleOnSubmit = async (params: FormFilterBalanceSheet) => {
    onSubmit({
      baixados: params.baixados,
      ano: params.data.year(),
      mes: params.data.month() + 1,
      tipo_data: params.tipo_data,
    });
    closeModal('ModalFilterBalanceSheet');
  };

  return (
    <div className="modal fade" id="ModalFilterBalanceSheet" role="dialog" aria-labelledby="Modal-Filter" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Filtrar balancete</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="ModalFilterFinanceId">
                      <div className="form-group">
                        <label>Mês e ano</label>
                        <Controller
                          name="data"
                          control={control}
                          defaultValue={moment()}
                          render={({ onChange, value }) => (
                            <Datetime dateFormat="MMMM [de] YYYY" timeFormat={false} value={value} onChange={onChange} />
                          )}
                        />
                      </div>

                      <SelectControlled
                        control={control}
                        defaultValue={'S'}
                        options={[
                          { value: 'S', label: 'Sim' },
                          { value: 'N', label: 'Não' },
                        ]}
                        name="baixados"
                        label="Mostrar baixados?"
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={'LAN_DATARECEBIDO'}
                        options={[
                          { value: 'LAN_DATAVENCIMENTO', label: 'Vencimento' },
                          { value: 'LAN_DATARECEBIDO', label: 'Pagamento' },
                        ]}
                        name="tipo_data"
                        label="Data por"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-default btn-wd" form="ModalFilterFinanceId" type="submit">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterBalanceSheet;
