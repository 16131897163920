import React, { useEffect, useRef, useState } from 'react';
import ModalOferta from '~/components/modal/ModalOfferDetails';
import { AssociateProps, Negotiation, Offer } from '~/types';
import { baseURL } from '~/config/api';
import LoadingSpin from '~/components/loading/LoadingSpin';
import { correctNumberWithComma, formatNumberToVPS, openModal } from '~/utils/util';
import { getOfferDetails, getOffersPerAssociate } from '~/service/OfferService';

const defaultProps = {
  enableOfferHiperlink: false,
};

const ModalAssociateDetails: React.FC<AssociateProps> = ({ associate, enableOfferHiperlink }) => {
  const modelRef = useRef<HTMLDivElement>(null);
  const [offer, setOffer] = useState({} as Offer);
  const [offers, setOffers] = useState([] as Offer[]);
  const [isLoadingOffers, setIsLoadingOffers] = useState(true);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  useEffect(() => {
    modelRef.current?.addEventListener('focus', overflowOnFocus);
    return () => {
      modelRef.current?.removeEventListener('focus', overflowOnFocus);
    };
  }, []);

  useEffect(() => {
    if (associate.OFERTAS !== undefined && associate.OFERTAS !== '0') loadOffers();
  }, [associate]);

  const overflowOnFocus = () => {
    document.body.classList.add('modal-open');
  };

  const loadOffers = async () => {
    setIsLoadingOffers(true);
    const { payload } = await getOffersPerAssociate(associate.EMP_CODIGO);
    setOffers(payload.ofertas);
    setIsLoadingOffers(false);
  };

  const fetchOfferDetails = async (ofeCodigo: number) => {
    setIsLoadingModal(true);
    setOffer({} as Offer);
    openModal('detalhesOferta');

    const { payload } = await getOfferDetails(ofeCodigo);

    setOffer(payload.oferta);
    setIsLoadingModal(false);
  };
  const props = {};
  return (
    <>
      <div
        className="modal fade"
        id="detalhesAssociado"
        role="dialog"
        aria-labelledby="detalhesAssociadoLabel"
        aria-hidden="true"
        tabIndex={-1}
        ref={modelRef}
      >
        <div className="modal-dialog modal-lg modal-extra-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{associate.EMP_NOMEFANTASIA}</h3>
              <button
                type="button"
                className="close clickable"
                onClick={() => {
                  $('#detalhesAssociado').modal('hide');
                }}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body details-offer" style={{ padding: 23 }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={`${baseURL}/web/uploads/logoLoja/${associate.EMP_LOGOTIPO}`}
                    alt={associate.EMP_NOMEFANTASIA}
                    style={{ width: 250, height: 'auto', objectFit: 'cover', borderRadius: 5, marginBottom: 10 }}
                  />
                </div>
              </div>
              <div style={{ flexDirection: 'row', marginLeft: 15, width: '100%' }}>
                <p>
                  <strong>Razão Social: </strong> <span>{associate.EMP_RAZAOSOCIAL}</span>
                </p>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p>
                    <strong>Segmento: </strong> <span>{associate.SEG_DESCRICAO}</span>
                  </p>
                  <p>
                    <strong>Cidade/UF : </strong>{' '}
                    <span>
                      {associate.LEN_CIDADE}/{associate.LEN_ESTADO}
                    </span>
                  </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p>
                    <strong>Descrição: </strong> <span>{associate.EMP_DESCRITIVO}</span>
                  </p>
                </div>
              </div>
            </div>
            <div style={{ padding: 23, boxSizing: 'content-box' }}>
              <div className="card-body table-full-width table-responsive">
                <table className="table table-striped">
                  <colgroup>
                    <col />
                    <col span={3} width="15%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Oferta</th>
                      <th>Disponível</th>
                      <th>Expira em</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {associate.OFERTAS !== '0' && isLoadingOffers ? (
                      <tr>
                        <td colSpan={4}>
                          <LoadingSpin />
                        </td>
                      </tr>
                    ) : associate.OFERTAS === '0' ? (
                      <tr>
                        <td colSpan={4}>Este usuário não possui ofertas ativas publicadas nesse momento.</td>
                      </tr>
                    ) : (
                      offers.map((offer) => (
                        <tr
                          key={offer.OFE_CODIGO}
                          className={enableOfferHiperlink ? 'clickable' : ''}
                          data-toggle={enableOfferHiperlink ? 'modal' : ''}
                          data-target={enableOfferHiperlink ? '#detalhesOferta' : ''}
                          onClick={() => {
                            enableOfferHiperlink && fetchOfferDetails(+offer.OFE_CODIGO);
                          }}
                        >
                          <th>{offer.OFE_TITULO}</th>
                          <th>
                            &nbsp;&nbsp;&nbsp;
                            {offer.OFE_QTDOFERTADOS === '' ? (
                              <>&infin;</>
                            ) : (
                              +offer.OFE_QTDOFERTADOS - +offer.OFE_QTDVENDIDA
                            )}
                          </th>
                          <th>{offer.OFE_DATAEXPIRACAO.split(' ').shift()}</th>
                          <th>
                            {offer.OFE_VALOR === '0'
                              ? 'À Combinar'
                              : formatNumberToVPS(+correctNumberWithComma(offer.OFE_VALOR))}
                          </th>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      {enableOfferHiperlink && <ModalOferta offer={offer} isLoading={isLoadingModal} />}
    </>
  );
};

ModalAssociateDetails.defaultProps = defaultProps;

export default ModalAssociateDetails;
