import api from '~/config/api';

export const getBalanceSheetData = async ({
  emp_codigo,
  mes,
  ano,
  baixados,
  tipo_data,
}) => {
  try {
    const { data } = await api.post('/api/balancete/carregar', {
      emp_codigo,
      mes,
      ano,
      baixados,
      tipo_data
    })
    return data
  } catch (error) {
    return { success: false, error }
  }
}