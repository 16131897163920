import React, { useState } from 'react';
import LoadingSpin from '~/components/loading/LoadingSpin';
import LoadingPointsSpin from '~/components/loading/LoadingPointsSpin';
import { Associate, TableAssociatesProps } from '~/types';
import { baseURL } from '~/config/api';
import ModalAssociateDetails from '~/components/modal/ModalAssociateDetails';
import { openModal } from '~/utils/util';

const defaultProps = {
  openAssociate: false,
};

const TableAssociates: React.FunctionComponent<TableAssociatesProps> = ({
  associates,
  isLoading,
  openAssociate,
  enableOfferHiperlink,
}) => {
  const [associate, setAssociate] = useState({} as Associate);

  return (
    <>
      {openAssociate && <ModalAssociateDetails associate={associate} enableOfferHiperlink={enableOfferHiperlink} />}
      <table className="table table-bigboy">
        <colgroup>
          <col width="20%" />
          <col />
          <col width="10%" />
          <col width="15%" />
          <col width="10%" />
          <col width="5%" />
        </colgroup>
        <thead>
          <tr>
            <th className="text-center">Imagem</th>
            <th>Associado</th>
            <th className="th-description">Franquia</th>
            <th className="text-right">Cidade/UF</th>
            <th className="text-right">Ofertas</th>
            <th className="text-right"></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6}>{/* <LoadingPointsSpin /> */}</td>
            </tr>
          ) : associates.length === 0 ? (
            <tr>
              <td colSpan={6}>
                <p className="text-color-default">Nenhuma associado encontrado. Tente alterar os filtros.</p>
              </td>
            </tr>
          ) : (
            associates.map((associate, i) => (
              <tr
                key={associate.EMP_CODIGO}
                className={openAssociate ? 'clickable' : ''}
                onClick={() => {
                  setAssociate(associate);
                  openAssociate && openModal('detalhesAssociado');
                }}
              >
                <td>
                  <div className="img-container text-center mx-auto">
                    <img
                      src={`${baseURL}/web/uploads/logoLoja/${associate.EMP_LOGOTIPO}`}
                      alt={associate.EMP_NOMEFANTASIA}
                    />
                  </div>
                </td>
                <td className="td-name">{associate.EMP_NOMEFANTASIA}</td>
                <td>{associate.MATRIZ_NOMEFANTASIA}</td>
                <td className="td-number">
                  {associate.LEN_CIDADE},
                  <br />
                  <span style={{ color: '#aaa' }}>{associate.LEN_ESTADO}</span>
                </td>
                <td className="td-number">{associate.OFERTAS}</td>
                <td className="td-actions"></td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
};

TableAssociates.defaultProps = defaultProps;

export default TableAssociates;
