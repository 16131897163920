import React, { useContext } from 'react';
import { TableNegotiationsProps } from '~/types';
import { AuthContext } from '~/context/AuthContext';
import { NegotiationSituation } from '~/enum';
import { formatNumberWithoutPrefix } from '~/utils/util';

const TableNegotiations: React.FunctionComponent<TableNegotiationsProps> = ({
  isLoading,
  negotiations,
  onSelectNegotiation,
  execTableAction: execNegotiationAction,
}) => {
  const { company: empresa } = useContext(AuthContext);

  const getColorOfIcon = (negotiation: any) => {

    const conditions = [
      {
        condition: negotiation.ONE_STATUS === NegotiationSituation.Fechado && negotiation.LAN_DATA_RECEBIDO !== '',
        color: '#cccccc',
      },
      {
        condition:
          negotiation.ONE_STATUS === NegotiationSituation.Fechado &&
          negotiation.ORC_TIPO === 'Venda' &&
          negotiation.LAN_RECEBIDODESTINATARIO !== 'S',
        color: '#8ece31',
      },
      {
        condition:
          negotiation.ONE_STATUS === NegotiationSituation.Fechado &&
          negotiation.ORC_TIPO === 'Compra' &&
          negotiation.LAN_RECEBIDOSOLICITANTE !== 'S',
        color: '#8ece31',
      },
      {
        condition: negotiation.ONE_STATUS === NegotiationSituation.Negociando,
        color: '#fba534',
      },
      {
        condition: negotiation.ONE_STATUS === NegotiationSituation.Recusado,
        color: 'red',
      },
      {
        condition:
          negotiation.ONE_STATUS === NegotiationSituation.Fechado &&
          negotiation.ORC_TIPO === 'Venda' &&
          negotiation.LAN_RECEBIDODESTINATARIO === 'S',
        color: '#cccccc',
      },
      {
        condition:
          negotiation.ONE_STATUS === NegotiationSituation.Fechado &&
          negotiation.ORC_TIPO === 'Compra' &&
          negotiation.LAN_RECEBIDOSOLICITANTE === 'S',
        color: '#cccccc',
      },
    ];

    const match = conditions.find(({ condition }) => condition);
    return match ? match.color : undefined;
  };

  const getTitleOfIcon = (negotiation: any) => {
    if (
      (negotiation?.ORC_TIPO == 'Venda' && negotiation?.LAN_RECEBIDODESTINATARIO === 'S') ||
      (negotiation?.ORC_TIPO == 'Compra' && negotiation?.LAN_RECEBIDOSOLICITANTE === 'S') ||
      negotiation?.LAN_DATA_RECEBIDO !== ''
    ) {
      return 'A comissão já foi paga';
    } else {
      return 'pagar comissão';
    }
  };

  return (
    <table className="table">
      <colgroup>
        <col width="70px" />
        <col />
        <col />
        <col width="45px" />
        <col width="100px" />
        <col width="70px" />
        <col width="140px" />
        <col width="110px" />
      </colgroup>
      <thead>
        <tr>
          <th className="text-center">Orçam.</th>
          <th className="text-left">Associado</th>
          <th className="text-left">Oferta</th>
          <th>Tipo</th>
          <th className="text-center">Atualização</th>
          <th>Valor</th>
          <th>Situação</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={8}>{/* <LoadingPointsSpin /> */}</td>
          </tr>
        ) : negotiations.length === 0 ? (
          <tr>
            <td colSpan={8}>
              <p className="text-color-default">Nenhuma negociação encontrada. Tente alterar os filtros.</p>
            </td>
          </tr>
        ) : (
          negotiations.map((negotiation) => (
            <tr key={negotiation.ORC_CODIGO}>
              <td
                className="text-center clickable"
                onClick={() => {
                  onSelectNegotiation(negotiation);
                }}
              >
                {negotiation.ORC_CODIGO}
              </td>
              <td
                className="text-left clickable"
                onClick={() => {
                  onSelectNegotiation(negotiation);
                }}
              >
                {negotiation.ORC_TIPO == 'Venda' ? negotiation.SOLICITANTE : negotiation.DESTINATARIO}
              </td>
              <td
                className="text-left clickable"
                onClick={() => {
                  onSelectNegotiation(negotiation);
                }}
              >
                {negotiation.OFE_TITULO}
              </td>
              <td
                className="text-left clickable"
                onClick={() => {
                  onSelectNegotiation(negotiation);
                }}
              >
                <span
                  className={`highlight-letter-badge ${
                    empresa.EMP_TIPO !== 'A'
                      ? '--'
                      : negotiation.ORC_TIPO === 'Compra'
                      ? 'highlight-letter-badge-red'
                      : 'highlight-letter-badge-green'
                  }`}
                >
                  {negotiation.ORC_TIPO[0]}
                </span>
              </td>
              <td
                className="text-center clickable"
                onClick={() => {
                  onSelectNegotiation(negotiation);
                }}
              >
                {negotiation.ONE_DATA}
              </td>
              <td
                className="text-left clickable"
                onClick={() => {
                  onSelectNegotiation(negotiation);
                }}
              >
                {negotiation.ONE_VALOR === '0' ? '(N/I)' : formatNumberWithoutPrefix(+negotiation.ONE_VALOR)}
              </td>
              <td
                className="text-left clickable"
                onClick={() => {
                  onSelectNegotiation(negotiation);
                }}
              >
                {negotiation.ONE_STATUS === NegotiationSituation.Fechado && (
                  <>
                    <i className="fas fa-check-double" /> Fechado
                  </>
                )}
                {negotiation.ONE_STATUS === NegotiationSituation.Negociando && (
                  <>
                    <i className="fas fa-check" /> Em Negociação
                  </>
                )}
                {negotiation.ONE_STATUS === NegotiationSituation.Recusado && (
                  <>
                    <i className="fas fa-times" /> Recusado
                  </>
                )}
              </td>
              <td>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {negotiation.ONE_STATUS !== NegotiationSituation.Fechado ? (
                    <i
                      className="clickable fa fa-comments icon-medium"
                      style={{ color: '#fba534' }}
                      title="responder"
                      onClick={() => execNegotiationAction(negotiation, 'negotiationRespose')}
                    />
                  ) : (
                    <i className="fa fa-comments icon-medium" style={{ color: '#ccc' }} title="responder" />
                  )}
                  <i
                    className={`fa fa-money-bill-alt icon-medium ${
                      (negotiation.ONE_STATUS === NegotiationSituation.Fechado &&
                        negotiation?.ORC_TIPO == 'Venda' &&
                        negotiation?.LAN_RECEBIDODESTINATARIO !== 'S') ||
                      (negotiation?.ORC_TIPO == 'Compra' && negotiation?.LAN_RECEBIDOSOLICITANTE !== 'S')
                        ? 'clickable'
                        : ''
                    }`}
                    style={{
                      color: getColorOfIcon(negotiation),
                    }}
                    title={getTitleOfIcon(negotiation)}
                    onClick={() => execNegotiationAction(negotiation, 'payCommission')}
                  />
                  <i
                    className="clickable fa fa-list icon-medium"
                    style={{}}
                    title="ver histórico"
                    onClick={() => execNegotiationAction(negotiation, 'showHistory')}
                  />
                  {negotiation.ONE_STATUS === NegotiationSituation.Fechado ? (
                    <i
                      className="fa fa-print icon-medium clickable"
                      onClick={() => execNegotiationAction(negotiation, 'printVoucher')}
                      title="imprimir voucher"
                    />
                  ) : (
                    <i className="fa fa-print icon-medium" style={{ color: '#ccc' }} title="imprimir voucher" />
                  )}
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default TableNegotiations;
