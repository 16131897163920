import React, { useContext, useEffect, useState } from 'react';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import { AuthContext } from '~/context/AuthContext';
import { getParametersFranchise, saveParametersFranchise } from '~/service/ParametersService';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import PageLoading from '~/components/loading/PageLoading';
import { ParametersFranchiseAccounts } from '~/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Select, { mountOptions } from './SelectGroup';
import MoneyMask from './MoneyMask';

interface FormParametersFranchise {
  ecc_codroyaltyfranq: string;
  ecc_codassociado: string;
  ecc_codremuneracao: string;
  ecc_codentrantes: string;
  ecc_codrenovacao: string;
  ge_comremper?: number;
  ge_comremval?: number;
  ge_comentper?: number;
  ge_comentval?: number;
  ge_comrenper?: number;
  ge_comrenval?: number;
  cn_comremper?: number;
  cn_comremval?: number;
  cn_comentper?: number;
  cn_comentval?: number;
  cn_comrenper?: number;
  cn_comrenval?: number;
  co_comremper?: number;
  co_comremval?: number;
  co_comentper?: number;
  co_comentval?: number;
  co_comrenper?: number;
  co_comrenval?: number;
}

const Parametros: React.FC = () => {
  const { company: empresa } = useContext(AuthContext);

  const [incomeAccount, setIncomeAccount] = useState<ParametersFranchiseAccounts[]>([]);
  const [expenseAccount, setExpenseAccount] = useState<ParametersFranchiseAccounts[]>([]);
  const [parametersFranchiseAccounts, setParametersFranchiseAccounts] = useState<ParametersFranchiseAccounts[]>([]);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [chosenFranchiseParameters, setChosenFranchiseParameters] = useState<FormParametersFranchise | undefined>(undefined);

  const { watch, control, handleSubmit, errors } = useForm<FormParametersFranchise>({
    resolver: yupResolver(
      yup.object().shape({
        ecc_codroyaltyfranq: yup.string().required(),
        ecc_codassociado: yup.string().required(),
        ecc_codremuneracao: yup.string().required(),
        ecc_codentrantes: yup.string().required(),
        ecc_codrenovacao: yup.string().required(),
        ge_comremper: yup.number(),
        ge_comremval: yup.number(),
        ge_comentper: yup.number(),
        ge_comentval: yup.number(),
        ge_comrenper: yup.number(),
        ge_comrenval: yup.number(),
        cn_comremper: yup.number(),
        cn_comremval: yup.number(),
        cn_comentper: yup.number(),
        cn_comentval: yup.number(),
        cn_comrenper: yup.number(),
        cn_comrenval: yup.number(),
        co_comremper: yup.number(),
        co_comremval: yup.number(),
        co_comentper: yup.number(),
        co_comentval: yup.number(),
        co_comrenper: yup.number(),
        co_comrenval: yup.number(),
      })
    ),
  });

  useEffect(() => {
    fetchParametersFranchise();
  }, []);

  const fetchParametersFranchise = async () => {
    setIsLoading(true);
    const { success, payload, error } = await getParametersFranchise(empresa.EMP_CODIGO);
    setIsLoading(false);

    if (success) {
      const { gruposContasReceita, gruposContasDespesa, contasPlanosContas } = payload;
      const { ecc_codroyaltyfranq, ecc_codassociado, ecc_codremuneracao, ecc_codentrantes, ecc_codrenovacao, perfis } = payload;
      const ge = perfis.find((p: any) => p.PER_DESCRICAO.includes('Gerente'));
      const cn = perfis.find((p: any) => p.PER_DESCRICAO.includes('Coordenador'));
      const co = perfis.find((p: any) => p.PER_DESCRICAO.includes('Consultor'));

      setChosenFranchiseParameters({
        ecc_codroyaltyfranq,
        ecc_codassociado,
        ecc_codremuneracao,
        ecc_codentrantes,
        ecc_codrenovacao,
        ge_comremper: ge.PER_COMREMPER?.replace(',', '.'),
        ge_comremval: ge.PER_COMREMVAL?.replace(',', '.'),
        ge_comentper: ge.PER_COMENTPER?.replace(',', '.'),
        ge_comentval: ge.PER_COMENTVAL?.replace(',', '.'),
        ge_comrenper: ge.PER_COMRENPER?.replace(',', '.'),
        ge_comrenval: ge.PER_COMRENVAL?.replace(',', '.'),
        cn_comremper: cn.PER_COMREMPER?.replace(',', '.'),
        cn_comremval: cn.PER_COMREMVAL?.replace(',', '.'),
        cn_comentper: cn.PER_COMENTPER?.replace(',', '.'),
        cn_comentval: cn.PER_COMENTVAL?.replace(',', '.'),
        cn_comrenper: cn.PER_COMRENPER?.replace(',', '.'),
        cn_comrenval: cn.PER_COMRENVAL?.replace(',', '.'),
        co_comremper: co.PER_COMREMPER?.replace(',', '.'),
        co_comremval: co.PER_COMREMVAL?.replace(',', '.'),
        co_comentper: co.PER_COMENTPER?.replace(',', '.'),
        co_comentval: co.PER_COMENTVAL?.replace(',', '.'),
        co_comrenper: co.PER_COMRENPER?.replace(',', '.'),
        co_comrenval: co.PER_COMRENVAL?.replace(',', '.'),
      });

      setIncomeAccount(gruposContasReceita[0].FILHOS);
      setExpenseAccount(gruposContasDespesa[0].FILHOS);
      setParametersFranchiseAccounts(contasPlanosContas);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const handleOnSubmit = async (formParametersFranchise: FormParametersFranchise) => {
    setIsAwaitResponse(true);
    const { success, payload, message, error } = await saveParametersFranchise({ ...formParametersFranchise, emp_codigo: empresa.EMP_CODIGO });
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: message });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Parâmetros Gerais" />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <form key={`${chosenFranchiseParameters}`} onSubmit={handleSubmit(handleOnSubmit, console.log)}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Select
                      control={control}
                      options={mountOptions(expenseAccount, parametersFranchiseAccounts)}
                      name="ecc_codroyaltyfranq"
                      defaultValue={chosenFranchiseParameters?.ecc_codroyaltyfranq as any}
                      hasError={!!errors.ecc_codroyaltyfranq}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <p>
                    Conta de lançamento onde <b>será pago o royalty para a franqueadora</b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Select
                      control={control}
                      options={mountOptions(incomeAccount, parametersFranchiseAccounts)}
                      name="ecc_codassociado"
                      defaultValue={chosenFranchiseParameters?.ecc_codassociado as any}
                      hasError={!!errors.ecc_codassociado}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <p>
                    Conta de lançamento onde <b>será recebida a comissão paga pelos associados</b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Select
                      control={control}
                      options={mountOptions(expenseAccount, parametersFranchiseAccounts)}
                      name="ecc_codremuneracao"
                      defaultValue={chosenFranchiseParameters?.ecc_codremuneracao as any}
                      hasError={!!errors.ecc_codremuneracao}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <p>
                    Conta de lançamento onde <b>será paga as comissões de remuneração</b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Select
                      control={control}
                      options={mountOptions(expenseAccount, parametersFranchiseAccounts)}
                      name="ecc_codentrantes"
                      defaultValue={chosenFranchiseParameters?.ecc_codentrantes as any}
                      hasError={!!errors.ecc_codentrantes}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <p>
                    Conta de lançamento onde <b>será paga as comissões entrantes</b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Select
                      control={control}
                      options={mountOptions(expenseAccount, parametersFranchiseAccounts)}
                      name="ecc_codrenovacao"
                      defaultValue={chosenFranchiseParameters?.ecc_codrenovacao as any}
                      hasError={!!errors.ecc_codrenovacao}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <p>
                    Conta de lançamento onde <b>será paga as comissões de renovação</b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card table-with-links">
                    <div className="card-header">
                      <h4 className="card-title">Comissões x perfil</h4>
                      <p className="card-category">Defina sobre as comissões e remunerações por perfil na plataforma</p>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center"></th>
                            <th className="text-center" colSpan={2} style={{ fontWeight: 'bold' }}>
                              Comissão Remuneração
                            </th>
                            <th className="text-center" colSpan={2} style={{ fontWeight: 'bold' }}>
                              Comissão Entrantes
                            </th>
                            <th className="text-center" colSpan={2} style={{ fontWeight: 'bold' }}>
                              Comissão Renovação
                            </th>
                            <th></th>
                          </tr>
                          <tr>
                            <th className="text-center">Perfil</th>
                            <th className="text-center">Percentual</th>
                            <th className="text-center">Valor</th>
                            <th className="text-center">Percentual</th>
                            <th className="text-center">Valor</th>
                            <th className="text-center">Percentual</th>
                            <th className="text-center">Valor</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left">Gerente de Negócios</td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.ge_comremper) || undefined}
                                  name="ge_comremper"
                                  suffix="%"
                                  isDisabled={watch('ge_comremval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.ge_comremval) || undefined}
                                  name="ge_comremval"
                                  prefix="R$"
                                  isDisabled={watch('ge_comremper') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.ge_comentper) || undefined}
                                  name="ge_comentper"
                                  suffix="%"
                                  isDisabled={watch('ge_comentval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.ge_comentval) || undefined}
                                  name="ge_comentval"
                                  prefix="R$"
                                  isDisabled={watch('ge_comentper') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.ge_comrenper) || undefined}
                                  name="ge_comrenper"
                                  suffix="%"
                                  isDisabled={watch('ge_comrenval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.ge_comrenval) || undefined}
                                  name="ge_comrenval"
                                  prefix="R$"
                                  isDisabled={watch('ge_comrenper') !== undefined}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">Coordenador de Negócio</td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.cn_comremper) || undefined}
                                  name="cn_comremper"
                                  suffix="%"
                                  isDisabled={watch('cn_comremval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.cn_comremval) || undefined}
                                  name="cn_comremval"
                                  prefix="R$"
                                  isDisabled={watch('cn_comremper') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.cn_comentper) || undefined}
                                  name="cn_comentper"
                                  suffix="%"
                                  isDisabled={watch('cn_comentval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.cn_comentval) || undefined}
                                  name="cn_comentval"
                                  prefix="R$"
                                  isDisabled={watch('cn_comentper') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.cn_comrenper) || undefined}
                                  name="cn_comrenper"
                                  suffix="%"
                                  isDisabled={watch('cn_comrenval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.cn_comrenval) || undefined}
                                  name="cn_comrenval"
                                  prefix="R$"
                                  isDisabled={watch('cn_comrenper') !== undefined}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">Consultor de Negócio</td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.co_comremper) || undefined}
                                  name="co_comremper"
                                  suffix="%"
                                  isDisabled={watch('co_comremval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.co_comremval) || undefined}
                                  name="co_comremval"
                                  prefix="R$"
                                  isDisabled={watch('co_comremper') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.co_comentper) || undefined}
                                  name="co_comentper"
                                  suffix="%"
                                  isDisabled={watch('co_comentval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.co_comentval) || undefined}
                                  name="co_comentval"
                                  prefix="R$"
                                  isDisabled={watch('co_comentper') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.co_comrenper) || undefined}
                                  name="co_comrenper"
                                  suffix="%"
                                  isDisabled={watch('co_comrenval') !== undefined}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <MoneyMask
                                  control={control}
                                  defaultValue={Number(chosenFranchiseParameters?.co_comrenval) || undefined}
                                  name="co_comrenval"
                                  prefix="R$"
                                  isDisabled={watch('co_comrenper') !== undefined}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer" style={{ display: 'flex', justifyContent: 'center' }}>
                      <button className="btn btn-success btn-wd" type="submit" disabled={isAwaitResponse}>
                        {isAwaitResponse ? (
                          <>
                            <i className="fas fa-spinner rotating" /> Processando...
                          </>
                        ) : (
                          <>
                            <i className="fa fa-save" /> Salvar
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Parametros;
