import api from '~/config/api';

export const getAccountsPayableReceivable = async ({ emp_codigo, tipo, campo_pesquisa, tipo_data, data_inicial, data_final, baixados }) => {
  try {
    const { data } = await api.post('/api/contas-a-pagar-receber/carregar', {
      emp_codigo,
      tipo,
      campo_pesquisa,
      tipo_data,
      data_inicial,
      data_final,
      baixados,
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};
