import React, { useContext, useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import SelectGroup from './SelectGroup';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import Dropzone from 'react-dropzone';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  FileCustom,
  FileCustomInterface,
  FileFinance,
  FormFinance,
  ViewModalFinanceProps,
  SelectControlledOption,
} from '~/types';
import SelectControlled from '~/components/input/SelectControlled';
import { autoCompleteFinances, saveFinance } from '~/service/financeiro/FinanceService';
import { closeModal, correctNumberWithComma, formatCNPJ, formatCPF, openModal } from '~/utils/util';
import { AuthContext } from '~/context/AuthContext';
import { mountOptions } from '~/pages/franquia/parametros/SelectGroup';
import BasicInput from '~/components/input/BasicInput';
import MoneyMaskControlled from '~/components/input/MaskMoneyControlled';
import SweetAlert from 'react-bootstrap-sweetalert';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import ModalCreateProvider from './ModalCreateProvider';
import SelectAsyncControlled from '~/components/input/SelectAsyncControlled';
import moment from 'moment';

const FormFinanceSchema: yup.SchemaOf<FormFinance> = yup.object().shape({
  ECC_TIPO: yup.mixed<'R' | 'D'>().required(),
  cfo_codigo: yup.number().required(),
  ecc_codigo: yup.number().required(),
  fpa_codigo: yup.number().required(),
  lan_datavencimento: yup.string().required(),
  lan_valor: yup.number().required(),
  vezes: yup.number().required(),
  cba_codigo: yup.number().required(),
  lan_documento: yup.string(),
  lan_recebido: yup.mixed<'S' | 'N'>().when('parcela', {
    is: 1,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  lan_datarecebido: yup
    .string()
    .when('lan_recebido', { is: 'S', then: yup.string().required(), otherwise: yup.string() }),
  lan_valorrecebido: yup
    .number()
    .when('lan_recebido', { is: 'S', then: yup.number().required(), otherwise: yup.number() }),
  lan_valordesconto: yup.number(),
  lan_valorjurosmulta: yup.number(),
  parcela: yup.array(yup.string().required()).required(),
  valor: yup.array(yup.number().required()).required(),
  vencimento: yup.array(yup.string().required()).required(),
  documento: yup.array().of(yup.string()).notRequired(),
});

const ViewModalFinance: React.FunctionComponent<ViewModalFinanceProps> = ({
  finance,
  paymentOptions,
  groupAccounts,
  entryAccount,
  bankAccounts,
  filesFinance,
  isAwaitResponse,
  onSubmit,
  onDelete,
}) => {
  const modelRef = useRef<HTMLDivElement>(null);
  const { user: usuario, company: empresa } = useContext(AuthContext);
  const [sweetAlertSave, setSweetAlertSave] = useState<JSX.Element | undefined>(undefined);
  const [sweetAlertDelete, setSweetAlertDelete] = useState<JSX.Element | undefined>(undefined);
  const [files, setFiles] = useState<FileCustom<false, FileFinance>[]>([]);

  const { watch, control, register, handleSubmit, errors, setValue, reset } = useForm<FormFinance>({
    resolver: yupResolver(FormFinanceSchema),
  });

  useEffect(() => {
    modelRef.current?.addEventListener('focus', overflowOnFocus);
    return () => {
      modelRef.current?.removeEventListener('focus', overflowOnFocus);
    };
  }, []);

  const overflowOnFocus = () => {
    document.body.classList.add('modal-open');
  };

  useEffect(() => {
    if (finance)
      setFiles(
        filesFinance
          .filter((file) => file.LAN_CODIGO === finance.LAN_CODIGO)
          .map((file) => ({
            ...file,
            isNew: false,
            name: file.LAX_DESCRICAO,
            path: file.LAX_CAMINHO,
          }))
      );
    else reset();
  }, [finance]);

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) return <p className="dropContainterMessageDefault">Solte o documento aqui</p>;
    if (isDragReject) return <p className="dropContainterMessageDanger">Arquivo não suportado...</p>;

    return <p className="dropContainterMessageSuccess">Solte os arquivos aqui...</p>;
  };

  const handleOnSubmit = async (formFinance: FormFinance) => {
    if (finance !== undefined && formFinance.vezes !== 1) {
      openSweetAlertSaveFinance(formFinance);
    } else {
      await onSubmit({ ...formFinance, tipoAlteracao: 1, files: files });
    }
  };

  const generateParcels = (quantity: number): { current: number; total: number }[] => {
    const arr: { current: number; total: number }[] = [];
    for (let index = 0; index < quantity; index++) {
      arr.push({ current: index + 1, total: quantity });
    }
    return arr;
  };

  const openSweetAlertSaveFinance = (formFinance: FormFinance) => {
    let changeType: number = 0;

    setSweetAlertSave(
      <SweetAlert
        warning
        title="Atenção!"
        onConfirm={() => {
          if (changeType !== 0) {
            onSubmit({ ...formFinance, tipoAlteracao: changeType, files: files });
            setSweetAlertSave(undefined);
          }
        }}
        onCancel={() => setSweetAlertSave(undefined)}
        allowEscape={true}
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Confirmar e Salvar"
        cancelBtnText="Cancelar"
      >
        <>
          <p>
            Olá {usuario.USU_TRATAMENTO || usuario.USU_NOME}, você está prestes a alterar um pagamento que está
            repetindo em outras datas.
          </p>
          <p>Quais lançamentos você deseja alterar agora?</p>
          <br />
          <div style={{ textAlign: 'start' }}>
            <div className="form-check form-check-radio">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value={1}
                  name="changeType"
                  // checked={changeType === 1}
                  onChange={(e) => (changeType = +e.target.value)}
                />
                <span className="form-check-sign"></span>
                <span style={{ textTransform: 'none', color: '#212529' }}>
                  Alterar apenas <strong>este</strong> lançamento.{' '}
                  <i style={{ fontSize: 'inherit' }}>(todos os outros lançamentos serão mantidos)</i>
                </span>
              </label>
            </div>
            <div className="form-check form-check-radio">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value={2}
                  name="changeType"
                  // checked={changeType === 2}
                  onChange={(e) => (changeType = +e.target.value)}
                />
                <span className="form-check-sign"></span>
                <span style={{ textTransform: 'none', color: '#212529' }}>
                  Alterar <strong>este e os próximos</strong> lançamentos.{' '}
                  <i style={{ fontSize: 'inherit', color: 'red' }}>(exceto data de vencimento)</i>
                </span>
              </label>
            </div>
            <div className="form-check form-check-radio">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value={3}
                  name="changeType"
                  // checked={changeType === 3}
                  onChange={(e) => (changeType = +e.target.value)}
                />
                <span className="form-check-sign"></span>
                <span style={{ textTransform: 'none', color: '#212529' }}>
                  Alterar <strong>todos</strong> os lançamentos, <strong>exceto os baixados.</strong>{' '}
                  <i style={{ fontSize: 'inherit', color: 'red' }}>(exceto data de vencimento)</i>
                </span>
              </label>
            </div>
            <div className="form-check form-check-radio">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value={4}
                  name="changeType"
                  // checked={changeType === 4}
                  onChange={(e) => (changeType = +e.target.value)}
                />
                <span className="form-check-sign"></span>
                <span style={{ textTransform: 'none', color: '#212529' }}>
                  Alterar <strong>todos</strong> os lançamentos.{' '}
                  <i style={{ fontSize: 'inherit', color: 'red' }}>(exceto data de vencimento)</i>
                </span>
              </label>
            </div>
          </div>
        </>
      </SweetAlert>
    );
  };

  const openSweetAlertDeleteFinance = (formFinance: any = {}) => {
    let changeType: number = 0;
    const vezes = Number(finance?.LAN_PARCELA.split('/')[1]);

    setSweetAlertDelete(
      <SweetAlert
        danger
        title="Atenção!"
        onConfirm={() => {
          if (finance && ((vezes !== 1 && changeType !== 0) || vezes === 1)) {
            onDelete({ lan_codigo: finance.LAN_CODIGO, lan_numero: finance.LAN_NUMERO, emp_codigo: empresa.EMP_CODIGO, change_type: changeType });
            setSweetAlertDelete(undefined);
          }
        }}
        onCancel={() => setSweetAlertDelete(undefined)}
        allowEscape={true}
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        confirmBtnText="EXCLUIR!"
        cancelBtnText="Cancelar"
        // confirmBtnCssClass='disabled'
      >
        <>
          <p>Olá, {usuario.USU_TRATAMENTO || usuario.USU_NOME}. Você está prestes a excluir um pagamento.</p>
          {vezes !== 1 && <p>Quais lançamentos você deseja excluir agora?</p>}
          <p className="text-danger font-weight-bold">Esta ação é irreversível!</p>
          <br />
          <br />
          {vezes !== 1 && (
            <div style={{ textAlign: 'center' }}>
              <div className="form-check form-check-radio">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value={1}
                    name="changeType"
                    // checked={changeType === 1}
                    onChange={(e) => (changeType = +e.target.value)}
                  />
                  <span className="form-check-sign"></span>
                  <span className="font-weight-bold" style={{ textTransform: 'none', color: '#212529' }}>
                    Excluir apenas este
                  </span>
                </label>
              </div>
              <div className="form-check form-check-radio">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value={2}
                    name="changeType"
                    // checked={changeType === 2}
                    onChange={(e) => (changeType = +e.target.value)}
                  />
                  <span className="form-check-sign"></span>
                  <span className="font-weight-bold" style={{ textTransform: 'none', color: '#212529' }}>
                    Excluir todos
                  </span>
                </label>
              </div>
            </div>
          )}
        </>
      </SweetAlert>
    );
  };

  return (
    <>
      <div
        className="modal fade"
        id="ModalFinance"
        role="dialog"
        aria-labelledby="usuarioLabel"
        aria-hidden="true"
        tabIndex={-1}
        ref={modelRef}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="card stacked-form">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Cobrança</h4>
                        <p className="card-category">A pagar ou receber</p>
                      </div>
                      <div className="card-body content-full-width">
                        <ul role="tablist" className="nav nav-tabs">
                          <li role="presentation" className="nav-item show active">
                            <a className="nav-link" id="info-tab" href="#icon-info" data-toggle="tab">
                              <i className="fa fa-info"></i> Dados Gerais
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="account-tab" href="#icon-account" data-toggle="tab">
                              <i className="fa fa-list"></i> Parcelas
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="style-tab" href="#icon-style" data-toggle="tab">
                              <i className="fa fa-link" /> Anexo
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            id="icon-info"
                            className="tab-pane fade show active"
                            role="tabpanel"
                            aria-labelledby="info-tab"
                          >
                            <form
                              key={finance?.LAN_CODIGO + 'ModalFinanceId'}
                              onSubmit={handleSubmit(handleOnSubmit, console.log)}
                              id="ModalFinanceId"
                            >
                              <SelectControlled
                                isRequired
                                control={control}
                                defaultValue={finance?.ECC_TIPO || ''}
                                options={[
                                  { value: 'R', label: 'Receita' },
                                  { value: 'D', label: 'Despesa' },
                                ]}
                                hasError={!!errors.ECC_TIPO}
                                name="ECC_TIPO"
                                label="Tipo"
                                onBlur={() => setValue('ecc_codigo', '')}
                              />

                              <SelectAsyncControlled
                                control={control}
                                defaultValue={finance?.CFO_CODIGO || '0'}
                                placeholder="Pesquise por nome, telefone ou cpf"
                                defaultOptions={[
                                  {
                                    value: finance ? finance.CFO_CODIGO : '',
                                    label: finance
                                      ? `${finance.CFO_RAZAOSOCIAL} ${
                                          finance.CFO_CPFCNPJ === ''
                                            ? ''
                                            : finance.CFO_CPFCNPJ.length === 14
                                            ? `(${formatCNPJ(finance.CFO_CPFCNPJ)})`
                                            : `(${formatCPF(finance.CFO_CPFCNPJ)})`
                                        }`
                                      : '',
                                  },
                                ]}
                                hasError={!!errors.cfo_codigo}
                                name="cfo_codigo"
                                label={
                                  <>
                                    Nome fantasia
                                    <span className="star" />
                                    &nbsp;&nbsp;
                                    <small className="link-hover" onClick={() => openModal('ModalCreateProvider')}>
                                      (Cadastrar Clientes ou Fornecedores)
                                    </small>
                                  </>
                                }
                                loadOptions={async (value) => {
                                  const response: string = await autoCompleteFinances(value, empresa.EMP_CODIGO);

                                  return response
                                    .split('\n')
                                    .filter((r) => r !== '')
                                    .map((row: string) => {
                                      const data = row.split('|');
                                      return {
                                        value: data[1],
                                        label: `${data[0]} ${
                                          data.length >= 3
                                            ? `(${data[2].length === 14 ? formatCNPJ(data[2]) : formatCPF(data[2])})`
                                            : ''
                                        }`,
                                      };
                                    });
                                }}
                              />

                              <div className="form-group">
                                <label>
                                  Conta de Lançamento
                                  <span className="star" />
                                </label>
                                <SelectGroup
                                  key={watch('ECC_TIPO') + 'ecc_codigo'}
                                  control={control}
                                  defaultValue={finance?.ECC_CODIGO || ''}
                                  options={mountOptions(
                                    groupAccounts.filter((g) => g.ECC_TIPO === watch('ECC_TIPO')),
                                    entryAccount
                                  )}
                                  name="ecc_codigo"
                                  hasError={!!errors.ecc_codigo}
                                  noOptionsMessage="Escolha o tipo de cobrança..."
                                />
                              </div>

                              <SelectControlled
                                isRequired
                                control={control}
                                defaultValue={finance?.FPA_CODIGO || ''}
                                options={paymentOptions.map((paymentOption) => ({
                                  value: paymentOption.FPA_CODIGO,
                                  label: paymentOption.FPA_DESCRICAO,
                                }))}
                                hasError={!!errors.fpa_codigo}
                                name="fpa_codigo"
                                label="Forma de Pagamento"
                              />

                              <BasicInput
                                isRequired
                                control={control}
                                type="date"
                                defaultValue={finance?.LAN_DATAVENCIMENTO.split('/').reverse().join('-')}
                                hasError={!!errors.lan_datavencimento}
                                name="lan_datavencimento"
                                label="Vencimento"
                              />

                              <MoneyMaskControlled
                                control={control}
                                isRequired
                                defaultValue={
                                  Number(finance?.LAN_VALOR.replace('.', '').replace(',', '.')) || undefined
                                }
                                hasError={!!errors.lan_valor}
                                name="lan_valor"
                                label="Valor da Parcela"
                                prefix="R$"
                              />

                              <BasicInput
                                isRequired
                                control={control}
                                type="number"
                                defaultValue={Number(finance?.LAN_PARCELA.split('/')[1]) || undefined}
                                hasError={!!errors.vezes}
                                name="vezes"
                                label="Quantidade de Parcelas"
                                isDisabled={finance !== undefined}
                              />

                              <SelectControlled
                                isRequired
                                control={control}
                                defaultValue={finance?.CBA_CODIGO || ''}
                                options={bankAccounts.map((bankAccount) => ({
                                  value: bankAccount.CBA_CODIGO,
                                  label: bankAccount.CBA_DESCRICAO,
                                }))}
                                hasError={!!errors.cba_codigo}
                                name="cba_codigo"
                                label="Conta Bancária"
                              />

                              <BasicInput
                                control={control}
                                type="text"
                                defaultValue={finance?.LAN_DOCUMENTO || undefined}
                                hasError={!!errors.lan_documento}
                                name="lan_documento"
                                label="Nro Documento"
                              />

                              {(finance !== undefined || watch('vezes') === 1 || watch('vezes') === undefined) && (
                                <>
                                  <hr />
                                  <h3>Informações de baixa</h3>

                                  <SelectControlled
                                    isRequired
                                    control={control}
                                    defaultValue={finance?.LAN_RECEBIDO || 'N'}
                                    options={[
                                      { value: 'S', label: 'Sim' },
                                      { value: 'N', label: 'Não' },
                                    ]}
                                    hasError={!!errors.lan_recebido}
                                    name="lan_recebido"
                                    label="Baixado"
                                  />

                                  <BasicInput
                                    isRequired={watch('lan_recebido') === 'S'}
                                    isDisabled={watch('lan_recebido') === 'N'}
                                    control={control}
                                    type="date"
                                    defaultValue={finance?.LAN_DATARECEBIDO.split('/').reverse().join('-') || undefined}
                                    hasError={!!errors.lan_datarecebido}
                                    name="lan_datarecebido"
                                    label="Data Pagamento"
                                  />

                                  <MoneyMaskControlled
                                    key={watch('lan_valor') + (watch('lan_recebido') || '') + 'lan_valorrecebido'}
                                    isRequired={watch('lan_recebido') === 'S'}
                                    readOnly
                                    control={control}
                                    defaultValue={
                                      finance?.LAN_DATARECEBIDO
                                        ? Number(finance.LAN_VALORRECEBIDO.replace('.', '').replace(',', '.'))
                                        : watch('lan_recebido') === 'S'
                                        ? watch('lan_valor')
                                        : undefined
                                    }
                                    hasError={!!errors.lan_valorrecebido}
                                    name="lan_valorrecebido"
                                    label="Valor Pago"
                                    prefix="R$"
                                  />

                                  <MoneyMaskControlled
                                    control={control}
                                    defaultValue={
                                      finance?.LAN_VALORDESCONTO
                                        ? correctNumberWithComma(finance.LAN_VALORDESCONTO)
                                        : undefined
                                    }
                                    hasError={!!errors.lan_valordesconto}
                                    name="lan_valordesconto"
                                    label="Descontos/Taxas"
                                    prefix="R$"
                                  />

                                  <MoneyMaskControlled
                                    control={control}
                                    defaultValue={
                                      finance?.LAN_VALORJUROSMULTA
                                        ? correctNumberWithComma(finance.LAN_VALORJUROSMULTA)
                                        : undefined
                                    }
                                    hasError={!!errors.lan_valorjurosmulta}
                                    name="lan_valorjurosmulta"
                                    label="Juros/Multa"
                                    prefix="R$"
                                  />
                                </>
                              )}
                            </form>
                          </div>
                          <div
                            id="icon-account"
                            className="tab-pane fade"
                            role="tabpanel"
                            aria-labelledby="account-tab"
                          >
                            {finance ? (
                              <div className="row">
                                <div
                                  className="col-md-2"
                                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                  <div className="badge-circle">
                                    <span>{finance.LAN_PARCELA}</span>
                                    <input
                                      ref={register}
                                      type="text"
                                      name={`parcela[0]`}
                                      value={finance.LAN_PARCELA}
                                      style={{ display: 'none' }}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <BasicInput
                                    key={watch('lan_datavencimento') + 'vencimento[]'}
                                    isRequired
                                    control={control}
                                    type="date"
                                    defaultValue={watch('lan_datavencimento')}
                                    hasError={!!errors.lan_datavencimento}
                                    name="vencimento[0]"
                                    label="Vencimento"
                                  />
                                </div>

                                <div className="col-md-2">
                                  <MoneyMaskControlled
                                    key={watch('lan_valor') + 'valor[]'}
                                    control={control}
                                    isRequired
                                    defaultValue={watch('lan_valor')}
                                    hasError={!!errors.lan_valor}
                                    name="valor[0]"
                                    label="Valor"
                                    prefix="R$"
                                  />
                                </div>

                                <div className="col-md-3">
                                  <BasicInput
                                    key={watch('lan_documento') + 'documento[]'}
                                    control={control}
                                    type="text"
                                    defaultValue={watch('lan_documento')}
                                    hasError={!!errors.lan_documento}
                                    name="documento[0]"
                                    label="Nro Documento"
                                  />
                                </div>
                              </div>
                            ) : (
                              generateParcels(watch('vezes')).map((parcel, i) => (
                                <div key={`parcels_${i}`} className="row">
                                  <div
                                    className="col-md-2"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        borderRadius: '50%',
                                        width: 40,
                                        height: 40,
                                        background: '#ddd',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <span>
                                        {parcel.current}/{parcel.total}
                                        <input
                                          key={watch('vezes') + `parcela[${i}]`}
                                          type="text"
                                          name={`parcela[${i}]`}
                                          value={parcel.current + '/' + parcel.total}
                                          ref={register}
                                          style={{ display: 'none' }}
                                          readOnly
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <BasicInput
                                      key={watch('lan_datavencimento') + `vencimento[${i}]`}
                                      isRequired
                                      control={control}
                                      type="date"
                                      defaultValue={moment(watch('lan_datavencimento'))
                                        .add(i, 'months')
                                        .format('YYYY-MM-DD')}
                                      hasError={!!errors.lan_datavencimento}
                                      name={`vencimento[${i}]`}
                                      label="Vencimento"
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <MoneyMaskControlled
                                      key={watch('lan_valor') + `valor[${i}]`}
                                      control={control}
                                      isRequired
                                      defaultValue={watch('lan_valor')}
                                      hasError={!!errors.lan_valor}
                                      name={`valor[${i}]`}
                                      label="Valor"
                                      prefix="R$"
                                    />
                                  </div>

                                  <div className="col-md-3">
                                    <BasicInput
                                      key={watch('lan_documento') + `documento[${i}]`}
                                      control={control}
                                      type="text"
                                      defaultValue={watch('lan_documento')}
                                      hasError={!!errors.lan_documento}
                                      name={`documento[${i}]`}
                                      label="Nro Documento"
                                    />
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                          <div id="icon-style" className="tab-pane fade" role="tabpanel" aria-labelledby="style-tab">
                            <div className="form-group">
                              <Dropzone
                                accept={['image/*', 'text/plain', 'application/*']}
                                multiple
                                onDropAccepted={(filesToAdd) => {
                                  setFiles([
                                    ...files,
                                    ...filesToAdd.map((file) => ({
                                      path: URL.createObjectURL(file),
                                      isNew: true,
                                      name: file.name,
                                      file: file,
                                    })),
                                  ]);
                                }}
                              >
                                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                  <div
                                    {...getRootProps()}
                                    className="dropContainter"
                                    style={{ borderColor: isDragReject ? '#e57878' : isDragActive ? '#78e5d5' : '' }}
                                  >
                                    <input {...getInputProps()} />
                                    {renderDragMessage(isDragActive, isDragReject)}
                                  </div>
                                )}
                              </Dropzone>
                              <div style={{ marginTop: '15px' }}>
                                {files.map((file) => (
                                  <ul>
                                    <li>
                                      <a className="link-hover" href={file.path} target="_blank">
                                        {file.name}
                                      </a>
                                      <i
                                        style={{ marginLeft: '5px', color: 'red' }}
                                        className="fas fa-times clickable"
                                        title="excluir arquivo"
                                        onClick={() => {
                                          setFiles([...files.filter((f) => f !== file)]);
                                        }}
                                      />
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div>
                <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
                  <i className="fa fa-arrow-left"></i> Voltar
                </button>
                {finance && (
                  <button
                    type="button"
                    className="btn btn-wd btn-danger ml-2"
                    onClick={() => openSweetAlertDeleteFinance({})}
                    disabled={!!sweetAlertDelete}
                  >
                    <i className="fas fa-times"></i> Excluir
                  </button>
                )}
              </div>
              <button type="submit" className="btn btn-success btn-wd" disabled={isAwaitResponse} form="ModalFinanceId">
                {isAwaitResponse ? (
                  <>
                    <i className="fas fa-spinner rotating" /> Processando...
                  </>
                ) : (
                  <>
                    <i className="fa fa-save" /> Salvar
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {sweetAlertSave}
      {sweetAlertDelete}
      <ModalCreateProvider />
    </>
  );
};

export default ViewModalFinance;
