import React, { useState, useContext, useEffect } from 'react';

import { AuthContext } from '~/context/AuthContext';
import { downloadDocuments, openModal } from '~/utils/util';
import { BankAccount, CashFlowData, CashFlowDataTable, FinanceQuota, FormFilterCashFlow } from '~/types';
import { getCashFlowData, getRegisterPerDay } from '~/service/financeiro/CashFlowService';
import { showAlertDanger } from '~/components/notification';
import moment, { Moment } from 'moment';
import { baseURL } from '~/config/api';
import ViewFluxoCaixa from '~/views/relatorioFluxoCaixa';

const fluxoCaixa: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [previousBalanceState, setPreviousBalanceState] = useState<number>(0);
  const [cashFlowDatas, setCashFlowDatas] = useState<CashFlowData[]>([]);
  const [cashFlowDataTable, setCashFlowDataTable] = useState<CashFlowDataTable[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [register, setRegister] = useState<{ incomes: FinanceQuota[]; expenses: FinanceQuota[] }>({
    incomes: [],
    expenses: [],
  });
  const [filterState, setFilterState] = useState({
    cba_codigo: 0,
    date: moment(),
  });

  useEffect(() => {
    fetchCashFlow(filterState);
  }, [filterState]);

  useEffect(() => {
    setCashFlowDataTable(
      mountBalanceData({
        selectedDate: filterState.date,
        previousBalance: previousBalanceState,
        cashFlowDatas: cashFlowDatas,
      })
    );
  }, [previousBalanceState]);

  const fetchCashFlow = async (params: FormFilterCashFlow) => {
    setIsLoading(true);
    const { success, error, payload } = await getCashFlowData({
      emp_codigo: company.EMP_CODIGO,
      cba_codigo: params.cba_codigo,
      mes: params.date.month() + 1,
      ano: params.date.year(),
    });
    setIsLoading(false);

    if (success) {
      const { contas, fluxo, saldoAnterior } = payload;

      const previousBalanceAux = +saldoAnterior.replaceAll('.', '').replaceAll(',', '.');

      setPreviousBalanceState(previousBalanceAux);
      setBankAccounts(contas);
      setCashFlowDatas(fluxo);
      setCashFlowDataTable(
        mountBalanceData({ selectedDate: params.date, previousBalance: previousBalanceAux, cashFlowDatas: fluxo })
      );
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const mountBalanceData: (params: {
    selectedDate: Moment;
    previousBalance: number;
    cashFlowDatas: CashFlowData[];
  }) => CashFlowDataTable[] = ({ selectedDate, previousBalance, cashFlowDatas }) => {
    let previousBalanceAux = previousBalance;
    let totalIncome = 0;
    let totalExpense = 0;
    const today = moment();

    const aux: CashFlowDataTable[] = cashFlowDatas.map((data) => {
      const currentDate = selectedDate.set('date', +data.dia);
      let income = 0;
      let expense = 0;

      if (currentDate >= today) {
        income = +data.valorAReceber + +data.valorRecebido;
        expense = +data.valorAPagar + +data.valorPago;
      } else {
        income = +data.valorRecebido;
        expense = +data.valorPago;
      }

      totalIncome += income;
      totalExpense += expense;
      previousBalanceAux += income - expense;

      return {
        ...data,
        previousBalance: previousBalanceAux,
        income: income,
        expense: expense,
        year: selectedDate.year(),
      };
    });

    return [
      ...aux,
      {
        dia: 'Saldo',
        income: totalIncome,
        expense: totalExpense,
        previousBalance: previousBalanceAux,
        mes: selectedDate.month().toString(),
        valorAPagar: '0',
        valorAReceber: '0',
        valorPago: '0',
        valorRecebido: '0',
      } as CashFlowDataTable,
    ];
  };

  const exportToExcel = () => {
    const mes = filterState.date.month() + 1;
    const ano = filterState.date.year();
    const cba_codigo = filterState.cba_codigo;

    const url = `${baseURL}/api/fluxo-caixa/exportar/${mes}/${ano}/${cba_codigo}/${company.EMP_CODIGO}`;
    downloadDocuments(url, 'Fluxo de Caixa - ' + mes + '_' + ano + '.xls', false);
  };

  const showRegister = async (data: CashFlowDataTable) => {
    setRegister({ incomes: [], expenses: [] });
    openModal('ModalRegistersTable');
    setIsLoadingRegister(true);
    const { success, payload, error } = await getRegisterPerDay({
      emp_codigo: company.EMP_CODIGO,
      cba_codigo: filterState.cba_codigo,
      dia: data.dia,
      mes: data.mes,
      ano: data.year,
    });
    setIsLoadingRegister(false);

    if (success) {
      const { receita, despesa } = payload;
      setRegister({ incomes: receita, expenses: despesa });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  return (
    <ViewFluxoCaixa
      isLoading={isLoading}
      isLoadingRegister={isLoadingRegister}
      previousBalanceState={previousBalanceState}
      cashFlowDataTable={cashFlowDataTable}
      bankAccounts={bankAccounts}
      register={register}
      exportToExcel={exportToExcel}
      showRegister={showRegister}
      onSubmitFilters={(params) => setFilterState(params)}
      onBalanceChange={(e) => setPreviousBalanceState(e.floatValue || 0)}
    />
  );
};

export default fluxoCaixa;
