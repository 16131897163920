import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import {
  Associate,
  AssociateWithCredit,
  FormAssociatesCreditHistorySearchParams,
  FormTransferAssociates,
  Franchise,
} from '~/types';
import LayoutMain from '~/layout/main';
import CardHeader from '~/components/card/CardHeader';
import TableWithLinks from '~/components/table/TableWithLinks';
import { getAssociatesCredit, transferAssociates } from '~/service/AssociateService';
import { matchSorter } from 'match-sorter';
import { useErrorHandler } from 'react-error-boundary';
import { closeModal, formatCNPJ, formatCPF, openModal } from '~/utils/util';
import ModalFiltro from './ModalFilter';
import ModalTransferAssociates from './ModalTransferAssociates';

const TransferenciaAssociados: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);
  const handleError = useErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [isAwaitFormResponse, setIsAwaitFormResponse] = useState(false);

  const [checkedRows, setCheckedRows] = useState<Associate[]>([]);

  const [associates, setAssociates] = useState<Associate[]>([]);
  const [associatesFiltered, setAssociatesFiltered] = useState<Associate[]>([]);
  const [franchises, setFranchises] = useState<Franchise[]>([]);

  const [searchBoxValue, setSearchBoxValue] = useState('');

  useEffect(() => {
    fetchAssociates({ franchiseId: undefined });
  }, []);

  useEffect(() => {
    setAssociatesFiltered([
      ...matchSorter(associates, searchBoxValue, { keys: ['EMP_RAZAOSOCIAL', 'EMP_NOMEFANTASIA'] }),
    ]);
  }, [searchBoxValue, associates]);

  const fetchAssociates = async (params?: FormAssociatesCreditHistorySearchParams) => {
    try {
      setIsLoading(true);
      setAssociates([]);
      const { success, payload, error } = await getAssociatesCredit({ emp_codmatriz: params?.franchiseId || '-1' });
      setIsLoading(false);

      if (success) {
        const { associados, franquias } = payload;

        setAssociates(associados);
        setFranchises(franquias);
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        if (error) console.log(error.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const onCheckRow = (checked: boolean, associate: Associate) => {
    setCheckedRows((current) => {
      const aux = current.filter(({ EMP_CODIGO }) => EMP_CODIGO != associate.EMP_CODIGO);

      if (checked) return [...aux, associate];
      else return [...aux];
    });
  };

  const onSubmitAssociateTransfer = async (formData: FormTransferAssociates) => {
    console.log('formData :>> ', formData);

    try {
      setIsAwaitFormResponse(true);
      const { success, payload, error } = await transferAssociates(formData as any);
      setIsAwaitFormResponse(false);

      if (success) {
        closeModal('ModalTransferAssociates');
        setCheckedRows([]);

        const { resultado } = payload;

        showAlertSuccess({ message: 'Associado(s) transferido(s).' });

        fetchAssociates();
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        if (error) console.log(error.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <LayoutMain
      isLoading={isLoading}
      title="Transferência de associados"
      searchBoxState={{ searchBoxValue, setSearchBoxValue }}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card table-with-links">
            <CardHeader
              title="Transferência de associados"
              subtitle="Lista de associados cadastrados"
              filterAction={() => openModal('ModalFilter')}
              actions={[
                {
                  action: () => openModal('ModalTransferAssociates'),
                  iconClass: 'fas fa-exchange-alt',
                  label: 'Transferir associado(s)',
                  variant: 'btn-primary',
                  disabled: checkedRows.length == 0,
                },
              ]}
            />

            <ModalTransferAssociates
              isLoading={isLoading}
              associates={checkedRows}
              franchises={franchises}
              isAwaitResponse={isAwaitFormResponse}
              onSubmit={onSubmitAssociateTransfer}
            />
            <ModalFiltro isLoading={isLoading} franchises={franchises} onSubmit={fetchAssociates} />

            <div className="card-body table-full-width">
              <TableWithLinks
                isLoading={isLoading}
                emptyTableMessage="Nenhum associado encontrado. Tente alterar os filtros."
                checkbox={{
                  onCheck: onCheckRow,
                  isChecked: ({ EMP_CODIGO }) => checkedRows.some((associate) => EMP_CODIGO === associate.EMP_CODIGO),
                }}
                columns={[
                  { field: 'CPFCNPJFormatted', label: 'CPF ou CNPJ', alignment: 'text-center', width: '150px' },
                  { field: 'EMP_RAZAOSOCIAL', label: 'Razão Social' },
                  { field: 'EMP_NOMEFANTASIA', label: 'N.fantasia' },
                  { field: 'MATRIZ_NOMEFANTASIA', label: 'Franquia', alignment: 'text-center' },
                  { field: 'ITI_DESCRICAO', label: 'Situação', alignment: 'text-center', width: '95px' },
                ]}
                data={associatesFiltered.map((associate) => ({
                  ...associate,
                  CPFCNPJFormatted:
                    associate.EMP_CPFCNPJ.length === 11
                      ? formatCPF(associate.EMP_CPFCNPJ)
                      : formatCNPJ(associate.EMP_CPFCNPJ),
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default TransferenciaAssociados;
