import React from 'react';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import PageLoading from '~/components/loading/PageLoading';
import IndicatorCard from '~/components/card/IndicatorCard';
import CardHeader from '~/components/card/CardHeader/index';
import { openModal } from '~/utils/util';
import TableWithLinks from '~/components/table/TableWithLinks';
import ModalFilterBalanceSheet from './ModalFilterBalanceSheet';
import { ViewBalanceteProps } from '~/types';
import LayoutMain from '~/layout/main';

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const ViewBalancete: React.FunctionComponent<ViewBalanceteProps> = ({
  isLoading,
  filterState,
  balanceSheetDatas,
  cards: { cardPreviousBalance, cardIncome, cardExpense, cardFinalBalance },
  exportToExcel,
  onSubmitFilters,
}) => {
  return (
    <LayoutMain isLoading={isLoading} title="Balancete">
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <IndicatorCard
            icon="nc-icon nc-icon nc-bullet-list-67 text-secondary"
            label="Saldo Anterior"
            value={cardPreviousBalance}
            title="Este é o seu saldo anterior"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <IndicatorCard
            icon="nc-icon nc-icon nc-bullet-list-67 text-success"
            label="Receita"
            value={cardIncome}
            title="Receita total"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <IndicatorCard
            icon="nc-icon nc-icon nc-bullet-list-67 text-danger"
            label="Despesa"
            value={cardExpense}
            title="Despesa total"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <IndicatorCard
            icon="nc-icon nc-icon nc-bullet-list-67 text-info"
            label="Saldo"
            value={cardFinalBalance}
            title="Saldo final"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <CardHeader
              title="Balancete"
              subtitle="Aplique os filtros para alterar a visualização do balancete"
              filterAction={() => openModal('ModalFilterBalanceSheet')}
              extraActions={[{ title: 'Exportar para Excel', iconClass: 'far fa-file-excel', action: exportToExcel }]}
            />

            <ModalFilterBalanceSheet onSubmit={onSubmitFilters} />

            <div className="card-body table-full-width">
              <TableWithLinks
                tableBigBoy
                isLoading={isLoading}
                boldRow={(b) => b.ECC_NUMARVORE.split('.').length !== 3}
                emptyTableMessage="Tente alterar os filtros."
                highlightOnHover
                striped
                columns={[
                  { field: 'ECC_NUMARVORE', label: 'Cód. Conta', width: '110px' },
                  { field: 'descriptionFormatted', label: 'Descrição' },
                  {
                    field: 'VALOR_TRES_MESES',
                    label: months[(filterState.mes - 4 + 12) % 12],
                    width: '110px',
                    alignment: 'text-right',
                  },
                  { field: 'VALOR_TRES_MESES_PERCENTUAL', label: '%', width: '60px' },
                  {
                    field: 'VALOR_DOIS_MESES',
                    label: months[(filterState.mes - 3 + 12) % 12],
                    width: '110px',
                    alignment: 'text-right',
                  },
                  { field: 'VALOR_DOIS_MESES_PERCENTUAL', label: '%', width: '60px' },
                  {
                    field: 'VALOR_UM_MES',
                    label: months[(filterState.mes - 2 + 12) % 12],
                    width: '110px',
                    alignment: 'text-right',
                  },
                  { field: 'VALOR_UM_MES_PERCENTUAL', label: '%', width: '60px' },
                  { field: 'VALOR', label: months[filterState.mes - 1], width: '110px', alignment: 'text-right' },
                  { field: 'VALOR_PERCENTUAL', label: '%', width: '60px' },
                ]}
                data={balanceSheetDatas.map((b) => {
                  const quantityDots = b.ECC_NUMARVORE.split('.').length;
                  return {
                    ...b,
                    descriptionFormatted: (
                      <span className={quantityDots === 2 ? 'ml-4 ' : quantityDots === 3 ? 'ml-5' : ''}>
                        {b.ECC_DESCRICAO}
                      </span>
                    ),
                    VALOR_TRES_MESES: b.VALOR_TRES_MESES.toLocaleString('pt-br', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                    VALOR_TRES_MESES_PERCENTUAL: b.VALOR_TRES_MESES_PERCENTUAL.toLocaleString('pt-br', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    }),
                    VALOR_DOIS_MESES: b.VALOR_DOIS_MESES.toLocaleString('pt-br', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                    VALOR_DOIS_MESES_PERCENTUAL: b.VALOR_DOIS_MESES_PERCENTUAL.toLocaleString('pt-br', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    }),
                    VALOR_UM_MES: b.VALOR_UM_MES.toLocaleString('pt-br', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                    VALOR_UM_MES_PERCENTUAL: b.VALOR_UM_MES_PERCENTUAL.toLocaleString('pt-br', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    }),
                    VALOR: b.VALOR.toLocaleString('pt-br', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                    VALOR_PERCENTUAL: b.VALOR_PERCENTUAL.toLocaleString('pt-br', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    }),
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewBalancete;
