import { encode } from 'querystring';
import { baseURL } from '~/config/api';
import { downloadDocuments } from '~/utils/util';

export const associateBalance = async ({ empCodigo, empCodMatriz }) => {
  const url = `${baseURL}/api/relatorios/exportar-saldo-associados?${encode({
    c: empCodigo,
    m: empCodMatriz,
  })}`;

  // window.open(url);
  
  downloadDocuments(url, 'Saldo Associados.xls', false);
};
