import api from '~/config/api';

export const getBankAccounts = async (emp_codigo) => {
  try {
    const { data } = await api.post('/api/conta-bancaria/listar', {
      emp_codigo
    })
    return data
  } catch (error) {
    return { success: false, error }
  }
}

export const saveBankAccount = async (bankAccount) => {
  try {
    const { data } = await api.post('/api/conta-bancaria/salvar', bankAccount)
    return data
  } catch (error) {
    return { success: false, error }
  }
}

export const deleteBankAccount = async ({ cba_codigo, emp_codigo }) => {
  try {
    const { data } = await api.post('/api/conta-bancaria/excluir', { cba_codigo, emp_codigo })
    return data
  } catch (error) {
    return { success: false, error }
  }
}