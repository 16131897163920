import React, { useContext, useEffect, useRef, useState } from 'react';
import './styles.css';
import { showAlertSuccess, showAlertWarning } from '~/components/notification';
import ModalCarousel from '~/components/modal/ModalCarousel';
import { ModalOfferProps, Offer } from '~/types';
import { baseURL } from '~/config/api';
import { saveVisit } from '~/service/OfferService';
import { AuthContext } from '~/context/AuthContext';
import * as Util from '~/utils/util';
import LoadingSpin from '~/components/loading/LoadingSpin';
import { OfferSituation, OfferType } from '~/enum';
import { addJQueryListener, closeModal, jQueryChangeCSS } from '~/utils/util';

const defaultProps: ModalOfferProps = {
  offer: {} as Offer,
  isLoading: false,
  enableOffer: false,
  mustSaveVisit: false,
};

const ModalOfferDetails: React.FC<ModalOfferProps> = ({ offer, enableOffer, mustSaveVisit, isLoading, onSubmit }) => {
  const modelRef = useRef<HTMLDivElement>(null);
  const { company: empresa } = useContext(AuthContext);
  const [awaitRespose, setAwaitRespose] = useState(false);

  useEffect(() => {
    $(function () {
      addJQueryListener('detalhesOferta', 'show.bs.modal', () => jQueryChangeCSS('detalhesAssociado', 'z-index', 1039));
      addJQueryListener('detalhesOferta', 'hidden.bs.modal', () =>
        jQueryChangeCSS('detalhesAssociado', 'z-index', 1050)
      );
    });

    modelRef.current?.addEventListener('focus', overflowOnFocus);
    return () => {
      $('#detalhesOferta').off(undefined);
      modelRef.current?.removeEventListener('focus', overflowOnFocus);
    };
  }, []);

  const overflowOnFocus = () => {
    document.body.classList.add('modal-open');
  };

  useEffect(() => {
    if (mustSaveVisit && offer.OFE_CODIGO !== undefined && +offer.EMP_CODIGO !== empresa.EMP_CODIGO) {
      saveVisit({ emp_codigo: offer.EMP_CODIGO, ofe_codigo: offer.OFE_CODIGO });
    }
  }, [offer]);

  const copyShareURL = async (offerId: string) => {
    const shareURL = window.location.origin + '/compartilhar-oferta/' + offerId;
    if (await Util.copyToClipboard(shareURL))
      showAlertSuccess({ message: 'Link de compartilhamento copiado com sucesso!' });
  };

  const handleBuy = async (offer: Offer) => {
    setAwaitRespose(true);
    onSubmit && (await onSubmit(offer));
    setAwaitRespose(false);
  };

  return (
    <div
      className="modal fade"
      id="detalhesOferta"
      role="dialog"
      aria-labelledby="detalhesOfertaLabel"
      aria-hidden="true"
      tabIndex={-1}
      ref={modelRef}
    >
      <div className="modal-dialog modal-lg modal-extra-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">{offer.OFE_TITULO}</h3>
            <button
              type="button"
              className="close clickable"
              onClick={() => {
                $('#detalhesOferta').modal('hide');
              }}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body details-offer"
            style={{
              padding: 23,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                className="details-offer-main-image"
                data-toggle="modal"
                data-target="#carousel-modal"
                src={
                  offer.OFE_IMAGEM !== ''
                    ? `${baseURL}/web/uploads/oferta/imagem/${offer.OFE_IMAGEM}`
                    : `${baseURL}/web/uploads/logoLoja/${offer.EMP_LOGOTIPO}`
                }
              />
              <ModalCarousel
                imagesSRC={
                  offer.OFE_IMAGEMS && offer.OFE_IMAGEMS.length > 0
                    ? offer.OFE_IMAGEMS.map((image) => baseURL + '/web/uploads/oferta/imagem/' + image.FILENAME)
                    : [`${baseURL}/web/uploads/logoLoja/${offer.EMP_LOGOTIPO}`]
                }
              />
              <button
                type="button"
                className="btn btn-warning btn-wd btn-outline"
                data-toggle="modal"
                data-target="#carousel-modal"
              >
                Ver fotos
              </button>
              {enableOffer && (
                <>
                  {offer.OFE_STATUS === OfferSituation.LIBERADA &&
                    offer.EMP_CODIGO !== empresa.EMP_CODIGO.toString() &&
                    empresa.ITI_CODIGO != '6' && (
                      <button
                        type="button"
                        disabled={empresa.ITI_CODIGO == '6'}
                        className="btn btn-warning btn-wd"
                        onClick={() => handleBuy(offer)}
                      >
                        {awaitRespose ? (
                          <>
                            <i className="fas fa-spinner rotating" /> Processando...
                          </>
                        ) : (
                          <>
                            <i className="fa fa-gavel"></i>{' '}
                            {offer.OFE_ACOMBINAR === 'S' ? 'Solicitar orçamento' : 'Permutar'}
                          </>
                        )}
                      </button>
                    )}

                  <button
                    type="button"
                    className="btn btn-warning btn-wd btn-outline"
                    onClick={() => copyShareURL(offer.OFE_CODIGO)}
                  >
                    <i className="fa fa-share-alt icon-medium" /> Copiar link
                  </button>
                </>
              )}
            </div>
            <div className="details-offer-container-list">
              {isLoading ? (
                <div style={{ height: '300px' }}>
                  <LoadingSpin size="120px" radius="10px" />
                </div>
              ) : (
                <>
                  <div className="details-offer-list-item">
                    <p>
                      <strong>Associado: </strong> <span>{offer.EMP_NOMEFANTASIA}</span>
                    </p>
                    <p>
                      <strong>Cidade: </strong>
                      <span>{offer.LEN_CIDADE}</span>
                    </p>
                    <p>
                      <strong>SPMIX: </strong> <span>{offer.OFE_TIPO === OfferType.VPMIX ? 'Sim' : 'Não'}</span>
                    </p>
                    <p>
                      <strong>Franquia: </strong> <span>{offer.MATRIZ_NOMEFANTASIA}</span>
                    </p>
                  </div>
                  <div className="details-offer-list-item">
                    <p>
                      <strong>Promoção: </strong> <span>{offer.OFE_PROMOCAO === 'S' ? 'Sim' : 'Não'}</span>
                    </p>
                    <p>
                      <strong>Valido até: </strong> <span>{offer.OFE_PRAZORESGATE}</span>
                    </p>
                    <p>
                      <strong>Bem Ofertado: </strong> <span>{offer.BOF_DESCRICAO}</span>
                    </p>
                    <p>
                      <strong>Qtde: </strong>{' '}
                      <span>{offer.OFE_QTDOFERTADOS === '' ? <>&infin;</> : offer.OFE_QTDOFERTADOS}</span>
                    </p>
                  </div>
                  <div className="details-offer-list-item">
                    <p>
                      <strong>Valor: </strong>
                      <span>
                        {offer.OFE_VALOR === undefined
                          ? ''
                          : offer.OFE_ACOMBINAR === 'S'
                          ? 'À Combinar'
                          : Util.formatNumberToVPS(+Util.correctNumberWithComma(offer.OFE_VALOR))}
                      </span>
                    </p>
                    {offer.OFE_TIPO === OfferType.VPMIX && (
                      <>
                        <p>
                          <strong>Valor SCMIX: </strong>
                          <span>
                            {offer.OFE_VALOR === undefined
                              ? ''
                              : offer.OFE_ACOMBINAR === 'S'
                              ? 'À Combinar'
                              : Util.formatNumberToMoney(+Util.correctNumberWithComma(offer.OFE_VALORVPMIX))}
                          </span>
                        </p>
                        <p>
                          <strong>Valor Total: </strong>
                          <span>
                            {offer.OFE_VALOR === undefined
                              ? ''
                              : offer.OFE_ACOMBINAR === 'S'
                              ? 'À Combinar'
                              : Util.formatNumberWithoutPrefix(+Util.correctNumberWithComma(offer.OFE_VALORTOTAL))}
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                  <div className="details-offer-list-item">
                    <p>
                      <strong>Descrição: </strong> <span style={{ whiteSpace: 'pre-line' }}>{offer.OFE_DESCRICAO}</span>
                    </p>
                  </div>
                  <div className="details-offer-list-item">
                    <p>
                      <strong>Observação: </strong>{' '}
                      <span style={{ whiteSpace: 'pre-line' }}>{offer.OFE_OBSERVACAO}</span>
                    </p>
                  </div>
                  <div className="details-offer-list-item">
                    <p>
                      <strong>Documentos: </strong>{' '}
                      <span>
                        {
                          <a target="_blank" href={`${baseURL}/web/uploads/oferta/documento/${offer.OFE_DOCUMENTO}`}>
                            {offer.OFE_DOCUMENTO}
                          </a>
                        }
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

ModalOfferDetails.defaultProps = defaultProps;

export default ModalOfferDetails;
