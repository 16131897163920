import React, { useContext, useEffect, useState } from 'react';

import logoFiltro from '~/assets/filtro_2.png';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import { Associate } from '~/types';
import { AuthContext } from '~/context/AuthContext';

import * as Util from '~/utils/util';

import ModalFilter from './ModalFilter';

import { getAssociates } from '~/service/AssociateService';

import TableAssociates from '~/components/table/TableAssociates';
import PageLoading from '~/components/loading/PageLoading';

const VerAssociados: React.FC = () => {
  const { company: empresa } = useContext(AuthContext);

  const [associates, setAssociates] = useState([] as Array<Associate>);
  const [filteredAssociates, setFilteredAssociates] = useState([] as Array<Associate>);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [filters, setFilters] = useState({
    filterSegment: [] as Array<{ value: string }>,
    filterCity: [] as Array<{ value: string }>,
    filterFranchise: empresa.EMP_CODIGO.toString(),
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAssociates();
  }, []);

  useEffect(() => {
   
    setFilteredAssociates(associates.filter((associate) => {
      const searchBoxValueLower = searchBoxValue.toLowerCase();
      return (
        associate.EMP_RAZAOSOCIAL.toLowerCase().includes(searchBoxValueLower) ||
        associate.EMP_NOMEFANTASIA.toLowerCase().includes(searchBoxValueLower)
      )}))
  }, [searchBoxValue]);

  const fetchAssociates = async () => {
    setIsLoading(true);
    const { payload } = await getAssociates({
      seg_codigo: filters.filterSegment.length === 0 ? null : `(${filters.filterSegment.map((f) => f.value).toString()})`,
      len_cidibge: filters.filterCity.length === 0 ? null : `(${filters.filterCity.map((f) => f.value).toString()})`,
      emp_codigo: filters.filterFranchise,
    });
    const { associados } = payload;

    const associatesSorted = associados.sort((a: Associate, b: Associate) => {
      const orderByName = Util.compareStrings(a.EMP_NOMEFANTASIA, b.EMP_NOMEFANTASIA);
      const orderById = +a.EMP_CODIGO - +b.EMP_CODIGO;

      if (orderByName !== 0) return orderByName;
      else return orderById;
    });
    setAssociates(associatesSorted);
    setFilteredAssociates(associatesSorted);
    setIsLoading(false);
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Associados parceiros" searchBoxState={{ searchBoxValue, setSearchBoxValue }} />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card table-big-boy">
                  <div className="card-header ">
                    <h4 className="card-title">
                      Lista de associados parceiros{' '}
                      <img src={logoFiltro} style={{ width: 30, height: 30, cursor: 'pointer' }} data-toggle="modal" data-target="#filtro" />
                    </h4>
                    <p className="card-category">Aplique os filtros por segmentos, cidades, franquias</p>
                    <br />
                  </div>

                  <ModalFilter filtersState={{ filters: filters, setFilters }} fetchAssociates={fetchAssociates} />

                  <div className="card-body table-full-width">
                    <TableAssociates associates={filteredAssociates.filter((option, index, self) => index === self.findIndex((t) => t.EMP_CODIGO === option.EMP_CODIGO))} openAssociate isLoading={isLoading} enableOfferHiperlink/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default VerAssociados;
