import React, { useEffect, useState } from 'react';
import { Image, ImageDropzoneProps } from '~/types';
import Dropzone from 'react-dropzone';
import { baseURL } from '~/config/api';
import moment from 'moment';
import { closeModal, openModal } from '~/utils/util';
import ImageCropper from '../../input/ImageCropper/ImageCropper';

const ImageDropzone: React.FunctionComponent<ImageDropzoneProps> = ({ offer, images, setImages, removedImages, setRemovedImages }) => {
  useEffect(() => {
    return () => {
      images.forEach((image) => URL.revokeObjectURL(image.preview));
    };
  }, [offer]);

  const [imageToCrop, setImageToCrop] = useState<Image>();

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean, draggedFiles: File[]) => {
    if (images.length + draggedFiles.length > 5 && isDragActive)
      return (
        <div className="dropContainterMessageDefault" style={{ flexDirection: 'column' }}>
          <p style={{ color: 'red' }}>Você pode adicionar até 5 imagens no total</p>
          <small style={{ color: '#red' }}>&nbsp;</small>
        </div>
      );
    else if (images.length === 5 && !isDragActive)
      return (
        <div className="dropContainterMessageDefault" style={{ flexDirection: 'column' }}>
          <p>Você pode adicionar até 5 imagens</p>
          <small style={{ color: '#red' }}>&nbsp;</small>
        </div>
      );
    else if (!isDragActive)
      return (
        <>
          <div className="dropContainterMessageDefault" style={{ flexDirection: 'column' }}>
            <p>Arraste a imagem da oferta para cá...</p>
            <small style={{ color: '#9a9a9a' }}>Você pode adicionar até 5 imagens</small>
          </div>
        </>
      );
    else if (isDragReject)
      return (
        <div className="dropContainterMessageDanger" style={{ flexDirection: 'column' }}>
          <p>Arquivo não suportado...</p>
          <small style={{ color: '#9a9a9a' }}>Você pode adicionar até 5 imagens</small>
        </div>
      );
    else
      return (
        <div className="dropContainterMessageSuccess" style={{ flexDirection: 'column' }}>
          <p>Solte a imagem aqui...</p>
          <small style={{ color: '#9a9a9a' }}>Você pode adicionar até 5 imagens</small>
        </div>
      );
  };

  const onCropSuccess = (image: Image) => {
    setImages([...images, image]);
    setImageToCrop(undefined);
    closeModal('ImageCropper');
    setTimeout(() => {
      document.body.classList.add('modal-open');
    }, 500);
  };

  return (
    <>
      <div className="form-group">
        <Dropzone
          accept="image/*"
          maxSize={5242880}
          maxFiles={5}
          multiple={false}
          onDropAccepted={(file) => {
            if (images.length + file.length <= 5) {
              openModal('ImageCropper');
              setImageToCrop({
                preview: URL.createObjectURL(file[0]),
                file: file[0],
                isNew: true,
                name: moment(new Date()).format('YYYY-MM-DD-HH-mm-ss-SSS') + '.' + file[0].name.split('.').pop(),
                label: file[0].name,
              });
            }
          }}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject, draggedFiles, fileRejections }) => (
            <div
              {...getRootProps()}
              className="dropContainter"
              style={{
                borderColor: isDragReject ? '#e57878' : isDragActive && images.length === 5 ? '#e57878' : isDragActive ? '#78e5d5' : '',
              }}
            >
              <input {...getInputProps()} />
              {renderDragMessage(isDragActive, isDragReject, draggedFiles)}
            </div>
          )}
        </Dropzone>
      </div>
      <div className="drop-preview-containter">
        {images.length === 0 ? (
          <small>As imagens aparecerão aqui</small>
        ) : images.length !== 0 ? (
          images.map((image, i) => (
            <div
              key={image.preview}
              className="drop-containter-image-box"
              title={image.label}
              onClick={() => {
                const aux = [...images];
                const removedImage = aux.splice(i, 1)[0];

                setImages([...aux]);

                if (!removedImage.isNew) setRemovedImages([...removedImages, removedImage.name]);
              }}
            >
              <img src={image.preview} />
              <i className="fas fa-times" />
            </div>
          ))
        ) : (
          <div />
        )}
      </div>
      <ImageCropper image={imageToCrop} onConfirm={onCropSuccess} />
    </>
  );
};

export default ImageDropzone;
