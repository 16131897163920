import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { InputMaskControlledProps } from '~/types';
const { Group, Control } = Form;
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import SpinLoading from '~/components/loading/LoadingSpin';
import LoadingFormSpin from '~/components/loading/FormSpin';

const InputMaskControlled: React.FunctionComponent<InputMaskControlledProps> = ({
  hasError,
  label,
  isRequired,
  name,
  control,
  defaultValue,
  isMoneyMask,
  isNumberMask,
  isLoading,
  isDisabled,
  checkbox,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={`form-group form-loader ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      <label>
        {label}
        {isRequired && <span className="star" />}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        onFocus={() => {
          inputRef.current?.focus();
        }}
        render={({ onChange, value }) => (
          <NumberFormat
            {...rest}
            disabled={isDisabled}
            className="form-control"
            getInputRef={inputRef}
            defaultValue={isMoneyMask ? value * 100 : value}
            value={isMoneyMask ? value * 100 : value}
            onValueChange={(e) =>
              isMoneyMask ? onChange(e.floatValue ? e.floatValue / 100 : undefined) : isNumberMask ? onChange(e.floatValue) : onChange(e.value)
            }
          />
        )}
      />
      {isLoading && <LoadingFormSpin />}
      {checkbox}
    </div>
  );
};

export default InputMaskControlled;
