import React from 'react';
import moment from 'moment';
import { ViewFinanceiroProps } from '~/types';
import TableWithLinks from '~/components/table/TableWithLinks';
import { openModal } from '~/utils/util';
import IndicatorCard from '~/components/card/IndicatorCard';
import CardHeader from '~/components/card/CardHeader/index';
import LayoutMain from '~/layout/main';

export enum cardFinancial {
  NENHUM,
  A_PAGAR,
  A_RECEBER,
  INADIMPLENTE,
}

const ViewFinanceiro: React.FunctionComponent<ViewFinanceiroProps> = ({
  isLoading,
  cardSelected,
  onSelectCard,
  cards: { cardOverdue, cardToPay, cardToReceive },
  openModalAddFinance,
  openModalEditFinance,
  finances,
  searchState,
  ModalFinance,
  ModalFilter,
  ModalWriteOffFinance,
  financesChecked,
  onCheckFinance,
  onSort,
}) => {
  return (
    <LayoutMain isLoading={isLoading} title="Financeiro">
      <div className="row">
        <div className="col-lg-4 col-sm-6">
          <IndicatorCard
            icon="nc-icon nc-icon nc-bullet-list-67 text-danger"
            label="A pagar"
            value={cardToPay.value}
            title="Total contas a pagar"
            valueSecondary={cardToPay.valueSecondary}
            selected={cardSelected === cardFinancial.A_PAGAR}
            onClick={() => onSelectCard(cardFinancial.A_PAGAR)}
          />
        </div>

        <div className="col-lg-4 col-sm-6">
          <IndicatorCard
            icon="nc-icon nc-icon nc-bullet-list-67 text-success"
            label="A receber"
            value={cardToReceive.value}
            title="Total contas a receber"
            valueSecondary={cardToReceive.valueSecondary}
            selected={cardSelected === cardFinancial.A_RECEBER}
            onClick={() => onSelectCard(cardFinancial.A_RECEBER)}
          />
        </div>

        <div className="col-lg-4 col-sm-12">
          <IndicatorCard
            icon="nc-icon nc-icon nc-bullet-list-67 text-danger"
            label="Inadimplência"
            value={cardOverdue.value}
            title="Total inadimplência"
            valueSecondary={cardOverdue.valueSecondary}
            // valueSecondary={'R$ 100,00'}
            selected={cardSelected === cardFinancial.INADIMPLENTE}
            onClick={() => onSelectCard(cardFinancial.INADIMPLENTE)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card table-big-boy">
            <CardHeader
              title="Lista de cobranças a pagar e receber"
              subtitle="Aplique os filtros para encontrar uma cobrança"
              filterAction={() => openModal('ModalFilterFinance')}
              actions={[
                { label: 'cadastrar', iconClass: 'fa fa-plus', variant: 'btn-success', action: openModalAddFinance },
                {
                  label: 'baixar lançamentos',
                  iconClass: 'fa fa-save',
                  variant: 'btn-info',
                  action: () => openModal('ModalWriteOffFinance'),
                  disabled: financesChecked.length === 0,
                },
              ]}
            />

            {ModalFinance}
            {ModalFilter}
            {ModalWriteOffFinance}

            <div className="card-body table-full-width">
              <TableWithLinks
                tableBigBoy
                isLoading={isLoading}
                emptyTableMessage="Nenhuma cobrança encontrada. Tente alterar os filtros."
                sortState={{ column: searchState.coluna, orderBy: searchState.direcao }}
                onClickingRow={openModalEditFinance}
                columns={[
                  {
                    field: 'checker',
                    label: (
                      <input
                        type="checkbox"
                        className="clickable"
                        checked={
                          finances.filter((finance) => finance.LAN_DATARECEBIDO === '').length > 0 &&
                          finances.filter((finance) => finance.LAN_DATARECEBIDO === '').length === financesChecked.length
                        }
                        onChange={(event) =>
                          onCheckFinance(event.currentTarget.checked ? finances.filter((finance) => finance.LAN_DATARECEBIDO === '') : [])
                        }
                      />
                    ),
                    width: '40px',
                    alignment: 'text-center',
                    disableClick: true,
                  },
                  {
                    field: 'orc_codigo',
                    label: 'Orçamento',
                    alignment: 'text-center',
                    width: '130px',
                    sort: ({ column, orderBy }) => onSort({ ...searchState, coluna: column, direcao: orderBy }),
                  },
                  {
                    field: 'overdue',
                    label: 'Vencimento',
                    alignment: 'text-center',
                    width: '130px',
                    sort: ({ column, orderBy }) => onSort({ ...searchState, coluna: column, direcao: orderBy }),
                  },
                  {
                    field: 'razaoSocialModal',
                    label: 'Nome Fantasia',
                    alignment: 'text-left',
                    sort: ({ column, orderBy }) => onSort({ ...searchState, coluna: column, direcao: orderBy }),
                  },
                  { field: 'type', label: 'Tipo', alignment: 'text-left', width: '60px' },
                  {
                    field: 'received',
                    label: 'Baixado',
                    alignment: 'text-center',
                    width: '100px',
                    sort: ({ column, orderBy }) => onSort({ ...searchState, coluna: column, direcao: orderBy }),
                  },
                  {
                    field: 'quota',
                    label: 'Parcela',
                    alignment: 'text-center',
                    width: '100px',
                    sort: ({ column, orderBy }) => onSort({ ...searchState, coluna: column, direcao: orderBy }),
                  },
                  {
                    field: 'valor',
                    label: 'Valor',
                    alignment: 'text-center',
                    width: '100px',
                    sort: ({ column, orderBy }) => onSort({ ...searchState, coluna: column, direcao: orderBy }),
                  },
                ]}
                data={finances.map((finance) => ({
                  ...finance,
                  overdue: (
                    <span
                      style={
                        moment(finance.dataVencimentoModal, 'DD/MM/YYYY').valueOf() < new Date().getTime() ? { color: 'red' } : { color: 'green' }
                      }
                    >
                      {finance.dataVencimentoModal}
                    </span>
                  ),
                  type: (
                    <span style={{ fontWeight: 'bold', ...(finance.ECC_TIPO === 'R' ? { color: 'green' } : { color: 'red' }) }}>
                      {finance.ECC_TIPO === 'R' ? 'Receita' : 'Despesa'}
                    </span>
                  ),
                  received:
                    finance.LAN_DATARECEBIDO !== '' ? (
                      <span className="badge badge-green">
                        <i className="fas fa-check" /> Sim
                      </span>
                    ) : (
                      <span className="badge badge-blue">
                        <i className="fas fa-times" /> Não
                      </span>
                    ),
                  quota: (
                    <span className="badge badge-azure" style={{ fontSize: '85%' }}>
                      {finance.LAN_PARCELA}
                    </span>
                  ),
                  valor: (
                    <span className='text-left'>
                      {finance.FPA_DESCRICAO === 'Boleto' && Number(finance.valorFormatado) < 10 ? '10,00' : finance.LAN_VALOR}
                    </span>
                  ),
                  checker:
                    finance.LAN_DATARECEBIDO === '' ? (
                      <input
                        type="checkbox"
                        className="clickable"
                        checked={financesChecked.some((f) => f.LAN_CODIGO === finance.LAN_CODIGO)}
                        onChange={() => onCheckFinance(finance)}
                      />
                    ) : undefined,
                }))}
              />

              {searchState.pagina === 0 ? (
                <ul className="pagination pagination-blue pull-right" style={{ marginRight: '25px' }}>
                  <li className="page-item clickable">
                    <a className="page-link">&laquo;</a>
                  </li>
                  <li className={'page-item active'}>
                    <a className="page-link clickable">1</a>
                  </li>
                  <li className={'page-item'}>
                    <a className="page-link clickable" onClick={() => onSort({ ...searchState, pagina: 1 })}>
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link clickable" onClick={() => onSort({ ...searchState, pagina: 2 })}>
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link clickable" onClick={() => onSort({ ...searchState, pagina: 1 })}>
                      &raquo;
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="pagination pagination-blue pull-right" style={{ marginRight: '25px' }}>
                  <li className="page-item clickable">
                    <a className="page-link" onClick={() => onSort({ ...searchState, pagina: searchState.pagina - 1 })}>
                      &laquo;
                    </a>
                  </li>
                  <li className={'page-item'}>
                    <a className="page-link clickable" onClick={() => onSort({ ...searchState, pagina: searchState.pagina - 1 })}>
                      {searchState.pagina}
                    </a>
                  </li>
                  <li className={'page-item active'}>
                    <a className="page-link clickable">{searchState.pagina + 1}</a>
                  </li>
                  <li className="page-item">
                    <a className="page-link clickable" onClick={() => onSort({ ...searchState, pagina: searchState.pagina + 1 })}>
                      {searchState.pagina + 2}
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link clickable" onClick={() => onSort({ ...searchState, pagina: searchState.pagina + 1 })}>
                      &raquo;
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewFinanceiro;
