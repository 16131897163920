import React, { useContext, useEffect, useState } from 'react';

import Footer from '~/components/footer';
import Header from '~/components/header';
import Menu from '~/components/menu';

import moment from 'moment';
import logoFiltro from '~/assets/filtro_2.png';
import IndicatorCard from '~/components/card/IndicatorCard';
import PageLoading from '~/components/loading/PageLoading';
import ModalOfferDetails from '~/components/modal/ModalOfferDetails';
import { showAlertInfo } from '~/components/notification';
import { baseURL } from '~/config/api';
import { AuthContext } from '~/context/AuthContext';
import { getDashboardTotalizadoresAssociado } from '~/service/DashboardService';
import { getOfferDetails } from '~/service/OfferService';
import { getMyAccountStatement } from '~/service/associado/AccountStatementService';
import { printVoucher } from '~/service/associado/VoucherService';
import { Offer, Transaction } from '~/types';
import {
  correctNumberWithComma,
  downloadDocuments,
  formatAndCompareDate,
  formatNumberWithoutPrefix,
} from '~/utils/util';
import ModalFilter from './ModalFilter';

const ExtratoConta: React.FunctionComponent = () => {
  const { company: empresa, user: usuario, balance, changeBalance } = useContext(AuthContext);

  const [transactions, setTransactions] = useState([] as Transaction[]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModal, setisLoadingModal] = useState(false);
  const [offer, setOffer] = useState({} as Offer);
  const [cardLimit, setCardLimit] = useState('');
  const [cardCredit, setCardCredit] = useState('');
  const [cardBuys, setCardBuys] = useState('');
  const [cardSells, setCardSells] = useState('');

  const [filters, setFilters] = useState({
    startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().clone().endOf('month').format('YYYY-MM-DD'),
    disableFilterDate: false,
  });

  useEffect(() => {
    fetchMyAccountStatement();
    getDashboardTotalizadoresAssociado({ emp_codigo: empresa.EMP_CODIGO }).then(({ payload }) => {
      if (!payload) {
        showAlertInfo({ message: 'Erro ao carregar o saldo da sua conta. Por favor, tente novamente.' });
        return;
      }

      const { limiteAprovado, valorCompra, valorVenda, alertaNegocio } = payload;

      const regulator = +alertaNegocio;
      const limit = +limiteAprovado;
      const buys = +valorCompra;
      const sells = +valorVenda;

      //The value of "buys" is received as negative from the backend
      const credit = limit + (buys + sells - regulator);
      const balance = buys + sells - regulator;

      //The value of "buys" is received as negative from the backend
      changeBalance(balance);

      setCardLimit(formatNumberWithoutPrefix(limit));
      setCardCredit(formatNumberWithoutPrefix(credit));
      setCardBuys(formatNumberWithoutPrefix(buys * -1));
      setCardSells(formatNumberWithoutPrefix(sells));
    });
  }, []);

  const fetchMyAccountStatement = async () => {
    setIsLoading(true);
    const { payload } = await getMyAccountStatement({
      emp_codigo: empresa.EMP_CODIGO,
      emp_tipo: empresa.EMP_TIPO,
      inicio: moment(filters.startDate).format('DD/MM/YYYY'),
      fim: moment(filters.endDate).format('DD/MM/YYYY'),
      filtro_data: filters.disableFilterDate ? 'S' : 'N',
    });

    setTransactions(
      payload.extrato.sort((a: Transaction, b: Transaction) => {
        const perDate = formatAndCompareDate(b.TRA_DATA, a.TRA_DATA);
        return perDate !== 0 ? perDate : +b.ORC_CODIGO - +a.ORC_CODIGO;
      })
    );

    setIsLoading(false);
  };

  const fetchOfferDetails = async (offerId: string) => {
    setisLoadingModal(true);
    setOffer({} as Offer);
    const { payload } = await getOfferDetails(offerId);
    setOffer(payload.oferta);

    setisLoadingModal(false);
  };

  const exportToXLS = () => {
    if (!balance) {
      showAlertInfo({ message: 'Por favor, aguarde alguns instantes. Ainda estamos carregando o seu saldo.' });
    } else {
      const inicio = moment(filters.startDate).format('DD-MM-YYYY');
      const fim = moment(filters.endDate).format('DD-MM-YYYY');
      const filtro_data = filters.disableFilterDate ? 'S' : 'N';

      /* const limite_aprovado = cardLimit.replace(/\./g, '').replace(',', '_');
      const credito_disponivel = cardCredit.replace(/\./g, '').replace(',', '_');
      const total_compra = cardBuys.replace(/\./g, '').replace(',', '_');
      const total_venda = cardSells.replace(/\./g, '').replace(',', '_');

      const total_regulador = 0;
      const saldo_atual = balance.toFixed(2).replace('.', '_'); */

      //const url = `${baseURL}/api/extratos-conta/exportar/${empresa.EMP_CODIGO}/${inicio}/${fim}/${filtro_data}/${limite_aprovado}/${total_compra}/${total_venda}/${saldo_atual}/${credito_disponivel}/${empresa.EMP_TIPO}`;

      const url = `${baseURL}/api/extratos-conta/exportar?emp_codigo=${empresa.EMP_CODIGO}&inicio=${inicio}&fim=${fim}&filtro_data=${filtro_data}`;

      downloadDocuments(url, 'ExtratoConta.xls', false);
    }
  };

  const exportToPDF = () => {
    const inicio = moment(filters.startDate).format('DD-MM-YYYY');
    const fim = moment(filters.endDate).format('DD-MM-YYYY');
    const filtro_data = filters.disableFilterDate ? 'S' : 'N';

    const url = `${baseURL}/api/extratos-conta/exportar-pdf?emp_codigo=${empresa.EMP_CODIGO}&inicio=${inicio}&fim=${fim}&filtro_data=${filtro_data}`;

    downloadDocuments(url, 'ExtratoConta.pdf', false);
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Extrato Conta" showBalance />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard icon="nc-icon nc-notes" label="Limite SC$" value={cardLimit} title="Limite Aprovado" />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  icon="nc-icon nc-notes text-warning"
                  label="Disponível SC$"
                  value={cardCredit}
                  title="Crédito disponível"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  icon="nc-icon nc-notes text-danger"
                  label="Compras SC$"
                  value={cardBuys}
                  title="Total de compras"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  icon="nc-icon nc-notes text-success"
                  label="Vendas SC$"
                  value={cardSells}
                  title="Total de Vendas"
                />
              </div>
            </div>

            <ModalFilter filtersState={{ filters, setFilters }} fetchMyAccountStatement={fetchMyAccountStatement} />
            <ModalOfferDetails offer={offer} />

            <div className="row">
              <div className="col-md-12">
                <div className="card table-with-links">
                  <div className="card-header " style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <h4 className="card-title flex-in-row">
                        Extrato da Conta &nbsp;
                        <img
                          src={logoFiltro}
                          style={{ width: 30, height: 30, cursor: 'pointer' }}
                          data-toggle="modal"
                          data-target="#filtro"
                          title="Filtrar dados"
                        />
                        &nbsp;
                        <i
                          className="far fa-file-excel"
                          style={{ cursor: 'pointer' }}
                          title="Exportar para Excel"
                          onClick={exportToXLS}
                        />
                        &nbsp;
                        <i
                          className="far fa-file-pdf"
                          style={{ cursor: 'pointer' }}
                          title="Exportar para PDF"
                          onClick={exportToPDF}
                        />
                      </h4>
                      <div style={{ flexDirection: 'column', marginTop: 10 }}>
                        <span style={{ fontSize: 14 }}>
                          <i className="fa fa-search icon-caption" style={{ color: '#fba534' }} /> ver detalhes
                        </span>
                        <span style={{ fontSize: 14, marginLeft: 15 }}>
                          <i className="fa fa-print icon-caption" /> imprimir voucher
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <colgroup>
                          <col />
                          <col />
                          <col width="100px" />
                          <col width="110px" />
                          <col width="110px" />
                          <col width="110px" />
                          <col width="55px" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-left">Associado Permutante</th>
                            <th className="text-left">Oferta</th>
                            <th className="text-center">Transação</th>
                            <th className="text-center">Data</th>
                            <th className="text-center">Valor Compra</th>
                            <th className="text-center">Valor Venda</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <tr>
                              <td colSpan={8}>{/* <LoadingPointsSpin /> */}</td>
                            </tr>
                          ) : transactions.length === 0 ? (
                            <tr>
                              <td colSpan={8}>
                                <p className="text-color-default">
                                  Nenhuma transação encontrada. Tente alterar os filtros.
                                </p>
                              </td>
                            </tr>
                          ) : (
                            transactions.map((transaction, k) => {
                              const value = +correctNumberWithComma(transaction.TRA_VALOR);
                              return (
                                <tr key={transaction.TRA_CODIGO + `_${k}`}>
                                  <td className="text-left">
                                    {transaction.TRA_TIPO > 'Compra'
                                      ? transaction.SOLICITANTE
                                      : transaction.DESTINATARIO}
                                  </td>
                                  <td className="text-left">{transaction.OFE_TITULO}</td>
                                  <td className="text-center">{transaction.TRA_CODIGO}</td>
                                  <td className="text-center">{transaction.TRA_DATA.split(' ')[0]}</td>
                                  <td className="text-center">
                                    {value < 0 ? (
                                      <span style={{ color: transaction.TRA_STATUS === 'P' ? 'red' : '' }}>
                                        {formatNumberWithoutPrefix(value * -1)}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {value > 0 ? (
                                      <span style={{ color: transaction.TRA_STATUS === 'P' ? 'red' : '' }}>
                                        {formatNumberWithoutPrefix(value)}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      {transaction.OFE_CODIGO !== '' && (
                                        <>
                                          <i
                                            className="fa fa-search icon-medium clickable"
                                            style={{ color: '#fba534' }}
                                            data-toggle="modal"
                                            data-target="#detalhesOferta"
                                            title="ver detalhes"
                                            onClick={() => fetchOfferDetails(transaction.OFE_CODIGO)}
                                          />
                                          <i
                                            className="fa fa-print icon-medium clickable"
                                            title="imprimir voucher"
                                            onClick={() =>
                                              printVoucher({
                                                orc_codigo: transaction.ORC_CODIGO,
                                                ofe_codigo: transaction.OFE_CODIGO,
                                              })
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ExtratoConta;
