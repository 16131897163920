import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { getFilterData } from '~/service/OfferService';
import {
  CidadeInterface,
  FormAssociatesTransferAssociateSearchParams,
  FranquiaInterface,
  ModalFilterAssociatesTransferAssociate,
  ModalFilterOffers,
  OptionProps,
  SegmentoInterface,
} from '~/types';
import { AuthContext } from '~/context/AuthContext';
import { useErrorHandler } from 'react-error-boundary';
import { closeModal } from '~/utils/util';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import SelectControlled from '~/components/input/SelectControlled';

const ModalFiltro: React.FC<ModalFilterAssociatesTransferAssociate> = ({ isLoading, franchises, onSubmit }) => {
  const FormAssociatesTransferAssociateSchema: yup.SchemaOf<FormAssociatesTransferAssociateSearchParams> = yup
    .object()
    .shape({
      franchiseId: yup.number().required(),
    });

  const { control, register, handleSubmit, errors, reset } = useForm<FormAssociatesTransferAssociateSearchParams>({
    resolver: yupResolver(FormAssociatesTransferAssociateSchema),
  });

  const handleOnSubmit = async (params: FormAssociatesTransferAssociateSearchParams) => {
    onSubmit(params);
    closeModal('ModalFilter');
  };

  return (
    <div
      className="modal fade"
      id="ModalFilter"
      role="dialog"
      aria-labelledby="filtroLabel"
      aria-hidden="true"
      tabIndex={-1}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Filtrar associados...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form id="formFilter" onSubmit={handleSubmit(handleOnSubmit, console.log)}>
                      <div className="form-group">
                        <SelectControlled
                          isRequired
                          label="Franquias"
                          name="franchiseId"
                          control={control}
                          defaultValue={undefined}
                          hasError={!!errors.franchiseId}
                          placeholder="Escolha..."
                          options={franchises.map((franchise) => ({
                            value: franchise.EMP_CODIGO,
                            label: franchise.EMP_NOMEFANTASIA,
                          }))}
                          isLoading={isLoading}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" form="formFilter">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFiltro;
