import React from 'react';
import { BasicInputProps, BasicTextareaProps } from '~/types';
import LoadingFormSpin from '~/components/loading/FormSpin';

const BasicTextarea = React.forwardRef<HTMLTextAreaElement, BasicTextareaProps>(({ label, hasError, isRequired, height, ...rest }, ref) => {
  return (
    <div className={`form-group form-loader ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      <label>
        {label}
        {isRequired && <span className="star" />}
      </label>
      <textarea {...rest} style={{ height: height, ...rest.style }} ref={ref} className={'form-control'} />
    </div>
  );
});

export default BasicTextarea;
