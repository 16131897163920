import React, { useContext } from 'react';

import Select from 'react-select';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import IndicatorCard from '~/components/card/IndicatorCard';
import TableWithLinks from '~/components/table/TableWithLinks';
import { AuthContext } from '~/context/AuthContext';
import LayoutMain from '~/layout/main';
import { ViewRaioXAssociadoProps } from '~/types';
import { abbreviateNumber, correctNumberWithComma, formatNumberToVPS, formatNumberWithoutPrefix } from '~/utils/util';

const ViewRaioXAssociado: React.FunctionComponent<ViewRaioXAssociadoProps> = ({
  isLoading,
  cards: { cardBalance, cardLimit, cardAverageTicket, cardCredit, qtdBuy, qtdSell },
  fetchDashboardDataRaioXAssociado,
  associate,
  associates,
  associateCodChosenState: { associateCodChosen, setAssociateCodChosen },
  maioresTransacoes,
  ultimasTransacoes,
  graficoTransacoes,
  headerAppend,
}) => {
  const { company } = useContext(AuthContext);

  return (
    <LayoutMain isLoading={isLoading} title="Raio X Associado" headerAppend={headerAppend}>
      <div className="row">
        <div className="col">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              associateCodChosen && fetchDashboardDataRaioXAssociado(associateCodChosen.value);
            }}
          >
            <div className="form-group form-header-filter">
              <span className="text-color-default">Associado: &nbsp;</span>
              <Select
                styles={{
                  control: (provided, _) => ({
                    ...provided,
                    width: 400,
                  }),
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                options={associates
                  .map((associate) => ({ label: associate.EMP_RAZAOSOCIAL, value: associate.EMP_CODIGO }))
                  .filter((option, index, self) => index === self.findIndex((t) => t.value === option.value))}
                placeholder="Escolha um associado"
                noOptionsMessage={() => ''}
                value={associateCodChosen}
                onChange={(associateCod) => associateCod && setAssociateCodChosen(associateCod)}
              />
              <button type="submit" className="btn btn-warning  btn-outline">
                <i className="fas fa-filter" /> Filtrar
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <IndicatorCard
                icon="nc-icon nc-money-coins text-success"
                label="Saldo SC$"
                value={cardBalance}
                title="Saldo do associado em SC$"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <IndicatorCard
                icon="nc-icon nc-money-coins text-danger"
                label="Limite Crédito SC$"
                value={cardLimit}
                title="Limite de crédito do associado em SC$"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <IndicatorCard
                icon="nc-icon nc-money-coins text-danger"
                label="Ticket Médio SC$"
                value={cardAverageTicket}
                title="Ticket médio do associado em SC$"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <IndicatorCard
                icon="nc-icon nc-money-coins text-danger"
                label="Disponível SC$"
                value={cardCredit}
                title="Valor disponível para o associado"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Visão Geral</h4>
                  {/* <br /> */}
                  <p className="card-category">
                    Associado desde <strong>{associate?.EMP_DATACADASTRO}</strong>
                  </p>
                  <p className="card-category">
                    Segmento: <strong>{associate?.SEG_DESCRICAO}</strong>
                  </p>
                  <p className="card-category">Total de compra e venda do associado selecionado</p>
                </div>
                <div className="card-body">
                  <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ResponsiveContainer height={230} width={'100%'} className="graph-label">
                      <BarChart data={graficoTransacoes}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Legend formatter={(name) => (name === 'buy' ? 'Compra' : name === 'sell' ? 'Venda' : '')} />
                        <Tooltip
                          formatter={(value, name) => [
                            formatNumberToVPS(+value),
                            name === 'buy' ? 'Compra' : name === 'sell' ? 'Venda' : '',
                          ]}
                          cursor={false}
                        />
                        <Bar dataKey="buy" fill="#0088FE">
                          <LabelList
                            dataKey="buy"
                            position="top"
                            offset={5}
                            angle={0}
                            formatter={(e) => abbreviateNumber(+e, 0)}
                          />
                        </Bar>
                        <Bar dataKey="sell" fill="#00C49F">
                          <LabelList
                            dataKey="sell"
                            position="top"
                            offset={5}
                            angle={0}
                            formatter={(e) => abbreviateNumber(+e, 0)}
                          />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <IndicatorCard
                icon="nc-icon nc-money-coins text-success"
                label="Compras"
                value={qtdBuy}
                title="Quantidade de compras"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <IndicatorCard
                icon="nc-icon nc-money-coins text-danger"
                label="Vendas"
                value={qtdSell}
                title="Quantidade de vendas"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Perfil de Compras</h4>
                  <p className="card-category">Maiores transações do associado</p>
                </div>
                <div className="card-body">
                  <TableWithLinks
                    emptyTableMessage="Este associado ainda não tem transações"
                    columns={[
                      { field: 'TRA_DATA_FORMAT', label: 'Data' },
                      { field: 'associate', label: 'Associado' },
                      { field: 'valueFormatted', label: 'Valor' },
                      { field: 'OFE_TITULO', label: 'Titulo da oferta' },
                    ]}
                    data={maioresTransacoes.map((transacao) => ({
                      ...transacao,
                      associate: [
                        transacao.SOLICITANTE,
                        <span style={{ color: +transacao.CODFRANQUIA == company.EMP_CODIGO ? 'blue' : 'red' }}>
                          {transacao.FRANQUIA}
                        </span>,
                      ],
                      valueFormatted: (
                        <span style={{ color: +correctNumberWithComma(transacao.TRA_VALOR) > 0 ? 'blue' : 'red' }}>
                          {formatNumberWithoutPrefix(correctNumberWithComma(transacao.TRA_VALOR))}
                        </span>
                      ),
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header ">
              <h4 className="card-title">Últimas Transações</h4>
              <p className="card-category">Últimas Transações do associado selecionado</p>
            </div>
            <div className="card-body">
              <TableWithLinks
                emptyTableMessage="Este associado ainda não tem transações"
                columns={[
                  { field: 'TRA_DATA_FORMAT', label: 'Data' },
                  { field: 'associate', label: '' },
                  { field: 'valueFormatted', label: 'Valor' },
                  { field: 'OFE_TITULO', label: 'Título da oferta' },
                ]}
                data={ultimasTransacoes.map((transacao) => ({
                  ...transacao,
                  associate: [
                    transacao.SOLICITANTE,
                    <span style={{ color: +transacao.CODFRANQUIA == company.EMP_CODIGO ? 'blue' : 'red' }}>
                      {transacao.FRANQUIA}
                    </span>,
                  ],
                  valueFormatted: (
                    <span style={{ color: +correctNumberWithComma(transacao.TRA_VALOR) > 0 ? 'blue' : 'red' }}>
                      {formatNumberWithoutPrefix(correctNumberWithComma(transacao.TRA_VALOR))}
                    </span>
                  ),
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewRaioXAssociado;
