import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { OfferSituation } from '~/enum';
import { GoodOffered, ModalFilterMyOffers, OptionProps } from '~/types';

const ModalFilter: React.FunctionComponent<ModalFilterMyOffers> = ({ config, isLoading, filtersState, fetchMyOffers }) => {
  const [bensOfertados, setBensOfertados] = useState([] as OptionProps[]);

  const status = [
    {
      value: OfferSituation.ABERTA,
      label: 'Aberta',
    },
    {
      value: OfferSituation.BLOQUEADA,
      label: 'Bloqueada',
    },
    {
      value: OfferSituation.LIBERADA,
      label: 'Liberada',
    },
    {
      value: OfferSituation.PENDENTE,
      label: 'Pendente',
    },
    {
      value: OfferSituation.ENCERRADA,
      label: 'Encerrada',
    },
    {
      value: OfferSituation.REENVIADA,
      label: 'Reenviada',
    },
  ];

  useEffect(() => {
    if (!isLoading) {
      setBensOfertados(config.segmentos.map((segmento: GoodOffered) => ({ value: segmento.BOF_CODIGO, label: segmento.BOF_DESCRICAO })));
    }
  }, [isLoading, config]);

  return (
    <div className="modal fade" id="filtro" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Buscar ofertas...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form id="formFilter">
                      <div className="form-group">
                        <label>Bens Ofertados</label>
                        <div className="col-md-12">
                          <Select
                            isLoading={isLoading}
                            closeMenuOnSelect={false}
                            placeholder="Todos"
                            isMulti
                            isSearchable
                            options={bensOfertados}
                            onChange={(e) => {
                              filtersState.setFilters({ ...filtersState.filters, filterGoodsOffered: e });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Situação</label>
                        <div className="col-md-12">
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Todas"
                            isMulti
                            isSearchable
                            options={status}
                            onChange={(e) => {
                              filtersState.setFilters({ ...filtersState.filters, filterSituation: e });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Valor</label>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={filtersState.filters.filterToCombine}
                              onChange={(e) => filtersState.setFilters({ ...filtersState.filters, filterToCombine: !!e.target.checked })}
                            />
                            <span className="form-check-sign"></span>
                            A combinar
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={filtersState.filters.filterWithValue}
                              onChange={(e) => filtersState.setFilters({ ...filtersState.filters, filterWithValue: !!e.target.checked })}
                            />
                            <span className="form-check-sign"></span>
                            Com Valor
                          </label>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Tipos de Ofertas</label>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="PADRAO"
                              defaultChecked
                              onChange={(e) => filtersState.setFilters({ ...filtersState.filters, filterVP: e.target.checked ? e.target.value : '' })}
                            />
                            <span className="form-check-sign"></span>
                            VP
                          </label>
                        </div>

                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="VPMIX"
                              defaultChecked
                              onChange={(e) =>
                                filtersState.setFilters({ ...filtersState.filters, filterVPMIX: e.target.checked ? e.target.value : '' })
                              }
                            />
                            <span className="form-check-sign"></span>
                            SCMIX
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" data-dismiss="modal" onClick={fetchMyOffers} form="formFilter">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilter;
