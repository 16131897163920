import React, { FunctionComponent } from 'react';
import Select, { components } from 'react-select';
import { Empresa } from '~/context/AuthContext';

type SelectedCompany = { value: number; label: string; labelAux: string };
interface SelectCompanyProps {
  selectedCompany: SelectedCompany;
  setSelectedCompany: (selectedCompany: SelectedCompany) => void;
  franquias: Empresa[];
}

const SelectCompany: FunctionComponent<SelectCompanyProps> = ({ selectedCompany, setSelectedCompany, franquias }) => {
  return (
    <Select
      styles={{
        container: (props) => ({ ...props, width: '250px' }),
        control: (style, props) => ({
          ...style,
          border: 'var(--color-default) 1px solid',
          borderColor: 'var(--color-default) !important',
          boxShadow: props.isFocused ? '0 0 0 1px orange' : undefined,
        }),
      }}
      value={selectedCompany}
      options={
        franquias.map((company) => ({
          value: company.EMP_CODIGO,
          label: company.EMP_NOMEFANTASIA,
          labelAux: company.EMP_RAZAOSOCIAL,
        })) || []
      }
      onChange={(selected) => selected && setSelectedCompany(selected)}
      getOptionLabel={(option) => option.label}
      components={{
        Option: (props) => (
          <components.Option {...props}>
            <div className="d-flex flex-column text-dark">
              <span>{props.label}</span>
              <small>{props.data.labelAux}</small>
            </div>
          </components.Option>
        ),
      }}
    />
  );
};

export default SelectCompany;
