import React, { CSSProperties, useRef } from 'react';
import { ParametersFranchiseAccounts, SelectControlledOption } from '~/types';
import { Controller, Control } from 'react-hook-form';
import Select from 'react-select';

interface GroupOption extends ParametersFranchiseAccounts {
  label: string;
  options: (SelectControlledOption & ParametersFranchiseAccounts)[];
}

interface SelectGroupProps {
  options: (GroupOption & ParametersFranchiseAccounts)[];
  control: Control<Record<string, any>>;
  name: string;
  defaultValue?: string;
  hasError: boolean;
}

const groupStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles: CSSProperties = {
  color: '#172B4D',
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const mountOptions: (
  group: ParametersFranchiseAccounts[],
  options: ParametersFranchiseAccounts[]
) => (GroupOption & ParametersFranchiseAccounts)[] = (group, options) => {
  return group.map((group) => ({
    ...group,
    label: `(${group.ECC_TIPO}) ${group.ECC_DESCRICAO}`,
    options: options
      .filter((parameterAccount) => group.ECC_NUMARVORE === parameterAccount.ECC_SUPERIOR && parameterAccount.ECC_DESATIVADO === 'N')
      .map((parameterAccount) => ({
        ...parameterAccount,
        value: parameterAccount.ECC_CODIGO,
        label: `(${parameterAccount.ECC_TIPO}) ${parameterAccount.ECC_DESCRICAO}`,
      })),
  }));
};

const SelectGroup: React.FunctionComponent<SelectGroupProps> = ({ control, options, name, defaultValue, hasError }) => {
  const selectRef = useRef<HTMLDivElement | any>(null);

  const formatGroupLabel = (groupOptionAux: any) => {
    const groupOption = groupOptionAux as GroupOption & ParametersFranchiseAccounts;
    return (
      <div style={groupStyles}>
        <span>{groupOption.label}</span>
        <span style={groupBadgeStyles}>{groupOption.options.length}</span>
      </div>
    );
  };

  const formatOptionLabel = (option: SelectControlledOption & ParametersFranchiseAccounts) => (
    <div>
      <span style={{ paddingLeft: 10 }}>{option.label}</span>
    </div>
  );

  return (
    <div className={`form-group ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        onFocus={() => {
          selectRef.current.focus();
        }}
        render={({ onChange, value }) => (
          <Select
            ref={selectRef}
            openMenuOnFocus
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Selecione"
            isSearchable
            options={options}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            value={
              options
                .reduce((acc, option) => [...acc, ...option.options], [] as (SelectControlledOption & ParametersFranchiseAccounts)[])
                .find((option) => option.value === value) || undefined
            }
            onChange={(optionSelected) => {
              onChange(optionSelected?.value);
            }}
          />
        )}
      />
    </div>
  );
};

export default SelectGroup;
