import React, { ChangeEvent, ChangeEventHandler } from 'react';
import { Controller } from 'react-hook-form';
import { CheckBoxControlledProps } from '~/types';
import { Form } from 'react-bootstrap';
const { Group, Check } = Form;

const CheckBoxControlled: React.FunctionComponent<CheckBoxControlledProps> = ({
  label,
  name,
  control,
  defaultValue,
  isDisabled,
  onChangeAux,
  ...rest
}) => {
  const e: ChangeEventHandler = (e) => {};
  return (
    <div className={`form-group`}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => (
          <div className="form-check">
            <label className="form-check-label" style={{ textTransform: 'none' }}>
              <input
                {...rest}
                disabled={isDisabled}
                className="form-check-input"
                type="checkbox"
                checked={value}
                onChange={(e: any) => {
                  onChange(e.target.checked);
                  onChangeAux && onChangeAux(e.target.checked);
                }}
              />
              <span className="form-check-sign" />
              {label}
            </label>
          </div>
        )}
      />
    </div>
  );
};

export default CheckBoxControlled;
