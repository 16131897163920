import React, { useContext, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormUser, ModalUserProps } from '~/types';
import { AuthContext } from '~/context/AuthContext';
import BasicInput from '~/components/input/BasicInput';
import MaskCPFControlled from '~/components/input/MaskCPFControlled';
import SelectControlled from '~/components/input/SelectControlled';
import MaskPhoneControlled from '~/components/input/MaskPhoneControlled';
import moment from 'moment';
import { saveUser } from '~/service/UserService';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import { closeModal } from '~/utils/util';

const status = [
  {
    value: '1',
    label: 'Ativo',
  },
  {
    value: '2',
    label: 'Bloqueado',
  },
  {
    value: '3',
    label: 'Inativo',
  },
];

const FormUserSchema: yup.SchemaOf<FormUser> = yup.object().shape({
  usu_cpf: yup.string(),
  usu_nome: yup.string().required().required(),
  usu_tratamento: yup.string(),
  usu_sexo: yup.string(),
  iti_codigo: yup.number().required(),
  per_codigo: yup.number().required(),
  usu_email: yup.string().required(),
  usu_telefone: yup.string(),
  usu_login: yup.string().required(),
  usu_dataaniver: yup.string(),
});

const ViewModalUser: React.FunctionComponent<ModalUserProps> = ({ user, profiles, handleOnSubmit, isAwaitResponse }) => {
  const { user: usuario, company: empresa } = useContext(AuthContext);


  const { watch, control, handleSubmit, errors } = useForm<FormUser>({
    resolver: yupResolver(FormUserSchema),
  });

  return (
    <div className="modal fade" id="ModalUSer" role="dialog" aria-labelledby="usuarioLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Usuário</h4>
                  </div>
                  <div className="card-body ">
                    <form key={user?.USU_CODIGO + 'ModalUSerForm'} onSubmit={handleSubmit(handleOnSubmit)} id="ModalUSerFormId">
                      <BasicInput
                        isRequired
                        control={control}
                        type="text"
                        defaultValue={user?.USU_NOME || ''}
                        hasError={!!errors.usu_nome}
                        name="usu_nome"
                        label="Nome"
                        placeholder="Nome do usuário"
                      />

                      <MaskCPFControlled
                        control={control}
                        defaultValue={user?.USU_CPF || ''}
                        hasError={!!errors.usu_cpf}
                        name="usu_cpf"
                        label="CPF"
                      />

                      <BasicInput
                        control={control}
                        type="text"
                        defaultValue={user?.USU_TRATAMENTO || ''}
                        hasError={!!errors.usu_tratamento}
                        name="usu_tratamento"
                        label="Tratamento"
                        placeholder="Apelido ou Tratamento"
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={user?.USU_SEXO || ''}
                        options={[
                          { value: 'M', label: 'Masculino' },
                          { value: 'F', label: 'Feminino' },
                        ]}
                        hasError={!!errors.usu_sexo}
                        name="usu_sexo"
                        label="Sexo"
                      />

                      <SelectControlled
                        isRequired
                        control={control}
                        defaultValue={user?.ITI_CODIGO || '1'}
                        options={status}
                        hasError={!!errors.iti_codigo}
                        name="iti_codigo"
                        label="Situação"
                      />

                      <SelectControlled
                        isRequired
                        control={control}
                        defaultValue={user?.PER_CODIGO || '1'}
                        options={profiles.map((profile) => ({ value: profile.PER_CODIGO, label: profile.PER_DESCRICAO }))}
                        hasError={!!errors.per_codigo}
                        name="per_codigo"
                        label="Perfil"
                      />

                      <BasicInput
                        isRequired
                        control={control}
                        type="email"
                        defaultValue={user?.USU_EMAIL || ''}
                        hasError={!!errors.usu_email}
                        name="usu_email"
                        label="Email"
                        placeholder="Email"
                      />

                      <MaskPhoneControlled
                        control={control}
                        defaultValue={user?.USU_TELEFONE || ''}
                        hasError={!!errors.usu_telefone}
                        name="usu_telefone"
                        label="Telefone"
                      />

                      <BasicInput
                        isRequired
                        control={control}
                        type="text"
                        defaultValue={user?.USU_LOGIN || ''}
                        hasError={!!errors.usu_login}
                        name="usu_login"
                        label="Login"
                        placeholder="Login no sistema"
                      />

                      <BasicInput
                        control={control}
                        type="date"
                        defaultValue={user?.USU_DATAANIVER?.split(' ')[0].split('/').reverse().join('-') || undefined}
                        hasError={!!errors.usu_dataaniver}
                        name="usu_dataaniver"
                        label="Aniversário"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-success btn-wd" form="ModalUSerFormId" type="submit" disabled={isAwaitResponse}>
              {isAwaitResponse ? (
                <>
                  <i className="fas fa-spinner rotating" /> Processando...
                </>
              ) : (
                <>
                  <i className="fa fa-save" /> Salvar
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModalUser;
