import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { ModalBaixarProps } from '~/types';
import { VoucherStatus } from '~/enum';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { baixarVoucher } from '~/service/associado/VoucherService';
import Mensagem from '~/components/mensagens';
import { showAlertSuccess } from '~/components/notification';
import { closeModal } from '~/utils/util';

interface FormInput {
  vou_nomeutilizador: string;
  vou_rgutilizador: string;
  vou_obsutilizador: string;
}

const validationSchema = yup.object().shape({
  vou_nomeutilizador: yup.string().required(),
  vou_rgutilizador: yup.string().required(),
  vou_obsutilizador: yup.string(),
});

const ModalBaixar: React.FunctionComponent<ModalBaixarProps> = ({ voucher, fetchMyVouchers }) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowError, setIsShowError] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: FormInput): Promise<void> => {
    if (Object.keys(errors).length > 0 && formRef.current?.reportValidity()) {
    } else {
      setIsAwaitResponse(true);
      const { success, payload, message, type } = await baixarVoucher({ ...values, vou_codigo: voucher.VOU_CODIGO });
      setIsAwaitResponse(false);

      if (success == true) {
        showAlertSuccess({ message: 'Sucesso ao baixar voucher!' });
        closeModal('baixar')
        reset()
        fetchMyVouchers();
      } else {
        setErrorMessage(message);
      }
    }
  };

  const setErrorMessage = (message: string) => {
    setMessage(message);
    setIsShowError(true);
  };

  return (
    <div className="modal fade" id="baixar" role="dialog" aria-labelledby="baixarLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Resposta...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    {(Object.keys(errors).length > 0 || isShowError) && <Mensagem color="danger" title="Atenção!" description={message} />}
                    <form key={voucher.VOU_CODIGO} id="genericFormId" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label>
                          Nome<span className="star"></span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="vou_nomeutilizador"
                          ref={register}
                          defaultValue={voucher.VOU_NOMEUTILIZADOR}
                          disabled={voucher.VOU_STATUS === VoucherStatus.BAIXADO}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Identidade<span className="star"></span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="vou_rgutilizador"
                          ref={register}
                          defaultValue={voucher.VOU_RGUTILIZADOR}
                          disabled={voucher.VOU_STATUS === VoucherStatus.BAIXADO}
                        />
                      </div>
                      <div className="form-group">
                        <label>Observação</label>
                        <textarea
                          style={{ height: 80 }}
                          placeholder=""
                          className="form-control"
                          name="vou_obsutilizador"
                          ref={register}
                          defaultValue={voucher.VOU_OBSUTILIZADOR}
                          disabled={voucher.VOU_STATUS === VoucherStatus.BAIXADO}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            {voucher.VOU_STATUS === VoucherStatus.ABERTO && (
              <button type="submit" className="btn btn-success btn-wd" form="genericFormId" disabled={isAwaitResponse}>
                {isAwaitResponse ? (
                  <>
                    <i className="fas fa-spinner rotating" /> Processando...
                  </>
                ) : (
                  <>
                    <i className="fa fa-save" /> Baixar
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBaixar;
