import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
  BankAccount,
  FileCustom,
  FileFinance,
  Finance,
  FinanceSearchParams,
  FormFinance,
  FormFinanceSearchParams,
  FormWriteOffFinance,
  ParametersFranchiseAccounts,
  PaymentOption,
} from '~/types';
import { closeModal, formatNumberToMoney, openModal } from '~/utils/util';
import { deleteFinance, getFinances, saveFinance, writeOffFinances } from '~/service/financeiro/FinanceService';
import { showAlertDanger, showAlertInfo, showAlertSuccess } from '~/components/notification';
import { AuthContext } from '~/context/AuthContext';
import ModalFilter from '~/views/financeiro/ModalFilter';
import ViewModalFinance from '~/views/ModalFinance';
import ViewFinanceiro, { cardFinancial } from '~/views/financeiro';
import ModalWriteOffFinance from '~/views/financeiro/ModalWriteOffFinance';
import { defaultMessageError } from '~/config/api';

const Financeiro: React.FC = () => {
  const LIMIT = 20;

  const { user: usuario, company: empresa } = useContext(AuthContext);

  const [cardSelected, setCardSelected] = useState(cardFinancial.NENHUM);
  const [finance, setFinance] = useState<Finance | undefined>(undefined);
  const [finances, setFinances] = useState<Finance[]>([]);
  const [financesFiltered, setFinancesFiltered] = useState<Finance[]>([]);

  const [cardToPay, setCardToPay] = useState({ value: '', valueSecondary: '' });
  const [cardToReceive, setCardToReceive] = useState({ value: '', valueSecondary: '' });
  const [cardOverdue, setCardOverdue] = useState({ value: '', valueSecondary: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>([]);
  const [groupAccounts, setGroupAccounts] = useState<ParametersFranchiseAccounts[]>([]);
  const [entryAccount, setEntryAccount] = useState<ParametersFranchiseAccounts[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [financesChecked, setFinancesChecked] = useState<Finance[]>([]);
  const [FilesFinance, setFilesFinance] = useState<FileFinance[]>([]);
  const [searchState, setSearchState] = useState<FinanceSearchParams>({
    emp_codigo: empresa.EMP_CODIGO,
    pagina: 0,
    limite: LIMIT,
    data_inicio: moment().subtract(3, 'd'),
    data_final: moment().add(3, 'd'),
    tipo_data: 'LAN_DATAVENCIMENTO',
    baixados: 'S',
    tipoLancamento: ['R', 'D'],
    coluna: 1,
    direcao: 'ASC',
  });

  useEffect(() => {
    const hasAccess = localStorage.getItem('@viaPermuta:hasAccessFinance');
    if (!hasAccess) {
      showAlertInfo({ message: 'Clique nos cards no topo da página para filtrar as cobranças', timeout: 10 });
      localStorage.setItem('@viaPermuta:hasAccessFinance', 'true');
    }
  }, []);

  useEffect(() => {
    fetchFinances(searchState);
  }, [searchState]);

  useEffect(() => {
    if (cardSelected === cardFinancial.NENHUM) setFinancesFiltered(finances);
    else if (cardSelected === cardFinancial.A_PAGAR) setFinancesFiltered(finances.filter((finance) => finance.ECC_TIPO === 'D'));
    else if (cardSelected === cardFinancial.A_RECEBER) setFinancesFiltered(finances.filter((finance) => finance.ECC_TIPO === 'R'));
    else if (cardSelected === cardFinancial.INADIMPLENTE)
      setFinancesFiltered(finances.filter((finance) => moment(finance.dataVencimentoModal, 'DD/MM/YYYY').valueOf() < new Date().getTime()));
  }, [cardSelected, finances]);

  const selectCard = (myself: cardFinancial) => {
    cardSelected === myself ? setCardSelected(cardFinancial.NENHUM) : setCardSelected(myself);
  };

  const fetchFinances = async (params: FinanceSearchParams) => {
    setFinance(undefined);
    setIsLoading(true);
    const { success, payload, error } = await getFinances({
      ...params,
      pagina: params.pagina * LIMIT,
      coluna: convertColumnIndex(params.coluna),
      data_inicio: moment(params.data_inicio).format('DD/MM/YYYY'),
      data_final: moment(params.data_final).format('DD/MM/YYYY'),
    });
    setIsLoading(false);

    if (success) {
      const { lancamentos, gruposContas, formaPagamento, contasPlanosContas, contasBancaria, arquivos } = payload;
      setFinances(lancamentos);
      setPaymentOptions(formaPagamento);
      setGroupAccounts([...gruposContas[0].FILHOS, ...gruposContas[1].FILHOS]);
      setEntryAccount(contasPlanosContas);
      setBankAccounts(contasBancaria);
      setFilesFinance(arquivos);
      setFinancesChecked([]);

      setCardToPay({
        value: lancamentos.filter((finance: Finance) => finance.ECC_TIPO === 'D').length,
        valueSecondary: formatNumberToMoney(
          lancamentos
            .filter((finance: Finance) => finance.ECC_TIPO === 'D')
            .reduce((accumulator: number, finance: Finance) => +finance.LAN_VALOR.replace('.', '').replace(',', '.') + accumulator, 0)
        ),
      });

      setCardToReceive({
        value: lancamentos.filter((finance: Finance) => finance.ECC_TIPO === 'R').length,
        valueSecondary: formatNumberToMoney(
          lancamentos
            .filter((finance: Finance) => finance.ECC_TIPO === 'R')
            .reduce((accumulator: number, finance: Finance) => +finance.LAN_VALOR.replace('.', '').replace(',', '.') + accumulator, 0)
        ),
      });

      setCardOverdue({
        value: lancamentos.filter((finance: Finance) => moment(finance.dataVencimentoModal, 'DD/MM/YYYY').valueOf() < new Date().getTime()).length,
        valueSecondary: formatNumberToMoney(
          lancamentos
            .filter((finance: Finance) => moment(finance.dataVencimentoModal, 'DD/MM/YYYY').valueOf() < new Date().getTime())
            .reduce((accumulator: number, finance: Finance) => +finance.LAN_VALOR.replace('.', '').replace(',', '.') + accumulator, 0)
        ),
      });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const convertColumnIndex = (index: number) =>
    index === 0 ? 2 : index === 1 ? 3 : index === 2 ? 4 : index === 4 ? 1 : index === 5 ? 8 : index === 6 ? 9 : -1;

  const handleFilterFinances = (params: FormFinanceSearchParams) => {
    setSearchState({
      ...searchState,
      data_inicio: params.data_inicio,
      data_final: params.data_final,
      tipo_data: params.tipo_data,
      campo_pesquisa: params.campo_pesquisa?.value as string,
      baixados: params.baixados,
      tipoLancamento: params.tipoLancamento === 'T' ? ['D', 'R'] : [params.tipoLancamento],
      orc_codigo: params.orc_codigo,
      pagina: 0,
    });
  };

  const openModalAddFinance = () => {
    setFinance(undefined);
    openModal('ModalFinance');
  };

  const openModalEditFinance = (finance: Finance) => {
    setFinance(finance);
    openModal('ModalFinance');
  };

  const handleSaveFinance = async (formFinance: FormFinance & { tipoAlteracao: number; files: FileCustom<false, FileFinance>[] }) => {
    setIsAwaitResponse(true);
    const { success, payload, message, error } = await saveFinance({
      emp_codigo: empresa.EMP_CODIGO,
      usu_codigo: usuario.USU_CODIGO,

      vezes: finance ? undefined : formFinance.vezes,
      recebido: formFinance.lan_recebido === 'S' ? 'S' : undefined,
      lan_datavencimento: formFinance.lan_datavencimento.split('-').reverse().join('/'),
      cfo_codigo: formFinance.cfo_codigo,
      ecc_codigo: formFinance.ecc_codigo,
      cba_codigo: formFinance.cba_codigo,
      fpa_codigo: formFinance.fpa_codigo,
      parcela: formFinance.parcela,
      valor: formFinance.valor,
      vencimento: formFinance.vencimento.map((d) => d.split('-').reverse().join('/')),
      documento: formFinance.documento,

      lan_datarecebido: formFinance.lan_datarecebido?.split('-').reverse().join('/'),
      lan_valorrecebido: formFinance.lan_valorrecebido,

      lan_valordesconto: formFinance.lan_valordesconto,
      lan_valorjurosmulta: formFinance.lan_valorjurosmulta,

      tipoAlteracao: finance !== undefined && formFinance.vezes !== 1 ? formFinance.tipoAlteracao : 1,
      lan_valor: formFinance.lan_valor,
      lan_codigo: finance?.LAN_CODIGO,
      lan_numero: finance?.LAN_NUMERO,

      newFiles: formFinance.files.filter((file) => file.isNew).map((file) => (file as FileCustom<true, false>).file),
      oldFiles: formFinance.files.filter((file) => !file.isNew),
    });
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalFinance');
      setFinance(null as any);
      fetchFinances(searchState);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
    }
  };

  const onCheckFinance = (finance: Finance | Finance[]) => {
    if (Array.isArray(finance)) {
      setFinancesChecked(finance);
    } else {
      setFinancesChecked((current) => {
        const found = current.filter((c) => c.LAN_CODIGO != finance.LAN_CODIGO);

        if (found.length !== current.length) {
          return found;
        } else {
          return [...current, finance];
        }
      });
    }
  };

  const onWriteOffFinance = async ({ lan_datarecebido_receita, lan_datarecebido_despesa, ...params }: FormWriteOffFinance) => {
    setIsLoading(true);
    const { success, message } = await writeOffFinances({
      ...params,
      emp_codigo: empresa.EMP_CODIGO,
      lan_datarecebido_receita: moment(lan_datarecebido_receita).format('DD/MM/YYYY'),
      lan_datarecebido_despesa: moment(lan_datarecebido_despesa).format('DD/MM/YYYY'),
      parcelaLancamento: financesChecked.map(({ LAN_CODIGO }) => LAN_CODIGO),
    });

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalWriteOffFinance');
      fetchFinances(searchState);
    } else {
      showAlertDanger({ message: message || defaultMessageError });
      setIsLoading(false);
    }
  };

  const handleDeleteFinance = async ({ lan_codigo, lan_numero, emp_codigo, change_type }: { lan_codigo: number | string, lan_numero: number | string, emp_codigo: number | string, change_type: number }) => {
    setIsAwaitResponse(true);
    const { success, message } = await deleteFinance({ lan_codigo, lan_numero, emp_codigo, change_type });
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalFinance');
      setFinance(null as any);
      fetchFinances(searchState);
    } else {
      showAlertDanger({ message: message || defaultMessageError });
    }
  };

  return (
    <ViewFinanceiro
      isLoading={isLoading}
      cardSelected={cardSelected}
      onSelectCard={selectCard}
      cards={{ cardToPay, cardToReceive, cardOverdue }}
      openModalAddFinance={openModalAddFinance}
      openModalEditFinance={openModalEditFinance}
      finances={financesFiltered}
      searchState={searchState}
      ModalFinance={
        <ViewModalFinance
          finance={finance}
          paymentOptions={paymentOptions}
          groupAccounts={groupAccounts}
          entryAccount={entryAccount}
          bankAccounts={bankAccounts}
          filesFinance={FilesFinance}
          isAwaitResponse={isAwaitResponse}
          onSubmit={handleSaveFinance}
          onDelete={handleDeleteFinance}
        />
      }
      ModalFilter={<ModalFilter onSubmit={handleFilterFinances} />}
      ModalWriteOffFinance={
        <ModalWriteOffFinance
          isLoading={isLoading}
          onSubmit={onWriteOffFinance}
          financesChecked={financesChecked}
          bankAccounts={bankAccounts}
          paymentOptions={paymentOptions}
        />
      }
      onSort={setSearchState}
      financesChecked={financesChecked}
      onCheckFinance={onCheckFinance}
    />
  );
};

export default Financeiro;
