import React, { useContext, useState } from 'react';
import { getDashboardFranquia } from '~/service/DashboardService';
import { AuthContext } from '~/context/AuthContext';
import 'react-datetime/css/react-datetime.css';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import ViewDashboard from '~/views/dashboard';
import SelectCompany from '~/components/input/SelectCompany';

const Dashboard: React.FC = () => {
  const { company } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [filterStartDate, setFilterStartDate] = useState(moment());
  const [filterEndDate, setFilterEndDate] = useState(moment());

  const [startDate, setStartDate] = useState(moment().subtract(5, 'months'));
  const [endDate, setEndDate] = useState(moment());

  const [selectedCompany, setSelectedCompany] = useState<{ value: number; label: string; labelAux: string }>(
    company.FRANQUIAS
      ? {
          value: company.FRANQUIAS[0].EMP_CODIGO,
          label: company.FRANQUIAS[0].EMP_NOMEFANTASIA,
          labelAux: company.FRANQUIAS[0].EMP_RAZAOSOCIAL,
        }
      : ({} as any)
  );

  const fetchDashboard = async () => {
    const {
      payload: {
        totalVendasXMes,
        ticketMedio,
        totalTransacaoXMes,
        top10Associados,
        QUADROS,
        perfilTransacoes,
        saldoInicioGraficoRede,
        saldoRede,
        totalAssociadosPorSegmento,
        totalMesCorrente,
        totalMesPassado,
        qtdNegociacoesMes,
        qtdNegociacoesMesPassado,
        ultimasNegociacoes,
      },
    } = await getDashboardFranquia({
      EMP_TIPO: company.EMP_TIPO,
      EMP_CODIGO: selectedCompany.value,
      EMP_CODMATRIZ: company.EMP_CODMATRIZ,
      DATA_INICIO: filterStartDate.format('YYYY-MM'),
      DATA_FIM: filterEndDate.format('YYYY-MM'),
      DATA_INICIO_GRAFICO: startDate.format('YYYY-MM'),
      DATA_FIM_GRAFICO: endDate.format('YYYY-MM'),
    });

    return {
      totalVendasXMes,
      ticketMedio,
      totalTransacaoXMes,
      top10Associados,
      QUADROS,
      perfilTransacoes,
      saldoInicioGraficoRede,
      saldoRede,
      totalAssociadosPorSegmento,
      totalMesCorrente,
      totalMesPassado,
      qtdNegociacoesMes,
      qtdNegociacoesMesPassado,
      ultimasNegociacoes,
    };
  };

  const changeFilterEndDate = (state: Moment) => {
    setFilterEndDate(state);

    setStartDate(state.clone().subtract(5, 'months'));
    setEndDate(state.clone());
  };

  return (
    <ViewDashboard
      key={`ViewDashboard_${selectedCompany.value}`}
      isLoadingState={{ isLoading, setIsLoading }}
      handleFetchDashboard={fetchDashboard}
      filterStartDate={filterStartDate}
      filterEndDate={filterEndDate}
      changeFilterStartDate={setFilterStartDate}
      changeFilterEndDate={changeFilterEndDate}
      startDate={startDate}
      endDate={endDate}
      headerAppend={
        <SelectCompany
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          franquias={company.FRANQUIAS ?? []}
        />
      }
    />
  );
};

export default Dashboard;
