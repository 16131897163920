import React, { CSSProperties } from 'react';
import { LoadingSpinProps } from '~/types';
import './styles.css';

const LoadingSpin: React.FunctionComponent<LoadingSpinProps> = ({ size = '30px', radius = '5px', justifyContent = 'center', alignItems = 'center' }) => {
  const style: CSSProperties = {
    width: size,
    height: size,
    border: `${radius} solid #f3f3f3`,
    borderTop: `${radius} solid #ffa534`,
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: justifyContent,
        alignItems: alignItems,
      }}
    >
      <div style={style} className="loader" />
    </div>
  );
};

export default LoadingSpin;
