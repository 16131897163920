import React, { useContext, useEffect, useState } from 'react';

import { showAlertDanger } from '~/components/notification';
import { AuthContext } from '~/context/AuthContext';
import { totalizadoresFranquiaVisaoAssociado } from '~/service/DashboardService';
import { Associate, DashboardTransactions } from '~/types';
import { formatNumberWithoutPrefix } from '~/utils/util';
import ViewRaioXAssociado from '~/views/raioXAssociado';

const RaioXAssociado: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  const [cardBalance, setCardBalance] = useState('');
  const [cardLimit, setCardLimit] = useState('');
  const [cardAverageTicket, setCardAverageTicket] = useState('');
  const [cardCredit, setCardCredit] = useState('');

  const [qtdBuy, setQtdBuy] = useState('');
  const [qtdSell, setQtdSell] = useState('');

  const [associate, setAssociate] = useState<Associate | undefined>(undefined);
  const [associates, setAssociates] = useState<Associate[]>([]);

  const [associateCodChosen, setAssociateCodChosen] = useState<{ value: string; label: string } | undefined>(undefined);

  const [maioresTransacoes, setMaioresTransacoes] = useState<DashboardTransactions[]>([]);
  const [ultimasTransacoes, setUltimasTransacoes] = useState<DashboardTransactions[]>([]);

  const [graficoTransacoes, setGraficoTransacoes] = useState<{ name: string; buy: number; sell: number }[]>([]);

  const [selectedCompany, setSelectedCompany] = useState<{ value: number; label: string; labelAux: string }>(
    company.FRANQUIAS
      ? {
          value: company.FRANQUIAS[0].EMP_CODIGO,
          label: company.FRANQUIAS[0].EMP_NOMEFANTASIA,
          labelAux: company.FRANQUIAS[0].EMP_RAZAOSOCIAL,
        }
      : ({} as any)
  );

  useEffect(() => {
    fetchDashboardDataRaioXAssociado(null);
  }, []);

  const fetchDashboardDataRaioXAssociado = async (emp_codigo_associado: number | string | null) => {
    setIsLoading(true);

    //  const { success, payload } = await getDashboardDataRaioXAssociado({ emp_codigo_associado });

    const emp_codigo = selectedCompany?.value ? selectedCompany.value : company.EMP_CODIGO;

    const { success, payload } = await totalizadoresFranquiaVisaoAssociado(emp_codigo, emp_codigo_associado);

    if (success) {
      setAssociates(payload.associados);
      setAssociateCodChosen({ value: payload.emp_codigo_associado, label: payload.emp_razaosocial });
      mountDashboard(payload);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro interno. Tente recarregar a página.' });
    }

    setIsLoading(false);
  };
  const mountDashboard = (payload: any) => {
    const { limiteAprovado, valorCompra, valorVenda, alertaNegocio } = payload;

    const regulator = +alertaNegocio;
    const limit = +limiteAprovado;
    const buys = +valorCompra;
    const sells = +valorVenda;

    //The value of "buys" is received as negative from the backend
    const credit = limit + (buys + sells - regulator);
    const balance = buys + sells - regulator;

    setCardBalance(formatNumberWithoutPrefix(balance));
    setCardLimit(formatNumberWithoutPrefix(limit));
    setCardAverageTicket(formatNumberWithoutPrefix(payload.ticketMedio));
    setCardCredit(formatNumberWithoutPrefix(credit));

    setGraficoTransacoes([
      {
        name: 'Total',
        buy: payload.valorCompra * -1,
        sell: payload.valorVenda,
      },
    ]);
    setAssociate(payload.associado);

    setQtdBuy(payload.qntCompras);
    setQtdSell(payload.qntVendas);

    setMaioresTransacoes(payload.maioresTransacoes);
    setUltimasTransacoes(payload.ultimasTransacoes);
  };

  /*   const fetchDashboardDataRaioX = async () => {
    setIsLoading(true);
    const { success, payload } = await getDashboardDataRaioX({ emp_codigo: company.EMP_CODIGO });

    if (success) {
      const { emp_codigo_associado, emp_razaosocial, associados, dados, associado, maioresTransacoes, ultimasTransacoes } = payload;

      setAssociates(associados);
      setAssociateCodChosen({ value: emp_codigo_associado, label: emp_razaosocial });
      mountDashboard({
        card: { ...dados.QUADRO0, ...dados.QUADRO1 },
        associate: associado,
        biggestTransactions: maioresTransacoes,
        lastTransactions: ultimasTransacoes,
      });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro interno. Tente recarregar a página.' });
    }

    setIsLoading(false);
  };

  const fetchDashboardDataRaioXAssociado = async (emp_codigo_associado: number | string) => {
    setIsLoading(true);
    const { success, payload } = await getDashboardDataRaioXAssociado({ emp_codigo_associado });

    if (success) {
      const { dados, associado, maioresTransacoes, ultimasTransacoes } = payload;
      mountDashboard({
        card: { ...dados.QUADRO0, ...dados.QUADRO1 },
        associate: associado,
        biggestTransactions: maioresTransacoes,
        lastTransactions: ultimasTransacoes,
      });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro interno. Tente recarregar a página.' });
    }

    setIsLoading(false);
  }; */

  /*   const mountDashboard = ({ card, associate, biggestTransactions, lastTransactions }: any) => {
    const regulator = +card.TOTAL_REGULADOR.replace(',', '.');
    const limit = +card.LIMITE_APROVADO.replace(',', '.');
    const buys = +card.TOTAL_COMPRA.replace(',', '.');
    const sells = +card.TOTAL_VENDA.replace(',', '.');

    const balance = buys + sells - regulator;
    const credit = limit + (buys + sells - regulator);

    setCardBalance(formatNumberWithoutPrefix(balance));
    setCardLimit(formatNumberWithoutPrefix(limit));
    setCardAverageTicket(formatNumberWithoutPrefix(correctNumberWithComma(card.TICKET_MEDIO_COMPRA)));
    setCardCredit(formatNumberWithoutPrefix(credit));

    setGraficoTransacoes([{ name: 'Total', buy: correctNumberWithComma(card.TOTAL_COMPRA) * -1, sell: correctNumberWithComma(card.TOTAL_VENDA) }]);
    setAssociate(associate);

    setQtdBuy(card.QTD_COMPRA);
    setQtdSell(card.QTD_VENDA);

    setMaioresTransacoes(biggestTransactions);
    setUltimasTransacoes(lastTransactions);
  }; */

  return (
    <ViewRaioXAssociado
      isLoading={isLoading}
      cards={{
        cardBalance,
        cardLimit,
        cardAverageTicket,
        cardCredit,
        qtdBuy,
        qtdSell,
      }}
      fetchDashboardDataRaioXAssociado={fetchDashboardDataRaioXAssociado}
      associate={associate}
      associates={associates}
      associateCodChosenState={{
        associateCodChosen,
        setAssociateCodChosen,
      }}
      maioresTransacoes={maioresTransacoes}
      ultimasTransacoes={ultimasTransacoes}
      graficoTransacoes={graficoTransacoes}
    />
  );
};

export default RaioXAssociado;
