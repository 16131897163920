import React, { useCallback, useEffect } from 'react';
import Modal from '~/components/modal/Modal';
import { Image, ImageCropperProps } from '~/types';
import { useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { closeModal } from '~/utils/util';

const ImageCropper: React.FunctionComponent<ImageCropperProps> = ({ image, onConfirm }) => {
  const [imageCrop, setImageCrop] = useState<Cropper>();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    image
      ? setTimeout(() => {
          setImageUrl(image.preview);
        }, 500)
      : setImageUrl('');
  }, [image]);

  const handleOnConfirm = () => {
    if (image && imageCrop) {
      imageCrop.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          const file = new File([blob], image.name, {type: blob.type});
          onConfirm({
            ...image,
            preview: URL.createObjectURL(file),
            file: file,
          });
        }
      });

      URL.revokeObjectURL(imageUrl);
      setImageUrl('');
    }
  };

  return (
    <Modal
      id="ImageCropper"
      size="md"
      dismissOnClickOut={false}
      body={() => (
        <Cropper
          style={{ width: '100%', height: 'auto', maxHeight: 'calc(90vh - 190px)' }}
          zoomTo={0}
          aspectRatio={230 / 200}
          src={imageUrl}
          viewMode={0}
          background={false}
          responsive={true}
          autoCropArea={1}
          autoCrop
          checkOrientation={false}
          onInitialized={(instance) => {
            setImageCrop(instance);
          }}
          guides={true}
        />
      )}
      footer={() => (
        <>
          <button type="button" className="btn btn-wd btn-default btn-outline" onClick={() => closeModal('ImageCropper')}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
          <button type="button" className="btn btn-wd btn-success" onClick={handleOnConfirm}>
            <i className="fa fa-save" /> Confirmar
          </button>
        </>
      )}
    />
  );
};

export default ImageCropper;
