import React, { useContext, useState } from 'react';
import {
  ModalRequestQuoteProps,
  QuoteFormData,
} from '~/types';
import { AuthContext } from '~/context/AuthContext';
import { useErrorHandler } from 'react-error-boundary';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicTextarea from '~/components/input/BasicTextarea';

const ModalRequestQuote: React.FunctionComponent<ModalRequestQuoteProps> = ({ onSubmit }) => {
  const validationSchema: yup.SchemaOf<QuoteFormData> = yup.object().shape({
    one_descricao: yup.string().required(),
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<QuoteFormData>({
    resolver: yupResolver(validationSchema),
  });

  const handleError = useErrorHandler();
  const { company: empresa } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async (quoteFormData: QuoteFormData) => {
    setIsLoading(true);
    await onSubmit(quoteFormData);
    setIsLoading(false);
    reset()
  };

  return (
    <div className="modal fade" id="ModalRequestQuote" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document" style={{ paddingTop: '100px' }}>
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Solicitação de orçamento</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form id="genericFormId" onSubmit={handleSubmit(handleOnSubmit)}>
                      <BasicTextarea
                        label="Descrição da resposta ou pergunta..."
                        name="one_descricao"
                        ref={register}
                        hasError={!!errors.one_descricao}
                        placeholder="Descreva brevemente sua dúvida ou pergunta..."
                        defaultValue=""
                        height="240px"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" form="genericFormId" disabled={isLoading}>
              {isLoading ? (
                <>
                  <i className="fas fa-spinner rotating" /> Processando...
                </>
              ) : (
                <>
                  <i className="fa fa-search"></i> Enviar
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRequestQuote;
