import React from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
// import LoadingSpin from '~/components/loading/LoadingSpin/index';
import { IndicatorCardInterface } from '~/types';

const IndicatorCard: React.FunctionComponent<IndicatorCardInterface> = ({ label, value, title, icon, selected, valueSecondary, onClick, helper }) => {
  return (
    <div
      className={`card card-stats ${selected ? 'card-selected' : ''} ${onClick ? 'clickable card-clickable' : ''}`}
      onClick={() => onClick && onClick()}
    >
      {helper && (
        <div style={{ position: 'absolute', top: '-6px', right: '2px', width: '11px', height: '11px', cursor: 'pointer' }}>
          <OverlayTrigger
            overlay={
              <Popover id="popover-basic">
                <Popover.Title as="h3">{helper.title}</Popover.Title>
                <Popover.Content>{helper.text}</Popover.Content>
              </Popover>
            }
          >
            <i className="far fa-question-circle" style={{ fontSize: '8pt' }}></i>
          </OverlayTrigger>
        </div>
      )}
      <div className="card-body ">
        <div className="row">
          <div className="col-5">
            <div className="icon-big text-center icon-warning" style={{ maxWidth: '95px' }}>
              <i className={icon}></i>
            </div>
          </div>
          <div className="col-7">
            <div className="numbers">
              <p className="card-category">{label}</p>
              <h4 className="card-title card-title-dashboard">
                {value !== '' ? value : <> {/* <LoadingSpin size='30px' radius='5px' justifyContent='flex-end'/> */}</>}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer ">
        <hr />
        <div className="card-footer-stats">
          <div className="stats">{title}</div>
          {valueSecondary && <div className="stats">{valueSecondary}</div>}
        </div>
      </div>
    </div>
  );
};

export default IndicatorCard;
