import React, { useContext, useEffect, useState } from 'react';

import logoFiltro from '../../../assets/filtro_2.png';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Menu from '../../../components/menu';
import ModalFilter from './ModalFilter';
import ModalCreateOffer from '~/components/modal/ModalCreateOffer/ModalCreateOffer';
import IndicatorCard from '~/components/card/IndicatorCard';
import TableMyOffers from '~/components/table/TableMyOffers';
import { ConfigVPMIX, GoodOffered, Offer, PaymentOption } from '~/types';
import { getMyOffers, getMyOffersFilterData } from '~/service/OfferService';
import { AuthContext } from '~/context/AuthContext';
import { OfferSituation } from '~/enum';
import { formatAndCompareDate, formatDateToDefaultPattern, openModal } from '~/utils/util';
import PageLoading from '~/components/loading/PageLoading';

const CriarOfertas: React.FC = () => {
  const [cardEncerradas, setCardEncerradas] = useState('');
  const [cardAbertas, setCardAbertas] = useState('');
  const [cardBloqueadasPendentes, setCardBloqueadasPendentes] = useState('');
  const [cardLiberadas, setCardLiberadas] = useState('');
  const [configModal, setConfigModal] = useState(
    {} as { config_vpmix: ConfigVPMIX; formasPgto: PaymentOption[]; segmentos: GoodOffered[] }
  );

  const { company: empresa } = useContext(AuthContext);
  const [offer, setOffer] = useState<(Offer & { isCopy?: 'S' | 'N' | '' }) | null>(null);
  const [offers, setOffers] = useState([] as Array<Offer>);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModal, setIsLoadingModal] = useState(true);
  const [filters, setFilters] = useState({
    filterGoodsOffered: [] as Array<{ value: string }>,
    filterSituation: [] as Array<{ value: string }>,
    filterWithValue: true,
    filterToCombine: true,
    filterVP: 'PADRAO',
    filterVPMIX: 'VPMIX',
  });

  useEffect(() => {
    fetchMyOffers();
    fetchMyOffersFilterData();
  }, []);

  const fetchMyOffersFilterData = async () => {
    setIsLoadingModal(true);
    const { payload } = await getMyOffersFilterData(empresa.EMP_CODIGO);
    setConfigModal(payload);
    setIsLoadingModal(false);
  };

  const fetchMyOffers = async () => {
    setOffer(null);
    setIsLoading(true);
    let situacao = '(';
    filters.filterSituation.forEach((s, i) => {
      i === 0 ? (situacao += `'${s.value}'`) : (situacao += `,'${s.value}'`);
    });
    situacao += ')';

    const { payload } = await getMyOffers({
      emp_codigo: empresa.EMP_CODIGO,
      seg_codigo:
        filters.filterGoodsOffered.length === 0
          ? null
          : `(${filters.filterGoodsOffered.map((f) => f.value).toString()})`,
      situacao: filters.filterSituation.length === 0 ? null : situacao,
      ofe_tipo: `('${filters.filterVP}', '${filters.filterVPMIX}')`,
      com_valor: filters.filterWithValue,
      a_combinar: filters.filterToCombine,
    });

    setOffers(payload.ofertas.sort((a: Offer, b: Offer) => formatAndCompareDate(b.OFE_DATA, a.OFE_DATA)));

    setCardEncerradas(
      payload.ofertas.reduce((accumulator: number, currentValue: Offer) => {
        if (currentValue.OFE_STATUS === OfferSituation.ENCERRADA) return accumulator + 1;
        else return accumulator;
      }, 0)
    );
    setCardAbertas(
      payload.ofertas.reduce((accumulator: number, currentValue: Offer) => {
        if (currentValue.OFE_STATUS === OfferSituation.ABERTA) return accumulator + 1;
        else return accumulator;
      }, 0)
    );
    setCardBloqueadasPendentes(
      payload.ofertas.reduce((accumulator: number, currentValue: Offer) => {
        if (currentValue.OFE_STATUS === OfferSituation.BLOQUEADA || currentValue.OFE_STATUS === OfferSituation.PENDENTE)
          return accumulator + 1;
        else return accumulator;
      }, 0)
    );
    setCardLiberadas(
      payload.ofertas.reduce((accumulator: number, currentValue: Offer) => {
        if (currentValue.OFE_STATUS === OfferSituation.LIBERADA) return accumulator + 1;
        else return accumulator;
      }, 0)
    );

    setIsLoading(false);
  };

  const onSelectOffer = (offer: Offer | null) => {
    setOffer(offer);
    openModal('criarOferta');
  };

  const onDuplicateOffer = (offer: Offer) => {
    // showAlertWarning({ message: 'Esta função ainda não foi implementada' });
    setOffer({
      ...offer,
      OFE_CODIGO: '',
      OFE_TITULO: offer.OFE_TITULO + ' (Cópia)',
      OFE_STATUS: 'A',
      FPA_CODIGO: '',
      USU_CODIGO: '',
      OFE_PRAZORESGATE: formatDateToDefaultPattern(offer.OFE_PRAZORESGATE),
      OFE_DATAEXPIRACAO: formatDateToDefaultPattern(offer.OFE_DATAEXPIRACAO),
      isCopy: 'S',
    });
    $('#criarOferta').modal('show');
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Minhas Ofertas" showBalance />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  label="Encerradas"
                  value={cardEncerradas}
                  title="Ofertas cadastradas"
                  icon="nc-icon nc-icon nc-layers-3"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  label="Abertas"
                  value={cardAbertas}
                  title="Ofertas abertas"
                  icon="nc-icon nc-icon nc-layers-3 text-warning"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  label="Bloqueadas ou Pendentes"
                  value={cardBloqueadasPendentes}
                  title="Ofertas bloqueadas, pend."
                  icon="nc-icon nc-icon nc-layers-3 text-danger"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  label="Liberadas"
                  value={cardLiberadas}
                  title="Ofertas liberadas para venda"
                  icon="nc-icon nc-icon nc-layers-3 text-success"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card table-big-boy">
                  <div className="card-header ">
                    <h4 className="card-title">
                      Ofertas cadastradas{' '}
                      <img
                        src={logoFiltro}
                        style={{ width: 30, height: 30, cursor: 'pointer' }}
                        data-toggle="modal"
                        data-target="#filtro"
                      />
                    </h4>
                    <p className="card-category" data-toggle="modal" data-target="#filtro">
                      Aplique os filtros acima e encontre a oferta
                    </p>
                    <button
                      onClick={() => onSelectOffer(null)}
                      type="button"
                      className="btn btn-sm btn-success btn-outline"
                      style={{ marginTop: 10 }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus"></i>
                      </span>
                      cadastrar oferta
                    </button>
                  </div>

                  <ModalCreateOffer
                    isLoading={isLoadingModal}
                    config={configModal}
                    offer={offer}
                    fetchMyOffers={fetchMyOffers}
                    onConfirm={setOffer}
                  />
                  <ModalFilter
                    isLoading={isLoadingModal}
                    config={configModal}
                    filtersState={{ filters: filters, setFilters: setFilters }}
                    fetchMyOffers={fetchMyOffers}
                  />

                  <div className="card-body table-full-width">
                    <div className="table-responsive">
                      <TableMyOffers
                        offers={offers}
                        isLoading={isLoading}
                        enableDuplicateOffer
                        onSelectOffer={onSelectOffer}
                        onDuplicateOffer={onDuplicateOffer}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default CriarOfertas;
