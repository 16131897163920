import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';

/**
 * Error catcher
 * Não funciona para funções assíncronas
 * Erros em funções assíncronas dever ser tratados com a função:
 * const handleError = useErrorHandler() importada do pacote react-error-boundary
 */
const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div className="wrapper">
      <Menu resetErrorBoundary={resetErrorBoundary}/>

      <div className="main-panel">
        <Header title="" />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title" style={{ marginBottom: '10px' }}>
                      Algo deu errado.
                    </h4>
                    <button className="btn btn-secondary" onClick={resetErrorBoundary}>
                      Tentar novamente
                    </button>
                    <br />
                  </div>

                  <div className="card-body" style={{ marginTop: '70px' }}>
                    <div className="accordions" id="accordion">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            <a data-target="#collapseOne" href="#" data-toggle="collapse" style={{ textAlign: 'start', color: '#9a9a9a' }}>
                              Informação de depuração<b className="caret"></b>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" className="card-collapse collapse">
                          <div className="card-body">
                            <pre>{error.message}</pre>
                            <pre>{error.stack}</pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

const ErrorCatcher: React.FunctionComponent = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}} onError={() => {}}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorCatcher;
