import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import {
  AssociateCreditHistory,
  AssociateWithCredit,
  FormAssociatesCreditHistorySearchParams,
  FormCreditRecordAux,
  Franchise,
} from '~/types';
import LayoutMain from '~/layout/main';
import CardHeader from '~/components/card/CardHeader';
import TableWithLinks from '~/components/table/TableWithLinks';
import { getAssociateCreditRecords, getAssociatesCredit, saveAssociateCreditRecord } from '~/service/AssociateService';
import { matchSorter } from 'match-sorter';
import { useErrorHandler } from 'react-error-boundary';
import { correctNumberWithComma, formatCNPJ, formatCPF, formatNumberToVPS, openModal } from '~/utils/util';
import ModalCreditRecords from './ModalCreditRecords';
import ModalFiltro from './ModalFilter';

const LimitesCredito: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);
  const handleError = useErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssociateRecords, setIsLoadingAssociateRecords] = useState(false);
  const [isAwaitFormResponse, setIsAwaitFormResponse] = useState(false);

  const [associateSelected, setAssociateSelected] = useState<AssociateWithCredit | undefined>(undefined);
  const [associates, setAssociates] = useState<AssociateWithCredit[]>([]);
  const [associatesFiltered, setAssociatesFiltered] = useState<AssociateWithCredit[]>([]);
  const [franchise, setFranchise] = useState<Franchise[]>([]);

  const [associateRecords, setAssociateRecords] = useState<AssociateCreditHistory[]>([]);

  const [searchBoxValue, setSearchBoxValue] = useState('');

  useEffect(() => {
    fetchAssociates({ franchiseId: undefined });
  }, []);

  useEffect(() => {
    setAssociatesFiltered([
      ...matchSorter(associates, searchBoxValue, { keys: ['EMP_RAZAOSOCIAL', 'EMP_NOMEFANTASIA'] }),
    ]);
  }, [searchBoxValue, associates]);

  const fetchAssociates = async (params?: FormAssociatesCreditHistorySearchParams) => {
    try {
      setIsLoading(true);
      setAssociateRecords([]);
      const { success, payload, error } = await getAssociatesCredit({
        emp_codmatriz: params?.franchiseId,
      });
      setIsLoading(false);

      if (success) {
        const { associados, franquias } = payload;

        setAssociates(associados);
        setFranchise(franquias);
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        if (error) console.log(error.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchAssociateRecords = async (emp_codigo: number) => {
    try {
      setAssociateRecords([]);
      setIsLoadingAssociateRecords(true);
      const { success, payload, error } = await getAssociateCreditRecords({ emp_codigo });
      setIsLoadingAssociateRecords(false);

      if (success) {
        const { registros } = payload;

        setAssociateRecords(registros);
        return true;
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        if (error) console.log(error.message);

        return false;
      }
    } catch (error) {
      handleError(error);
      return false;
    }
  };

  const showAssociateRecords = async (associate: AssociateWithCredit) => {
    setAssociateSelected(associate);
    fetchAssociateRecords(+associate.EMP_CODIGO);
    openModal('ModalCreditRecords');
  };

  const submitAssociateCreditRecord = async (formData: FormCreditRecordAux) => {
    try {
      setIsLoadingAssociateRecords(true);
      const { success, payload, error } = await saveAssociateCreditRecord(formData as any);
      setIsLoadingAssociateRecords(false);

      if (success) {
        const { registros } = payload;

        setAssociateRecords(registros);
        showAlertSuccess({ message: 'Registro salvo.' });

        fetchAssociates();
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        if (error) console.log(error.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <LayoutMain isLoading={isLoading} title="Limites de Crédito" searchBoxState={{ searchBoxValue, setSearchBoxValue }}>
      <div className="row">
        <div className="col-md-12">
          <div className="card table-with-links">
            <CardHeader
              title="Limite de crédito"
              subtitle="Lista de associados cadastrados"
              filterAction={() => openModal('ModalFilter')}
            />

            <ModalCreditRecords
              isLoading={isLoadingAssociateRecords}
              associate={associateSelected}
              creditRecords={associateRecords}
              onSubmit={submitAssociateCreditRecord}
              isAwaitResponse={isAwaitFormResponse}
            />

            <ModalFiltro isLoading={isLoading} franchises={franchise} onSubmit={fetchAssociates} />

            <div className="card-body table-full-width">
              <TableWithLinks
                isLoading={isLoading}
                emptyTableMessage="Nenhum associado encontrado. Tente alterar os filtros."
                onClickingRow={showAssociateRecords}
                columns={[
                  { field: 'CPFCNPJFormatted', label: 'CPF ou CNPJ', alignment: 'text-center', width: '150px' },
                  { field: 'EMP_RAZAOSOCIAL', label: 'Razão Social' },
                  { field: 'EMP_NOMEFANTASIA', label: 'N.fantasia' },
                  { field: 'MATRIZ_NOMEFANTASIA', label: 'Franquia', alignment: 'text-center' },
                  { field: 'ITI_DESCRICAO', label: 'Situação', alignment: 'text-center', width: '95px' },
                  { field: 'creditLimitFormatted', label: 'Limite de Crédito', alignment: 'text-center' },
                ]}
                data={associatesFiltered.map((associate) => ({
                  ...associate,
                  CPFCNPJFormatted:
                    associate.EMP_CPFCNPJ.length === 11
                      ? formatCPF(associate.EMP_CPFCNPJ)
                      : formatCNPJ(associate.EMP_CPFCNPJ),
                  creditLimitFormatted: formatNumberToVPS(correctNumberWithComma(associate.LIMITE_CREDITO)),
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default LimitesCredito;
