import React, { useState, useEffect, useContext } from 'react';
import { baseURL } from '~/config/api';

import { downloadDocuments, formatNumberWithoutPrefix } from '~/utils/util';
import { AccountPayableReceivable, FormFilterAccountsPayableReceivable } from '~/types';
import { getAccountsPayableReceivable } from '~/service/financeiro/AccountsPayableReceivableService';
import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger } from '~/components/notification';
import moment from 'moment';
import ViewContasPagarReceber from '~/views/relatorioContasPagarReceber';

const ContasPagarReceber: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [cardIncome, setCardIncome] = useState('');
  const [cardExpense, setCardExpense] = useState('');
  const [filterState, setFilterState] = useState<FormFilterAccountsPayableReceivable>({
    tipo: 'T',
    campo_pesquisa: '',
    tipo_data: 'LAN_DATAVENCIMENTO',
    data_inicial: moment().format('YYYY-MM-DD'),
    data_final: moment().format('YYYY-MM-DD'),
    baixados: 'S',
  });

  const [accountsPayableReceivable, setAccountsPayableReceivable] = useState<AccountPayableReceivable[]>([]);

  useEffect(() => {
    fetchFinances(filterState);
  }, [filterState]);

  const fetchFinances = async (filterParams: FormFilterAccountsPayableReceivable) => {
    setIsLoading(true);
    const { success, error, payload } = await getAccountsPayableReceivable({
      emp_codigo: company.EMP_CODIGO,
      tipo: filterParams.tipo === 'T' ? ['R', 'D'] : [filterParams.tipo],
      campo_pesquisa: filterParams.campo_pesquisa,
      tipo_data: filterParams.tipo_data,
      data_inicial: filterParams.data_inicial.split('-').reverse().join('/'),
      data_final: filterParams.data_final.split('-').reverse().join('/'),
      baixados: filterParams.baixados,
    });
    setIsLoading(false);

    if (success) {
      const { dados }: { dados: AccountPayableReceivable[] } = payload;

      setAccountsPayableReceivable(dados);

      const income: number = dados
        .filter((dado) => dado.ECC_TIPO === 'R')
        .reduce((acc, current) => {
          return acc + +current.LAN_VALOR.replace('.', '').replace(',', '.');
        }, 0);
      const expense: number = dados
        .filter((dado) => dado.ECC_TIPO === 'D')
        .reduce((acc, current) => {
          return acc + +current.LAN_VALOR.replace('.', '').replace(',', '.');
        }, 0);

      setCardIncome(formatNumberWithoutPrefix(income));
      setCardExpense(formatNumberWithoutPrefix(expense));
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const exportToExcel = () => {
    console.log('Contas a Pagar/Receber: exportToExcel');
    const tipo = filterState.tipo === 'R' ? 'SN' : filterState.tipo === 'D' ? 'NS' : 'SS';
    const baixados = filterState.baixados;
    const tipo_data = filterState.tipo_data;
    const data_inicial = filterState.data_inicial.split('-').reverse().join('_');
    const data_final = filterState.data_final.split('-').reverse().join('_');
    const campo_pesquisa = filterState.campo_pesquisa || '';

    const url = `${baseURL}/api/contas-a-pagar-receber/exportar/${tipo}/${baixados}/${tipo_data}/${data_inicial}/${data_final}/${company.EMP_CODIGO}/${campo_pesquisa}`;
    downloadDocuments(url, 'Contas a Pagar/Receber - ' + data_inicial + '_' + data_final + '.xls', false);
  };

  return (
    <ViewContasPagarReceber
      isLoading={isLoading}
      cards={{
        cardIncome,
        cardExpense,
      }}
      accountsPayableReceivable={accountsPayableReceivable}
      exportToExcel={exportToExcel}
      onSubmitFilters={setFilterState}
    />
  );
};

export default ContasPagarReceber;
