import React, { useContext, useEffect, useState } from 'react';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';

import { Bank, BankAccount } from '~/types';
import ModalBankAccount from './ModalBankAccount';
import TableWithLinks from '~/components/table/TableWithLinks';
import { closeModal, openModal } from '~/utils/util';
import SweetAlert from 'react-bootstrap-sweetalert';
import { deleteBankAccount, getBankAccounts } from '~/service/financeiro/BankAccountService';
import { AuthContext } from '~/context/AuthContext';
import PageLoading from '~/components/loading/PageLoading';
import { showAlertDanger, showAlertSuccess, showAlertWarning } from '~/components/notification';

const CadastroContaBancaria: React.FC = () => {
  const { company: empresa } = useContext(AuthContext);

  const [bankAccount, setBankAccount] = useState<BankAccount | undefined>(undefined);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sweetAlertDeleteBankAccount, setSweetAlertDeleteBankAccount] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const fetchBankAccounts = async () => {
    setIsLoading(true);
    const { success, payload, error } = await getBankAccounts(empresa.EMP_CODIGO);
    setIsLoading(false);

    if (success) {
      const { contas, bancos, itensTipoConta } = payload;
      setBankAccounts(contas);
      setBanks(bancos);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const openModalBankAccountAdd = () => {
    openModal('ModalBankAccount');
    setBankAccount(undefined);
  };

  const openModalBankAccountEdit = (bankAccount: BankAccount) => {
    if (bankAccount.BAN_CODIGO === '') {
      showAlertWarning({ message: 'Não é possível editar essa conta' });
    } else {
      openModal('ModalBankAccount');
      setBankAccount(bankAccount);
    }
  };

  const handleDeleteBankAccount = async (bankAccount: BankAccount) => {
    setIsLoading(true);
    setSweetAlertDeleteBankAccount(undefined);
    const { success, payload, message, type, error } = await deleteBankAccount({
      cba_codigo: bankAccount.CBA_CODIGO,
      emp_codigo: empresa.EMP_CODIGO,
    });
    setIsLoading(false);

    if (success) {
      setBankAccounts(payload.contas);
      showAlertSuccess({ message: message });
    } else if (!success && type) {
      showAlertDanger({ message: message });
    } else {
      if (error) console.log(error);
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
    }
  };

  const showConfirmDeleteBankAccount = (bankAccount: BankAccount) => {
    if (bankAccount.BAN_CODIGO === '') {
      showAlertWarning({ message: 'Não é possível excluir essa conta' });
    } else {
      setSweetAlertDeleteBankAccount(
        <SweetAlert
          warning
          title="Atenção!"
          onConfirm={() => handleDeleteBankAccount(bankAccount)}
          onCancel={() => setSweetAlertDeleteBankAccount(undefined)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Sim"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Não"
          showCancel
        >
          Excluir a conta "{bankAccount.BAN_DESCRICAO} - {bankAccount.CBA_DESCRICAO}"?
        </SweetAlert>
      );
    }
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Contas Bancárias" />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card table-big-boy">
                  <div className="card-header">
                    <h4 className="card-title">Lista de Contas Bancárias</h4>
                    <button onClick={openModalBankAccountAdd} type="button" className="btn btn-sm btn-success btn-outline" style={{ marginTop: 10 }}>
                      <span className="btn-label">
                        <i className="fa fa-plus"></i>
                      </span>
                      cadastrar conta
                    </button>
                  </div>

                  <ModalBankAccount bankAccount={bankAccount} banks={banks} onSubmit={fetchBankAccounts} />
                  {sweetAlertDeleteBankAccount}

                  <div className="card-body table-full-width">
                    <TableWithLinks
                      isLoading={isLoading}
                      emptyTableMessage="Nenhuma conta bancária encontrada."
                      onClickingRow={openModalBankAccountEdit}
                      columns={[
                        { field: 'BAN_DESCRICAO', label: 'Banco', alignment: 'text-left' },
                        { field: 'CBA_DESCRICAO', label: 'Descrição', alignment: 'text-left' },
                        { field: 'CBA_AGENCIA', label: 'Agência', alignment: 'text-center', width: '100px' },
                        { field: 'CBA_DVAGENCIA', label: 'DV Agência', alignment: 'text-center', width: '100px' },
                        { field: 'CBA_CONTA', label: 'Conta', alignment: 'text-center', width: '100px' },
                        { field: 'CBA_DVCONTA', label: 'DV Conta', alignment: 'text-center', width: '100px' },
                      ]}
                      data={bankAccounts}
                      actions={[
                        { iconClass: 'far fa-times-circle', title: 'Excluir', style: { color: 'red' }, action: showConfirmDeleteBankAccount },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default CadastroContaBancaria;
