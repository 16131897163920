import React, { useState } from 'react';
import { Offer, TableMyOffersProps } from '~/types';
import * as Util from '~/utils/util';
import { OfferSituation, OfferType } from '~/enum';
import LoadingPointsSpin from '~/components/loading/LoadingPointsSpin';
import { showAlertWarning } from '~/components/notification';
import { baseURL } from '~/config/api';

const TableMyOffers: React.FunctionComponent<TableMyOffersProps> = ({ offers, isLoading, onSelectOffer, enableDuplicateOffer, onDuplicateOffer }) => {
  const [offer, setOffer] = useState({} as Offer);

  return (
    <table className="table table-bigboy">
      <thead>
        <tr>
          <th className="text-center">Imagem</th>
          <th>Título</th>
          <th className="text-center">à combinar</th>
          <th className="text-right">Valor</th>
          <th>Situação</th>
          <th>Doc</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={7}>
              {/* <LoadingPointsSpin /> */}
            </td>
          </tr>
        ) : offers.length === 0 ? (
          <tr>
            <td colSpan={7}>
              <p className="text-color-default">Nenhuma oferta encontrada.</p>
            </td>
          </tr>
        ) : (
          offers.map((offer) => (
            <tr key={offer.OFE_CODIGO}>
              <td className="clickable" onClick={() => onSelectOffer && onSelectOffer(offer)}>
                <div className="img-container text-center">
                  <img
                    src={
                      offer.OFE_IMAGEM !== ''
                        ? `${baseURL}/web/uploads/oferta/imagem/${offer.OFE_IMAGEM}`
                        : `${baseURL}/web/uploads/logoLoja/${offer.EMP_LOGOTIPO}`
                    }
                    alt={offer.OFE_TITULO}
                  />
                </div>
              </td>
              <td className="td-name clickable" onClick={() => onSelectOffer && onSelectOffer(offer)}>
                {offer.OFE_TITULO}
              </td>
              <td className="text-center clickable" onClick={() => onSelectOffer && onSelectOffer(offer)}>
                {offer.OFE_ACOMBINAR === 'S' ? 'Sim' : 'Não'}
              </td>
              <td className="td-number clickable" onClick={() => onSelectOffer && onSelectOffer(offer)}>
                {+offer.OFE_VALOR === 0 ? (
                  'À Combinar'
                ) : offer.OFE_TIPO === OfferType.VP ? (
                  Util.formatNumberToVPS(+Util.correctNumberWithComma(offer.OFE_VALOR))
                ) : (
                  <>
                    {Util.formatNumberToVPS(Util.correctNumberWithComma(offer.OFE_VALOR))}
                    <br />
                    {Util.formatNumberToMoney(Util.correctNumberWithComma(offer.OFE_VALORVPMIX))}
                    <br />
                    <strong>Total</strong> {Util.formatNumberWithoutPrefix(Util.correctNumberWithComma(offer.OFE_VALORTOTAL))}
                  </>
                )}
              </td>
              <td className="text-left clickable" onClick={() => onSelectOffer && onSelectOffer(offer)}>
                {offer.OFE_STATUS === OfferSituation.ABERTA && 'Aberta'}
                {offer.OFE_STATUS === OfferSituation.BLOQUEADA && 'Bloqueada'}
                {offer.OFE_STATUS === OfferSituation.LIBERADA && 'Liberada'}
                {offer.OFE_STATUS === OfferSituation.PENDENTE && 'Pendente'}
                {offer.OFE_STATUS === OfferSituation.ENCERRADA && 'Encerrada'}
                {offer.OFE_STATUS === OfferSituation.REENVIADA && 'Reenviada'}
              </td>
              <td className="text-left clickable" onClick={() => onSelectOffer && onSelectOffer(offer)}>
                {offer.OFE_DOCUMENTO !== '' ? <i className="fas fa-check" /> : <i className="fas fa-ban" />}
              </td>
              <td className="td-actions">
                <div className="flex-in-column" style={{ height: '120px' }}>
                  {enableDuplicateOffer && (
                    <i
                      className="fa fa-clone icon-medium color-default clickable"
                      title="Duplicar oferta"
                      onClick={() => onDuplicateOffer && onDuplicateOffer(offer)}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default TableMyOffers;
