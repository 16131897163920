import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
 {/*      <div className="container">
        <nav>
          <ul className="footer-menu">
            <li>
              <a href="https://socicoin.org/#sobre" target="_blank">
                Quem somos
              </a>
            </li>
            <li>
              <a href="https://socicoin.org/#como-funciona" target="_blank">
                Como Funciona
              </a>
            </li>
            <li>
              <a href="https://socicoin.org/" target="_blank">
                Vantagens
              </a>
            </li>
            <li>
              <a href="https://socicoin.org/" target="_blank">
                Associados
              </a>
            </li>
            <li>
              <a href="https://socicoin.org/#contato" target="_blank">
                Onde Estamos
              </a>
            </li>
          </ul>
          <p className="copyright text-center">
            ©
            <a href="http://www.lakstech.com.br" target="_blank">
              Laks Tecnologia
            </a>
            , faça algo ótimo
          </p>
        </nav>
      </div> */}
    </footer>
  );
};

export default Footer;
