import React from 'react';
import CardHeader from '~/components/card/CardHeader';
import TableOffers from '~/components/table/TableOffers';
import LayoutMain from '~/layout/main';
import { ViewVerOfertasProps } from '~/types';
import { openModal } from '~/utils/util';

const ViewVerOfertas: React.FunctionComponent<ViewVerOfertasProps> = ({
  isLoading,
  ModalFilter,
  ModalOfferDetails,
  offers,
  onSelectOffer,
  ModalPayCommission,
  ModalRequestQuote,
  alert,
  sweetAlertBuyToday,
  headerConfig,
}) => {
  return (
    <LayoutMain isLoading={isLoading} {...headerConfig}>
      <div className="row">
        <div className="col-md-12">
          <div className="card table-big-boy">
            <CardHeader
              title="Lista de ofertas disponíveis"
              subtitle="Aplique os filtros por segmentos, cidades, franquias"
              filterAction={() => openModal('filtro')}
            />
            {/* <div className="card-header">
                    <h4 className="card-title">
                      Lista de ofertas disponíveis{' '}
                      <img src={logoFiltro} style={{ width: 30, height: 30, cursor: 'pointer' }} data-toggle="modal" data-target="#filtro" />
                    </h4>
                    <p className="card-category">Aplique os filtros por segmentos, cidades, franquias</p>
                    <br />
                  </div> */}

            {/* <ModalOfferDetails offer={offer} /> */}
            {/* <ModalFilter fetchOffers={fetchOffers} filtersState={{ filters: filters, setFilters: setFilters }} /> */}

            {ModalOfferDetails}
            {ModalFilter}

            {ModalRequestQuote}
            {ModalPayCommission}
            {alert}
            {sweetAlertBuyToday}

            <div className="card-body table-full-width">
              <div className="table-responsive">
                <TableOffers offers={offers} isLoading={isLoading} onSelectOffer={onSelectOffer} enableShareLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewVerOfertas;
