import React, { useState, useEffect, useContext } from 'react';
import { baseURL } from '~/config/api';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import PageLoading from '~/components/loading/PageLoading';
import TableWithLinks from '~/components/table/TableWithLinks';
import CardHeader from '~/components/card/CardHeader';
import { formatNumberWithoutPrefix, openModal } from '~/utils/util';
import IndicatorCard from '~/components/card/IndicatorCard';
import { AccountPayableReceivable, FormFilterAccountsPayableReceivable, ViewContasPagarReceberProps } from '~/types';
import ModalFilterAccountsPayableReceivable from './ModalFilterAccountsPayableReceivable';
import { getAccountsPayableReceivable } from '~/service/financeiro/AccountsPayableReceivableService';
import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger } from '~/components/notification';
import moment from 'moment';
import LayoutMain from '~/layout/main';

const ViewContasPagarReceber: React.FunctionComponent<ViewContasPagarReceberProps> = ({
  isLoading,
  cards: { cardIncome, cardExpense },
  accountsPayableReceivable,
  exportToExcel,
  onSubmitFilters,
}) => {
  return (
    <LayoutMain isLoading={isLoading} title="Contas a Pagar/Receber">
      <div className="row">
        <div className="col-sm-6">
          <IndicatorCard icon="nc-icon nc-icon nc-bullet-list-67 text-success" label="Receita" value={cardIncome} title="Receita total no período" />
        </div>
        <div className="col-sm-6">
          <IndicatorCard icon="nc-icon nc-icon nc-bullet-list-67 text-danger" label="Despesa" value={cardExpense} title="Despesa total no período" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <CardHeader
              title="Contas a Pagar/Receber"
              subtitle="Aplique os filtros para alterar a visualização do relatório"
              filterAction={() => openModal('ModalFilterAccountsPayableReceivable')}
              extraActions={[{ title: 'Exportar para Excel', iconClass: 'far fa-file-excel', action: exportToExcel }]}
            />

            <ModalFilterAccountsPayableReceivable onSubmit={onSubmitFilters} />

            <div className="card-body table-full-width">
              <TableWithLinks
                isLoading={isLoading}
                emptyTableMessage="Nenhum registro encontrado. Tente alterar os filtros."
                striped
                highlightOnHover
                columns={[
                  { field: 'received', label: 'Baixa', width: '65px' },
                  { field: 'CFO_RAZAOSOCIAL', label: 'Cliente ou Fornecedor' },
                  { field: 'ECC_DESCRICAO', label: 'Lançamento' },
                  { field: 'type', label: 'Tipo', alignment: 'text-left', width: '60px' },
                  { field: 'CBA_DESCRICAO', label: 'Banco', width: '140px' },
                  { field: 'FPA_DESCRICAO', label: 'Forma Pagamento', width: '100px' },
                  { field: 'quota', label: 'Parcela', width: '70px' },
                  { field: 'overdue', label: 'Vencimento', width: '95px' },
                  { field: 'value', label: 'Valor', width: '85px' },
                  { field: 'LAN_DATARECEBIDO', label: 'Data Pgto', width: '85px' },
                  { field: 'valueReceived', label: 'Valor Pgto', width: '85px' },
                ]}
                data={accountsPayableReceivable.map((finance) => ({
                  ...finance,
                  overdue: (
                    <span
                      style={
                        moment(finance.LAN_DATAVENCIMENTO, 'DD/MM/YYYY').valueOf() < new Date().getTime() ? { color: 'red' } : { color: 'green' }
                      }
                    >
                      {finance.dataVencimentoModal}
                    </span>
                  ),
                  type: (
                    <span style={{ fontWeight: 'bold', ...(finance.ECC_TIPO === 'R' ? { color: 'green' } : { color: 'red' }) }}>
                      {finance.ECC_TIPO === 'R' ? 'Receita' : 'Despesa'}
                    </span>
                  ),
                  received:
                    finance.LAN_DATARECEBIDO !== '' ? (
                      <span className="badge badge-green">
                        <i className="fas fa-check" /> Sim
                      </span>
                    ) : (
                      <span className="badge badge-blue">
                        <i className="fas fa-times" /> Não
                      </span>
                    ),
                  quota: (
                    <span className="badge badge-azure" style={{ fontSize: '85%' }}>
                      {finance.LAN_PARCELA}
                    </span>
                  ),
                  value: `${finance.ECC_TIPO === 'R' ? '(+)' : '(-)'}${finance.LAN_VALOR}`,
                  valueReceived: `${finance.ECC_TIPO === 'R' ? '(+)' : '(-)'}${finance.LAN_VALORRECEBIDO}`,
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewContasPagarReceber;
