import api from '~/config/api';

export const getParametersFranchise = async (emp_codigo) => {
  try {
    const { data } = await api.post('/api/parametros/carregar-parametros-franquia', { emp_codigo })
    return data
  } catch (error) {
    return { success: false, error }
  }
}

export const saveParametersFranchise = async (parameters) => {
  try {
    const { data } = await api.post('/api/parametros/salvar-parametros-franquia', parameters)
    return data
  } catch (error) {
    return { success: false, error }
  }
}