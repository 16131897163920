import React from 'react';
import { baseURL } from '~/config/api';
import { OfferSituation, OfferType } from '~/enum';
import { TableOffersToReleaseProps } from '~/types';
import { correctNumberWithComma, formatNumberToMoney, formatNumberToVPS, formatNumberWithoutPrefix } from '~/utils/util';

const TableOffersToRelease: React.FunctionComponent<TableOffersToReleaseProps> = ({ offers, isLoading, onSelectOffer }) => {
  return (
    <table className="table table-bigboy">
      <thead>
        <tr>
          <th className="text-center">Imagem</th>
          <th>Título</th>
          <th className="text-center">à combinar</th>
          <th className="text-center">Valor</th>
          <th className="text-center">Situação</th>
          <th className="text-center">Tipo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={6}>{/* <LoadingPointsSpin /> */}</td>
          </tr>
        ) : offers.length === 0 ? (
          <tr>
            <td colSpan={5}>
              <p className="text-color-default">Nenhuma oferta encontrada. Tente alterar os filtros.</p>
            </td>
          </tr>
        ) : (
          offers.map((offer, i) => (
            <tr key={'TableOffersToRelease-row' + i} className="clickable" onClick={() => onSelectOffer(offer)}>
              <td>
                <div className="img-container text-center mx-auto">
                  <img
                    src={
                      offer.OFE_IMAGEM !== ''
                        ? `${baseURL}/web/uploads/oferta/imagem/${offer.OFE_IMAGEM}`
                        : `${baseURL}/web/uploads/logoLoja/${offer.EMP_LOGOTIPO}`
                    }
                    alt={offer.OFE_TITULO}
                  />
                </div>
              </td>
              <td className="td-name"><span className='font-weight-bold'>{offer.OFE_TITULO}</span><small className='d-block mt-2'>({offer.EMP_NOMEFANTASIA})</small></td>
              <td className="text-center">{offer.OFE_ACOMBINAR === 'S' ? 'Sim' : 'Não'}</td>
              <td className="text-center">
                {+offer.OFE_VALOR === 0 ? (
                  'À Combinar'
                ) : offer.OFE_TIPO === OfferType.VP ? (
                  formatNumberToVPS(+correctNumberWithComma(offer.OFE_VALOR))
                ) : (
                  <>
                    {formatNumberToVPS(correctNumberWithComma(offer.OFE_VALOR))}
                    <br />
                    {formatNumberToMoney(correctNumberWithComma(offer.OFE_VALORVPMIX))}
                    <br />
                    <strong>Total</strong> {formatNumberWithoutPrefix(correctNumberWithComma(offer.OFE_VALORTOTAL))}
                  </>
                )}
              </td>
              <td className="text-center">
                {offer.OFE_STATUS === OfferSituation.ABERTA && 'Aberta'}
                {offer.OFE_STATUS === OfferSituation.BLOQUEADA && 'Bloqueada'}
                {offer.OFE_STATUS === OfferSituation.LIBERADA && 'Liberada'}
                {offer.OFE_STATUS === OfferSituation.PENDENTE && 'Pendente'}
                {offer.OFE_STATUS === OfferSituation.ENCERRADA && 'Encerrada'}
                {offer.OFE_STATUS === OfferSituation.REENVIADA && 'Reenviada'}
              </td>
              <td className="text-center">{offer.OFE_TIPO === OfferType.VP ? 'SC' : 'SCMIX'}</td>
              <td className="td-actions"></td>
            </tr>
          ))
        )}
        {/* <tr className="clickable" onClick={() => setOferta({ nome: 'teste' })} data-toggle="modal" data-target="#criarOferta">
          <td>
            <div className="img-container">
              <img src="../../assets/img/blog-1.jpg" alt="..." />
            </div>
          </td>
          <td className="td-name">Oferta 001</td>
          <td className="text-center">Não</td>
          <td className="td-number">1.200</td>
          <td className="text-left">Aberta</td>
          <td className="text-left">VPMIX</td>
          <td className="td-actions"></td>
        </tr>
        <tr className="clickable" onClick={() => setOferta({ nome: 'teste' })} data-toggle="modal" data-target="#criarOferta">
          <td>
            <div className="img-container">
              <img src="../../assets/img/blog-2.jpg" alt="..." />
            </div>
          </td>
          <td className="td-name">Oferta 002</td>
          <td className="text-center">Sim</td>
          <td className="td-number">à combinar</td>
          <td className="text-left">Aberta</td>
          <td className="text-left">VP</td>
          <td className="td-actions"></td>
        </tr>
        <tr className="clickable" onClick={() => setOferta({ nome: 'teste' })} data-toggle="modal" data-target="#criarOferta">
          <td>
            <div className="img-container">
              <img src="../../assets/img/blog-3.jpg" alt="..." />
            </div>
          </td>
          <td className="td-name">Oferta 003</td>
          <td className="text-center">Não</td>
          <td className="td-number">130.00</td>
          <td className="text-left">Aberta</td>
          <td className="text-left">VP</td>
          <td className="td-actions"></td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default TableOffersToRelease;
