import api, { defaultMessageError } from '~/config/api';

export const getFinances = async ({
  emp_codigo,
  pagina,
  limite,
  data_inicio,
  data_final,
  tipo_data,
  campo_pesquisa = '',
  baixados,
  tipoLancamento,
  coluna,
  direcao,
  orc_codigo = '',
}) => {
  try {
    const { data } = await api.post('/api/financas/listar', {
      emp_codigo,
      pagina,
      limite,
      data_inicio,
      data_final,
      tipo_data,
      campo_pesquisa,
      baixados,
      tipoLancamento,
      coluna,
      direcao,
      orc_codigo,
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const paginateFinances = async ({
  emp_codigo,
  pagina,
  limite,
  data_inicio,
  data_final,
  tipo_data,
  campo_pesquisa = '',
  baixados,
  tipoLancamento,
  coluna,
  direcao,
  orc_codigo = '',
}) => {
  try {
    const { data } = await api.post('/api/financas/paginar', {
      emp_codigo,
      pagina,
      limite,
      data_inicio,
      data_final,
      tipo_data,
      campo_pesquisa,
      baixados,
      tipoLancamento,
      coluna,
      direcao,
      orc_codigo,
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const autoCompleteFinances = async (text, emp_codigo) => {
  try {
    const { data } = await api.get('/api/financas/auto-complete-cliente', {
      params: {
        emp_codigo,
        q: text,
        limit: 150,
        timestamp: new Date().getTime(),
      },
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const saveFinance = async (finance) => {
  try {
    const { data } = await api.post('/api/financas/salvar', serializeFinanceToFormData(finance));
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

const serializeFinanceToFormData = (finance) => {
  const financeFormData = new FormData();
  const { newFiles, oldFiles, ...object } = finance;

  financeFormData.append('object', JSON.stringify(object));
  financeFormData.append(`oldFiles`, JSON.stringify({ oldFiles: oldFiles }));

  if (newFiles)
    newFiles.forEach((file, i) => {
      financeFormData.append(`newFile[${i}]`, file);
    });

  return financeFormData;
};

export const createProviderFinance = async (provider) => {
  try {
    const { data } = await api.post('/api/financas/salvar-cadastro-cliente', provider);
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const writeOffFinances = async (params) => {
  try {
    const { data } = await api.post('/api/financas/baixar', { lan_valorrecebido_receita: '0,00', lan_valorrecebido_despesa: '0,00', ...params });
    return data;
  } catch (error) {
    return { success: false, message: defaultMessageError, error };
  }
};

export const deleteFinance = async ({ lan_codigo, lan_numero, emp_codigo, change_type }) => {
  try {
    const { data } = await api.post('/api/financas/excluir', { lan_codigo, lan_numero, emp_codigo, change_type });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};