import React from 'react'

interface MensagemProps {
    color: string;
    title: string;
    description: string;
}

const Mensagem = (props: MensagemProps) => (
<div className={`alert alert-${props.color}`}>
    <span>
        <b>{props.title}</b> {props.description}
    </span>
</div>
)

export default Mensagem