import React, { useContext, useEffect, useState } from 'react';

import ViewModalUser from '~/views/ModalUser';
import ViewModalFilterUsers from '~/views/ModalFilterUsers';
import { closeModal, openModal } from '~/utils/util';
import { FormUser, Profile, User } from '~/types';
import { AuthContext } from '~/context/AuthContext';
import { getAllUsers, saveUser } from '~/service/UserService';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import ViewCadastroUsuarios from '~/views/cadastroUsuarios';
import moment from 'moment';

const CadastroUsuarios: React.FunctionComponent = () => {
  const { user: usuario, company: empresa } = useContext(AuthContext);

  const [user, setUser] = useState<User | undefined>(undefined);
  const [users, setUsers] = useState<User[]>([]);
  const [profile, setProfile] = useState<Profile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState('');

  useEffect(() => {
    fetchAllUsers([1, 2, 3]);
  }, []);

  const fetchAllUsers = async (itiCodigos?: number[]) => {
    setIsLoading(true);
    const { success, payload, error } = await getAllUsers(empresa.EMP_CODIGO, itiCodigos);
    setIsLoading(false);

    if (success) {
      const { usuarios, perfis } = payload;
      setUsers(usuarios);
      setProfile(perfis);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const openModalUserAdd = () => {
    openModal('ModalUSer');
    setUser(undefined);
  };
  const openModalUserEdit = (user: User) => {
    openModal('ModalUSer');
    setUser(user);
  };

  const openModalUserFilter = (ModalFilterId: string) => openModal(ModalFilterId);

  const handleOnSubmit = async (formUser: FormUser) => {
    setIsAwaitResponse(true);
    const { success, payload, message } = await saveUser({
      ...formUser,
      usu_dataaniver: moment(formUser.usu_dataaniver).format('DD/MM/YYYY'),
      usu_codigo: user?.USU_CODIGO,
      emp_codigo: empresa.EMP_CODIGO,
      current_usu_codigo: usuario.USU_CODIGO.toString(),
      current_gru_codigo: usuario.GRU_CODIGO,
    });
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalUSer');
      await fetchAllUsers([1, 2, 3]);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
    }
  };

  return (
    <ViewCadastroUsuarios
      headerConfig={{ title: 'Gerenciar Usuários', searchBoxState: { searchBoxValue, setSearchBoxValue } }}
      ModalUser={<ViewModalUser user={user} profiles={profile} handleOnSubmit={handleOnSubmit} isAwaitResponse={isAwaitResponse} />}
      ModalFilterUsers={<ViewModalFilterUsers onSubmit={fetchAllUsers} />}
      user={user}
      users={users}
      profile={profile}
      isLoading={isLoading}
      openModalUserAdd={openModalUserAdd}
      openModalUserEdit={openModalUserEdit}
      openModalUserFilter={openModalUserFilter}
    />
  );
};

export default CadastroUsuarios;
