import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ModalLoginPaymentForm, ModalLoginPaymentProps } from '~/types';
import ControlledSelect from '~/components/input/ControlledSelect';
import BasicInput from '~/components/input/BasicInput';
import BasicTextarea from '~/components/input/BasicTextarea';

const paymentOptions = [
  { value: 'Boleto', label: 'Boleto' },
  { value: 'Cartão', label: 'Cartão' },
];

const ModalLoginPayment: React.FunctionComponent<ModalLoginPaymentProps> = ({ offer, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema: yup.SchemaOf<ModalLoginPaymentForm> = yup.object().shape({
    usu_login: yup.string().required(),
    usu_senha: yup.string().required(),
    fpa_descricao: yup.string().when('$ofe_acombinar', { is: 'N', then: yup.string().required() }),
    one_descricao: yup.string().when('$ofe_acombinar', { is: 'S', then: yup.string().required() }),
  });

  const { handleSubmit, errors, control, register, reset } = useForm<ModalLoginPaymentForm>({
    resolver: yupResolver(validationSchema),
    context: { ofe_acombinar: offer.OFE_ACOMBINAR },
  });

  const HandleonSubmit = async (formInput: ModalLoginPaymentForm) => {
    setIsLoading(true);
    const hasReset = await onSubmit(formInput);
    if (hasReset) reset();
    setIsLoading(false);
  };

  return (
    <div className="modal fade" id="ModalLoginPayment" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document" style={{ paddingTop: '100px' }}>
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Solicitação de orçamento</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form id="genericFormId" onSubmit={handleSubmit(HandleonSubmit)}>
                      <BasicInput
                        label="Login"
                        isRequired
                        hasError={!!errors.usu_login}
                        control={control}
                        defaultValue=""
                        name="usu_login"
                        type="text"
                      />
                      <BasicInput
                        label="Senha"
                        isRequired
                        hasError={!!errors.usu_senha}
                        control={control}
                        defaultValue=""
                        type="password"
                        name="usu_senha"
                      />
                      {offer.OFE_ACOMBINAR === 'S' ? (
                        <BasicTextarea
                          label="Descrição da resposta ou pergunta..."
                          name="one_descricao"
                          ref={register}
                          hasError={!!errors.one_descricao}
                          placeholder="Descreva brevemente sua dúvida ou pergunta..."
                          defaultValue=""
                          height="120px"
                        />
                      ) : (
                        <ControlledSelect
                          label="Forma de pagamento"
                          name="fpa_descricao"
                          control={control}
                          defaultValue={''}
                          isRequired
                          hasError={!!errors.fpa_descricao}
                          placeholder="Escolha..."
                          options={paymentOptions}
                          isLoading={isLoading}
                        />
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" form="genericFormId" disabled={isLoading}>
              {isLoading ? (
                <>
                  <i className="fas fa-spinner rotating" /> Processando...
                </>
              ) : (
                <>
                  <i className="fa fa-search"></i> Enviar
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLoginPayment;
