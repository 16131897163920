import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { Controller, useForm } from 'react-hook-form';
import { FormFinanceSearchParams, ModalFilterFinanceProps } from '~/types';
import BasicInput from '~/components/input/BasicInput';
import { autoCompleteFinances } from '~/service/financeiro/FinanceService';
import { AuthContext } from '~/context/AuthContext';
import { closeModal, formatCNPJ, formatCPF } from '~/utils/util';
import SelectControlled from '~/components/input/SelectControlled';
import moment from 'moment';

const ModalFilterFinance: React.FunctionComponent<ModalFilterFinanceProps> = ({ onSubmit }) => {
  const { company: empresa } = useContext(AuthContext);
  const { control, handleSubmit } = useForm<FormFinanceSearchParams>({});

  const handleOnSubmit = async (params: FormFinanceSearchParams) => {
    onSubmit(params);
    closeModal('ModalFilterFinance');
  };

  return (
    <div className="modal fade" id="ModalFilterFinance" role="dialog" aria-labelledby="Modal-Filter" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Buscar cobranças...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="ModalFilterFinanceId">
                      <div className="form-group">
                        <label>Nome fantasia</label>
                        <Controller
                          name="campo_pesquisa"
                          control={control}
                          defaultValue={''}
                          render={({ onChange, value }) => (
                            <AsyncSelect
                              className="react-select-container"
                              classNamePrefix="react-select"
                              cacheOptions
                              noOptionsMessage={() => 'Digite para pesquisar'}
                              placeholder="Pesquise por nome, telefone ou cpf"
                              isClearable
                              loadOptions={async (value, callback) => {
                                const response: string = await autoCompleteFinances(value, empresa.EMP_CODIGO);
                                callback(
                                  response
                                    .split('\n')
                                    .filter((r) => r !== '')
                                    .map((row: string) => {
                                      const data = row.split('|');
                                      return {
                                        value: data[0],
                                        label: `${data[0]} ${
                                          data.length >= 3 ? `(${data[2].length === 14 ? formatCNPJ(data[2]) : formatCPF(data[2])})` : ''
                                        }`,
                                      };
                                    })
                                );
                              }}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>

                      <BasicInput
                        control={control}
                        type="number"
                        defaultValue={''}
                        name="orc_codigo"
                        label="Orçamento"
                        placeholder="Número do orçamento/transação"
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={'T'}
                        options={[
                          { value: 'T', label: 'Receita e Despesa' },
                          { value: 'R', label: 'Apenas Receita' },
                          { value: 'D', label: 'Apenas Despesa' },
                        ]}
                        name="tipoLancamento"
                        label="Tipo"
                        placeholder="Tudo"
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={'S'}
                        options={[
                          { value: 'S', label: 'Sim' },
                          { value: 'N', label: 'Não' },
                        ]}
                        name="baixados"
                        label="Mostrar baixados?"
                      />

                      <SelectControlled
                        control={control}
                        defaultValue={'LAN_DATAVENCIMENTO'}
                        options={[
                          { value: 'LAN_DATAVENCIMENTO', label: 'Vencimento' },
                          { value: 'LAN_DATARECEBIDO', label: 'Pagamento' },
                          { value: 'LAN_DATAGERACAO', label: 'Emissão' },
                        ]}
                        name="tipo_data"
                        label="Data por"
                      />

                      <BasicInput
                        control={control}
                        type="date"
                        defaultValue={moment().subtract(3, 'd').format('YYYY-MM-DD')}
                        name="data_inicio"
                        label="Data início"
                      />

                      <BasicInput
                        control={control}
                        type="date"
                        defaultValue={moment().add(3, 'd').format('YYYY-MM-DD')}
                        name="data_final"
                        label="Data fim"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-default btn-wd" form="ModalFilterFinanceId" type="submit">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterFinance;
