import React from 'react';
import { PageLoadingProps } from '~/types';
import './styles.css';

const PageLoading: React.FunctionComponent<PageLoadingProps> = ({ center }) => {
  return (
    <>
      <div>
        <div className="loader-page-points-spin" style={{ right: center ? '50%' : undefined }}>
          Loading...
        </div>
      </div>
      <div className="loading-backdrop" />
    </>
  );
};

export default PageLoading;
