import React from 'react';
import LoadingSpin from '~/components/loading/LoadingSpin';
import { NegotiationSituation } from '~/enum';
import { ModalNegotiationHistoryProps } from '~/types';
import { correctNumberWithComma, formatNumberToVPS } from '~/utils/util';

const ModalNegotiationHistory: React.FunctionComponent<ModalNegotiationHistoryProps> = ({ isLoading, history }) => {
  return (
    <div className="modal fade" id="verHistorico" role="dialog" aria-labelledby="verHistoricoLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Histórico desta Negociação</h4>
                  </div>
                  <div className="card-body ">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-left">Responsável</th>
                          <th className="text-center">Data</th>
                          <th className="text-center">hora</th>
                          <th className="text-left">Pergunta ou Resposta</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={6}>
                              <LoadingSpin />
                            </td>
                          </tr>
                        ) : history.length === 0 ? (
                          <tr>
                            <td colSpan={6}>
                              <p className="text-color-default">Histórico vazio.</p>
                            </td>
                          </tr>
                        ) : (
                          history.map((item) => (
                            <tr key={item.ONE_CODIGO}>
                              <td className="text-left">{item.EMP_NOMEFANTASIA}</td>
                              <td className="text-center">{item.ONE_DATA.substr(0, 10)}</td>
                              <td className="text-center">{item.ONE_DATA.substr(10, 6)}</td>
                              <td className="text-left">{item.ONE_DESCRICAO}</td>
                              <td className="text-center">
                                {item.ONE_STATUS === NegotiationSituation.Fechado && 'Fechado'}
                                {item.ONE_STATUS === NegotiationSituation.Negociando && 'Em Negociação'}
                                {item.ONE_STATUS === NegotiationSituation.Recusado && 'Recusado'}
                              </td>
                              <td className="text-center">
                                {item.ONE_VALOR === ''
                                  ? 0
                                  : item.ONE_VALOR === '0'
                                  ? '(N/I)'
                                  : formatNumberToVPS(+correctNumberWithComma(item.ONE_VALOR))}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNegotiationHistory;
