import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormCreateProvider, ModalCreateProviderProps } from '~/types';
import BasicInput from '~/components/input/BasicInput';
import { AuthContext } from '~/context/AuthContext';
import { addJQueryListener, closeModal, jQueryChangeCSS } from '~/utils/util';
import InputMaskControlled from '~/components/input/InputMaskControlled';
import MaskPhoneControlled from '~/components/input/MaskPhoneControlled';
import CheckBoxControlled from '~/components/input/CheckBoxControlled';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import { createProviderFinance } from '~/service/financeiro/FinanceService';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';

const CreateProviderSchema: yup.SchemaOf<FormCreateProvider> = yup.object().shape({
  cfo_cpfcnpj_modal: yup.string(),
  cfo_razaosocial_modal: yup.string().required(),
  cfo_nomefantasia_modal: yup.string(),
  cfo_contato_modal: yup.string(),
  cfo_telefone_modal: yup.string(),
  cfo_email_modal: yup.string(),
  cfo_cliente_modal: yup.boolean(),
  cfo_fornecedor_modal: yup.boolean(),
});

const ModalCreateProvider: React.FunctionComponent<ModalCreateProviderProps> = ({}) => {
  const { company } = useContext(AuthContext);
  const { control, handleSubmit, errors, watch } = useForm<FormCreateProvider>({
    resolver: yupResolver(CreateProviderSchema),
  });

  useEffect(() => {
    $(function () {
      addJQueryListener('ModalCreateProvider', 'show.bs.modal', () => jQueryChangeCSS('ModalFinance', 'z-index', 1039));
      addJQueryListener('ModalCreateProvider', 'hidden.bs.modal', () => jQueryChangeCSS('ModalFinance', 'z-index', 1050));
    });

    return () => {
      $('#ModalCreateProvider').off(undefined);
    };
  }, []);

  const handleOnSubmit = async (params: FormCreateProvider) => {
    const { success, error, message } = await createProviderFinance({
      emp_codigo: company.EMP_CODIGO,
      cfo_cpfcnpj_modal: params.cfo_cpfcnpj_modal,
      cfo_razaosocial_modal: params.cfo_razaosocial_modal,
      cfo_nomefantasia_modal: params.cfo_nomefantasia_modal,
      cfo_contato_modal: params.cfo_contato_modal,
      cfo_telefone_modal: params.cfo_telefone_modal,
      cfo_email_modal: params.cfo_email_modal,

      ...(params.cfo_cliente_modal ? { cfo_cliente_modal: 'S' } : undefined),
      ...(params.cfo_fornecedor_modal ? { cfo_fornecedor_modal: 'S' } : undefined),
    });

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalCreateProvider');
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalCreateProvider"
      role="dialog"
      aria-labelledby="Modal-Filter"
      aria-hidden="true"
      tabIndex={-1}
      style={{ zIndex: 1052 }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Buscar cobranças...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="ModalCreateProviderId">
                      <BasicInput
                        isRequired
                        control={control}
                        type="text"
                        defaultValue={''}
                        hasError={!!errors.cfo_razaosocial_modal}
                        name="cfo_razaosocial_modal"
                        label="Razão Social ou Nome"
                      />

                      <BasicInput
                        control={control}
                        type="text"
                        defaultValue={''}
                        hasError={!!errors.cfo_nomefantasia_modal}
                        name="cfo_nomefantasia_modal"
                        label="Nome Fantasia / Abreviado"
                      />

                      <InputMaskControlled
                        control={control}
                        defaultValue={''}
                        hasError={!!errors.cfo_cpfcnpj_modal}
                        name="cfo_cpfcnpj_modal"
                        label="CNPJ ou CPF"
                        placeholder={watch('emp_cpfcnpj', '').substr(8, 3) === '000' ? '__. ___. ___/____-__' : '___.___.___-__'}
                        format={watch('emp_cpfcnpj', '').substr(8, 3) === '000' ? '##. ###. ###/####-##' : '###.###.###-##'}
                        mask="_"
                        isNumericString
                      />

                      <MaskPhoneControlled
                        control={control}
                        defaultValue={''}
                        hasError={!!errors.cfo_contato_modal}
                        name="cfo_contato_modal"
                        label="Contato"
                      />

                      <MaskPhoneControlled
                        control={control}
                        defaultValue={''}
                        hasError={!!errors.cfo_telefone_modal}
                        name="cfo_telefone_modal"
                        label="Telefone"
                      />

                      <BasicInput
                        control={control}
                        type="email"
                        defaultValue={''}
                        hasError={!!errors.cfo_email_modal}
                        name="cfo_email_modal"
                        label="Email"
                      />

                      <CheckBoxControlled control={control} defaultValue={true} label="Cliente" name="cfo_cliente_modal" />

                      <CheckBoxControlled control={control} defaultValue={false} label="Fornecedor" name="cfo_fornecedor_modal" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-default btn-wd" form="ModalCreateProviderId" type="submit">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateProvider;
