import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import Dropzone from 'react-dropzone';
import SelectControlled from '~/components/input/SelectControlled';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ModalFilterUsersProps } from '~/types';
import { closeModal } from '~/utils/util';

const animatedComponents = makeAnimated();

interface FilterUser {
  itiCodigos: number[];
}

const status = [
  {
    value: 1,
    label: 'Ativo',
  },
  {
    value: 2,
    label: 'Bloqueado',
  },
  {
    value: 3,
    label: 'Inativo',
  },
];

const ViewModalFilterUsers: React.FunctionComponent<ModalFilterUsersProps> = ({ onSubmit }) => {
  const { watch, control, handleSubmit, errors } = useForm<FilterUser>({});

  const handleOnSubmit = async ({ itiCodigos }: FilterUser) => {
    closeModal('ModalFilterUsers');
    await onSubmit(itiCodigos);
  };

  return (
    <div className="modal fade" id="ModalFilterUsers" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Buscar usuários...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit)} id="ModalFilterUsersFormId">
                      <SelectControlled
                        isRequired
                        isMulti
                        control={control}
                        defaultValue={[1, 2, 3]}
                        options={status}
                        hasError={!!errors.itiCodigos}
                        name="itiCodigos"
                        label="Situação"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button form="ModalFilterUsersFormId" type="submit" className="btn btn-default btn-wd">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModalFilterUsers;
