import React, {  } from 'react';
import { ModalFilterAssociatesProps } from '~/types';
import SelectControlled from '~/components/input/SelectControlled';
import { closeModal } from '~/utils/util';
import { useForm } from 'react-hook-form';

const statusOptions = [
  {
    value: 4,
    label: 'Ativo',
  },
  {
    value: 6,
    label: 'Bloqueado',
  },
  {
    value: 5,
    label: 'Inativo',
  },
/*   {
    value: 7,
    label: 'Análise',
  },
  {
    value: 8,
    label: 'Trava de Segurança',
  }
  }, */
];

interface FormFilter {
  itiCodigos: number[];
}

const ModalFilterAssociates: React.FunctionComponent<ModalFilterAssociatesProps> = ({ onSubmit }) => {
  const { control, handleSubmit } = useForm<FormFilter>({});

  const handleOnSubmit = async (formFilter: FormFilter) => {
    closeModal('filtro');
    await onSubmit(formFilter.itiCodigos);
  };

  return (
    <div className="modal fade" id="filtro" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Buscar usuários...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="genericFormFilterId">
                      <div className="col-md-12">
                        <SelectControlled
                          placeholder="Todas"
                          isMulti
                          control={control}
                          defaultValue={[4]}
                          options={statusOptions}
                          name="itiCodigos"
                          label="Situação"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left" /> Voltar
            </button>
            <button type="submit" form="genericFormFilterId" className="btn btn-default btn-wd">
              <i className="fa fa-search" /> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterAssociates;
