import React, { useContext, useEffect, useState } from 'react';

import logoFiltro from '~/assets/filtro_2.png';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import ModalFilter from './ModalFilter';
import ModalOfferDetails from '~/components/modal/ModalOfferDetails';
import { Offer } from '~/types';
import { useErrorHandler } from 'react-error-boundary';
import { openModal } from '~/utils/util';
import { AuthContext } from '~/context/AuthContext';
import { matchSorter } from 'match-sorter';
import TableOffers from '~/components/table/TableOffers';
import PageLoading from '~/components/loading/PageLoading';
import { getOffers } from '~/service/OfferService';
import ViewVerOfertas from '~/views/verOfertas';
import ModalFilterAvailableOffers from '~/views/ModalFilterAvailableOffers';

const VerOfertas: React.FunctionComponent = () => {
  const handleError = useErrorHandler();

  const { user: usuario, company: empresa } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [offer, setOffer] = useState({} as Offer);
  const [offers, setOffers] = useState([] as Array<Offer>);
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const [filteredOffers, setFilteredOffers] = useState([] as Array<Offer>);

  const [filters, setFilters] = useState({
    filterSegment: [] as Array<{ value: string }>,
    filterCity: [] as Array<{ value: string }>,
    filterFranchise: { value: empresa.EMP_CODIGO },
    filterVP: 'PADRAO',
    filterVPMIX: 'VPMIX',
  });

  useEffect(() => {
    try {
      fetchOffers();
    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    setFilteredOffers([...matchSorter(offers, searchBoxValue, { keys: ['OFE_TITULO', 'EMP_NOMEFANTASIA'] })]);
  }, [searchBoxValue, offers]);

  const fetchOffers = async () => {
    setIsLoading(true);

    const payload = await getOffers({
      seg_codigo:
        filters.filterSegment.length === 0 ? null : `(${filters.filterSegment.map((f) => f.value).toString()})`,
      len_cidibge: filters.filterCity.length === 0 ? null : `(${filters.filterCity.map((f) => f.value).toString()})`,
      emp_codigo: filters.filterFranchise.value,
      emp_codaux: filters.filterFranchise.value,
      ofe_tipo: `('${filters.filterVP}', '${filters.filterVPMIX}')`,
    });
    if (!payload || !payload.ofertas || payload.ofertas.length === 0) {
      setOffers([]);
      setIsLoading(false);
      return;
    }
    payload.ofertas.forEach((f: Offer) => f.OFE_VALOR.includes(','));

    const offersSorted = payload.ofertas;

    setOffers(offersSorted);

    setIsLoading(false);
  };

  const onSelectOffer = (offer: Offer) => {
    setOffer(offer);
    openModal('detalhesOferta');
  };

  return (
    <ViewVerOfertas
      isLoading={isLoading}
      ModalFilter={
        <ModalFilterAvailableOffers
          fetchOffers={fetchOffers}
          filtersState={{ filters: filters, setFilters: setFilters }}
        />
      }
      ModalOfferDetails={<ModalOfferDetails offer={offer} />}
      offers={filteredOffers}
      onSelectOffer={onSelectOffer}
      headerConfig={{
        title: 'Ofertas disponíveis',
        searchBoxState: { searchBoxValue: searchBoxValue, setSearchBoxValue: setSearchBoxValue },
      }}
    />
    // <div className="wrapper">
    //   <Menu />

    //   <div className="main-panel">
    //     <Header title="Ofertas disponíveis" searchBoxState={{ searchBoxValue: searchBoxValue, setSearchBoxValue: setSearchBoxValue }} />

    //     <div className="content">
    //       {isLoading && <PageLoading />}
    //       <div className="container-fluid">
    //         <div className="row">
    //           <div className="col-md-12">
    //             <div className="card table-big-boy">
    //               <div className="card-header">
    //                 <h4 className="card-title">
    //                   Lista de ofertas disponíveis{' '}
    //                   <img src={logoFiltro} style={{ width: 30, height: 30, cursor: 'pointer' }} data-toggle="modal" data-target="#filtro" />
    //                 </h4>
    //                 <p className="card-category">Aplique os filtros por segmentos, cidades, franquias</p>
    //                 <br />
    //               </div>

    //               <ModalOfferDetails offer={offer} />
    //               <ModalFilter fetchOffers={fetchOffers} filtersState={{ filters: filters, setFilters: setFilters }} />

    //               <div className="card-body table-full-width">
    //                 <TableOffers offers={filteredOffers} isLoading={isLoading} onSelectOffer={onSelectOffer} enableShareLink />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <Footer />
    //   </div>
    // </div>
  );
};

export default VerOfertas;
