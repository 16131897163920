import React, { useState, Fragment, useEffect } from 'react';
import { TableWithLinksProps } from '~/types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LoadingSpin from '~/components/loading/LoadingSpin';
import LoadingPointsSpin from '~/components/loading/LoadingPointsSpin';

const TableWithLinks: <T>(props: TableWithLinksProps<T>) => JSX.Element = ({
  isLoading,
  emptyTableMessage,
  tableBigBoy,
  sortState,
  responsive,
  boldRow,
  columns,
  data,
  actions,
  highlightOnHover,
  striped,
  highlightRow,
  tooltipInRow,
  onClickingRow,
  checkbox,
}) => {
  const nextSortOrderBY = (orderBy: 'ASC' | 'DESC'): 'ASC' | 'DESC' => (orderBy === 'DESC' ? 'ASC' : 'DESC');

  const table: JSX.Element = (
    <table
      className={`table ${tableBigBoy ? 'table-bigboy' : ''} ${striped && data.length > 0 ? 'table-striped' : ''} ${
        highlightOnHover ? 'table-highlight-hover' : ''
      }`}
    >
      <colgroup>
        {checkbox && <col width="30px" />}
        {columns.map(({ width }, i) => (
          <col key={i + 'TableWithActions-colgroup'} width={width ? width : ''} />
        ))}
        {actions && <col width={`${actions.length * 28 + (tableBigBoy ? 0 : 15)}px`} />}
      </colgroup>

      <thead>
        <tr>
          {checkbox && checkbox.onCheckAll && (
            <th className="px-2 text-center">
              <input
                type="checkbox"
                onChange={(e) => checkbox.onCheckAll && checkbox.onCheckAll(e.target.checked, data)}
              />
            </th>
          )}
          {checkbox && !checkbox.onCheckAll && <th></th>}
          {columns.map(({ label, field, alignment, sort }, i) => (
            <th
              key={i + 'TableWithActions-thead'}
              className={`${alignment || ''} ${sortState && sort ? 'clickable' : ''}`}
              onClick={() =>
                sortState &&
                sort &&
                sort({
                  column: i,
                  columnName: field as string,
                  orderBy: nextSortOrderBY(sortState.column !== i ? 'DESC' : sortState.orderBy),
                })
              }
            >
              {label}{' '}
              {sortState && sort && (
                <i
                  className={`${
                    sortState.column === i && sortState.orderBy === 'ASC'
                      ? 'fas fa-sort-up'
                      : sortState.column === i && sortState.orderBy === 'DESC'
                      ? 'fas fa-sort-down'
                      : 'fas fa-sort'
                  }`}
                />
              )}
            </th>
          ))}
          {actions && <th></th>}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={actions ? columns.length + 1 : columns.length} style={{ display: 'table-cell', height: 10 }}>
              <LoadingSpin />
            </td>
          </tr>
        ) : data.length === 0 ? (
          <tr>
            <td colSpan={actions ? columns.length + 1 : columns.length} style={{ display: 'table-cell' }}>
              <p className="text-color-default">{emptyTableMessage}</p>
            </td>
          </tr>
        ) : (
          data.map((row, i) => {
            const rowElement: JSX.Element = (
              <tr
                key={i + 'TableWithActions-row-data'}
                className={`${boldRow && boldRow(row) ? 'bold-row' : ''}`}
                style={{ backgroundColor: highlightRow && highlightRow(row) ? '#ffa534' : '' }}
              >
                {checkbox && (
                  <td className="px-2 text-center">
                    <input
                      type="checkbox"
                      checked={checkbox.isChecked ? checkbox.isChecked(row) : undefined}
                      onChange={(e) => checkbox.onCheck(e.target.checked, row)}
                    />
                  </td>
                )}
                {columns.map(({ field, alignment, disableClick }, j) => (
                  <td
                    key={j + 'TableWithActions-column-data'}
                    className={`${alignment || ''} ${onClickingRow && !disableClick ? 'clickable' : ''}`}
                    onClick={(event) => (onClickingRow && !disableClick ? onClickingRow(row, event) : undefined)}
                    style={{ padding: tableBigBoy ? '' : j === 0 ? '7px 3px 7px 15px' : '7px 3px' }}
                  >
                    {Array.isArray(row[field])
                      ? (row[field] as any).map((value: any, index: number) =>
                          index === 0 ? (
                            <Fragment key={'TableWithActions-column-data-item' + index}>{value}</Fragment>
                          ) : (
                            <Fragment key={'TableWithActions-column-data-item' + index}>
                              <br />
                              {value}
                            </Fragment>
                          )
                        )
                      : row[field]}
                  </td>
                ))}

                {actions && (
                  <td style={{ padding: tableBigBoy ? '' : '7px 15px 7px 3px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {actions.map(({ iconClass, title, style, className, variant, action }, i) => (
                        <i
                          key={'TableWithActions-column-actions' + i}
                          className={`${!!className ? className : ''} ${
                            !!variant ? 'text-' + variant : ''
                          } ${iconClass}`}
                          title={title}
                          style={{ fontSize: 18, cursor: 'pointer', ...style }}
                          onClick={(event) => action(row, event)}
                        />
                      ))}
                    </div>
                  </td>
                )}
              </tr>
            );

            const tooltipData = tooltipInRow !== undefined ? tooltipInRow(row) : undefined;
            if (tooltipData) {
              return (
                <OverlayTrigger
                  placement={'top'}
                  overlay={<Tooltip id={`TableWithActions-row-data-tooltip-${i}`}>{tooltipData}</Tooltip>}
                >
                  {rowElement}
                </OverlayTrigger>
              );
            } else {
              return rowElement;
            }
          })
        )}
      </tbody>
    </table>
  );
  return responsive ? <div className="table-responsive">{table}</div> : table;
};

export default TableWithLinks;
