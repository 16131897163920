import React, { ReactNode, useContext, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { AuthContext } from '../context/AuthContext';
import { EmpType } from '~/enum';
import SweetAlert from 'react-bootstrap-sweetalert';

interface PrivateRoutes {
  children: ReactNode;
  allowedUser: Array<EmpType>;
  path: string;
}

const PrivateRoutes: React.FunctionComponent<PrivateRoutes> = (props) => {
  const { isAuthenticated, company: empresa } = useContext(AuthContext);
  const allowedRoutes = ['/associado/dashboard', '/associado/extrato-conta'];
  if (empresa.ITI_CODIGO === '8' && !allowedRoutes.includes(window.location.pathname)) {
    return (
      <SweetAlert
        warning
        title="Atenção!"
        onConfirm={() => {
          window.location.href = allowedRoutes[0];
        }}
        onCancel={() => {
          window.location.href = '/';
        }}
        allowEscape={true}
        showCancel
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Ok"
        cancelBtnText="Sair"
      >
        <>
          Você não possui acesso a esta funcionalidade no momento.
          <br />
          Entre em contato com a administração do sistema para mais informações.
        </>
      </SweetAlert>
    );
  }
  return (
    <Route
      path={props.path}
      render={() => {
        if (!isAuthenticated()) return <Redirect to="/" />;

        if (props.allowedUser.some((u) => u === empresa.EMP_TIPO)) return props.children;
        else return <Redirect to="/nao-autorizado" />;
      }}
    />
  );
};

export default PrivateRoutes;
