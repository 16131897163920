import axios from 'axios';

const defaultMessageError = 'Erro interno. Por favor, tente novamente.';

const baseURLPRO = 'https://adm.socicoin.laks.net.br';
const baseURLHMG = 'https://adm.socicoin.laks.net.br';

const baseURL =
  // baseURLHMG
  // baseURLPRO
  location.hostname === 'localhost' ||
  location.hostname === '127.0.0.1' ||
  location.hostname === '' ||
  location.hostname === 'hmg-franquiavp.laks.net.br' ||
  location.port !== ''
    ? baseURLPRO
    : baseURLPRO;

const api = axios.create({
  baseURL,
});

api.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

export default api;

export { baseURL, defaultMessageError };
