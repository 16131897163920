import React, { useContext, useEffect, useState } from 'react';

import logoFiltro from '../../../assets/filtro_2.png';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Menu from '../../../components/menu';
import ModalFilter from './ModalFilter';
import { Associate, ConfigVPMIX, GoodOffered, Offer, PaymentOption } from '~/types';
import { getMyOffersFilterData, getOfferToRelease, getOfferToReleaseFilterData } from '~/service/OfferService';
import { useErrorHandler } from 'react-error-boundary';
import { AuthContext } from '~/context/AuthContext';
import TableOffersToRelease from '~/components/table/TableOffersToRelease';
import { openModal } from '~/utils/util';
import ModalCreateOffer from '~/components/modal/ModalCreateOffer/ModalCreateOffer';
import PageLoading from '~/components/loading/PageLoading';
import { matchSorter } from 'match-sorter';

const LiberarOfertas: React.FC = () => {
    const handleError = useErrorHandler();

  const { user: usuario, company: empresa } = useContext(AuthContext);

  const [configModal, setConfigModal] = useState({} as { goodsOffered: GoodOffered[]; associates: Associate[] });
  const [configModalOffer, setConfigModalOffer] = useState(
    {} as {} as { config_vpmix: ConfigVPMIX; formasPgto: PaymentOption[]; segmentos: GoodOffered[] }
  );
  const [offer, setOffer] = useState<Offer | null>(null);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<Offer[]>([])
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModal, setIsLoadingModal] = useState(true);
  const [isLoadingModalOffer, setIsLoadingModalOffer] = useState(true);
  const [filters, setFilters] = useState({
    filterGoodsOffered: '',
    filterAssociate: [] as Array<{ value: string }>,
    filterWithValue: true,
    filterToCombine: true,
    filterVP: 'PADRAO',
    filterVPMIX: 'VPMIX',
  });

  useEffect(() => {
    fetchOffersToRelease();
    fetchOffersToReleaseFilterData();
  }, []);

  useEffect(() => {
    setFilteredOffers([...matchSorter(offers, searchBoxValue, { keys: ['OFE_TITULO', 'EMP_NOMEFANTASIA', 'EMP_RAZAOSOCIAL'] })]);
  }, [searchBoxValue, offers]);

  const fetchOffersToReleaseFilterData = async () => {
    try {
      setIsLoadingModal(true);

      const { payload } = await getOfferToReleaseFilterData(empresa.EMP_CODIGO);

      setConfigModal({ goodsOffered: payload.segmentos, associates: payload.associados });
      setIsLoadingModal(false);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchOffersToRelease = async () => {
    try {
      setIsLoading(true);

      const { payload } = await getOfferToRelease({
        emp_codigo: empresa.EMP_CODIGO,
        seg_codigo: filters.filterGoodsOffered,
        emp_codigos: filters.filterAssociate.length === 0 ? '' : `(${filters.filterAssociate.map((f) => f.value).toString()})`,
        ofe_tipo: `('${filters.filterVP}', '${filters.filterVPMIX}')`,
        com_valor: filters.filterWithValue,
        a_combinar: filters.filterToCombine,
      });

      setOffers(payload.ofertas);

      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchOfferData = async (emp_codigo: string) => {
    try {
      setIsLoadingModalOffer(true);
      setIsLoading(true);

      const { payload } = await getMyOffersFilterData(emp_codigo);

      setConfigModalOffer(payload);

      setIsLoadingModalOffer(false);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleOnSelectOffer = async (offer: Offer) => {
    await fetchOfferData(offer.EMP_CODIGO);
    openModal('criarOferta');
    setOffer(offer);
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Liberar Ofertas" searchBoxState={{ searchBoxValue: searchBoxValue, setSearchBoxValue: setSearchBoxValue }}/>

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card table-big-boy">
                  <div className="card-header">
                    <h4 className="card-title">
                      Liberar Ofertas{' '}
                      <img src={logoFiltro} style={{ width: 30, height: 30, cursor: 'pointer' }} data-toggle="modal" data-target="#filtro" />
                    </h4>
                    <p className="card-category" data-toggle="modal" data-target="#filtro">
                      Aplique os filtros acima e encontre a oferta
                    </p>
                  </div>

                  {/* <ModalLiberarOferta data={offers} /> */}
                  {/* <ModalFiltro /> */}

                  <ModalCreateOffer
                    isLoading={isLoadingModalOffer}
                    config={configModalOffer}
                    offer={offer}
                    fetchMyOffers={fetchOffersToRelease}
                    onConfirm={setOffer}
                  />

                  <ModalFilter
                    isLoading={isLoadingModal}
                    config={configModal}
                    filtersState={{ filters: filters, setFilters: setFilters }}
                    fetchOffersToRelease={fetchOffersToRelease}
                  />

                  <div className="card-body table-full-width">
                    <TableOffersToRelease offers={filteredOffers} isLoading={false} onSelectOffer={handleOnSelectOffer} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default LiberarOfertas;
