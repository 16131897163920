import api from '~/config/api';

export const getFilterData = async () => {
  try {
    const { data } = await api.get('/api/ofertas/dados-filtro');
    return data;
  } catch (error) {
    return error;
  }
};

export const getOffers = async ({ seg_codigo, len_cidibge, emp_codigo, emp_codaux, ofe_tipo }) => {
  try {
    const { data } = await api.post('/api/ofertas/carregar-ofertas', {
      seg_codigo,
      len_cidibge,
      emp_codigo,
      emp_codaux,
      ofe_tipo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const saveVisit = async ({ ofe_codigo, emp_codigo }) => {
  try {
    const { data } = await api.post('/api/ofertas/salvar-visita', {
      ofe_codigo,
      emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyOffers = async ({ emp_codigo, seg_codigo, situacao, ofe_tipo, com_valor, a_combinar }) => {
  try {
    const { data } = await api.post('/api/ofertas/carregar-minhas-ofertas', {
      emp_codigo,
      seg_codigo,
      situacao,
      ofe_tipo,
      com_valor,
      a_combinar,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyOffersFilterData = async (emp_codigo) => {
  try {
    const { data } = await api.post('/api/ofertas/dados-filtro-minhas-ofertas', { emp_codigo });
    return data;
  } catch (error) {
    return error;
  }
};

export const getOfferDetails = async (ofe_codigo) => {
  try {
    const { data } = await api.post('/api/ofertas/carregar-detalhes-oferta', {
      ofe_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getOffersPerAssociate = async (emp_codigo) => {
  try {
    const { data } = await api.post('/api/ofertas/carregar-lista-ofertas', {
      emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const autoCompleteAssociado = async (query) => {
  try {
    const {
      data: { payload },
    } = await api.get('/api/ofertas/auto-complete-associado', { params: { q: query } });
    return payload;
  } catch (error) {
    return error;
  }
};

export const saveOffer = async (offer) => {
  try {
    const { data } = await api.post('/api/ofertas/salvar', offer);
    return data;
  } catch (error) {
    return error;
  }
};

export const finishOffer = async (ofe_codigo) => {
  try {
    const { data } = await api.post('/api/ofertas/encerrar', { ofe_codigo: ofe_codigo });
    return data;
  } catch (error) {
    return error;
  }
};

/**
 * Carrega as ofertas dos associados que estão pendentes de liberação. Carrega ofertas pelo código da franquia
 */
export const getOfferToRelease = async ({ emp_codigo, emp_codigos, seg_codigo, ofe_tipo, com_valor, a_combinar }) => {
  try {
    const { data } = await api.post('/api/ofertas/carregar-ofertas-liberacao', {
      emp_codigo,
      emp_codigos,
      seg_codigo,
      ofe_tipo,
      com_valor,
      a_combinar,
    });
    return data;
  } catch (error) {
    return error;
  }
};

/**
 * Busca os dados para o filtro da franquia das ofertas pendentes de liberação
 * @param {string | number} emp_codigo
 */
export const getOfferToReleaseFilterData = async (emp_codigo) => {
  try {
    const { data } = await api.post('/api/ofertas/dados-filtro-ofertas-liberacao', { emp_codigo });
    return data;
  } catch (error) {
    return error;
  }
};
