import React, { useRef, useState } from 'react';
import { ControlledSelectProps } from '~/types';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { OptionProps } from 'react-select/src/types';
// import StateManager from '@types'

/**
 * @deprecated
 * Opte por utilizar o componente SelectControlled
 */
const ControlledSelect: React.FunctionComponent<ControlledSelectProps> = ({
  label,
  name,
  hasError,
  isRequired,
  control,
  defaultValue,
  options,
  onBlur,
  keyComponent,
  isDisabled,
  isLoading,
}) => {
  const selectRef = useRef<HTMLDivElement | any>(null);

  return (
    <div className={`form-group ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      <label>
        {label}
        {isRequired && <span className="star" />}
      </label>
      <Controller
        name={name}
        control={control}
        key={keyComponent}
        defaultValue={defaultValue}
        onFocus={() => {
          selectRef.current.focus();
        }}
        render={({ value, onChange }) => (
          <Select
            // menuShouldScrollIntoView={false}
            ref={selectRef}
            openMenuOnFocus
            className="react-select-container"
            classNamePrefix="react-select"
            value={options.find((o) => o.value === value)}
            isDisabled={isDisabled}
            isLoading={isLoading}
            placeholder="Selecione"
            onChange={(e) => onChange(e?.value)}
            onBlur={(e) => {
              onBlur && onBlur(e);
            }}
            options={options}
          />
        )}
      />
    </div>
  );
};

export default ControlledSelect;
