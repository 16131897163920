import api from '~/config/api';

export const searchByZipCode = async (cep) => {
  try {
    const { data } = await api.post('/api/lista-enderecos/consultar-por-cep-ou-logradouro', {
      campo: 'pesquisarPorCep',
      valor: cep
    })
    return data
  } catch (error) {
    return { success: false, error }
  }
}

export const getCitiesByState = async (uf) => {
  try {
    const { data } = await api.post('/api/lista-enderecos/carregar-cidades-por-u-f', {
      uf: uf
    })
    return data
  } catch (error) {
    return { success: false, error }
  }
}