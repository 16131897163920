import React from 'react';
import Select from 'react-select';
import { NegotiationSituation } from '~/enum';
import { ModalFilterNegotiations } from '~/types';

const ModalFilter: React.FunctionComponent<ModalFilterNegotiations> = ({ filtersState, fetchMyNegotiations }) => {
  const status = [
    {
      value: NegotiationSituation.Fechado,
      label: 'Fechado',
    },
    {
      value: NegotiationSituation.Negociando,
      label: 'Em Negociação',
    },
    {
      value: NegotiationSituation.Recusado,
      label: 'Recusado',
    },
  ];

  return (
    <div className="modal fade" id="filtro" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Filtrar negociações...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form id='formFilter'>
                      <div className="form-group">
                        <label>Início</label>
                        <input
                          type="date"
                          max='2999-12-31'
                          className="form-control"
                          value={filtersState.filters.startDate}
                          onChange={(e) => filtersState.setFilters({ ...filtersState.filters, startDate: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label>Fim</label>
                        <input
                          type="date"
                          max='2999-12-31'
                          className="form-control"
                          value={filtersState.filters.endDate}
                          onChange={(e) => filtersState.setFilters({ ...filtersState.filters, endDate: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label>Situação</label>
                        <Select
                          closeMenuOnSelect={false}
                          placeholder="Todas"
                          isMulti
                          isSearchable
                          options={status}
                          onChange={(e) => filtersState.setFilters({ ...filtersState.filters, status: e })}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" data-dismiss="modal" onClick={fetchMyNegotiations} form='formFilter'>
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilter;
