import React, { useContext, useState } from 'react';

import { getDashboardFranquia } from '~/service/DashboardService';
import { AuthContext } from '~/context/AuthContext';
import moment, { Moment } from 'moment';
import ViewDashboard from '~/views/dashboard';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#413ea0', '#82ca9d'];

const Dashboard: React.FC = () => {
  const { company: empresa } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);

  const [filterStartDate, setFilterStartDate] = useState(moment());
  const [filterEndDate, setFilterEndDate] = useState(moment());

  const [startDate, setStartDate] = useState(moment().subtract(5, 'months'));
  const [endDate, setEndDate] = useState(moment());

  const fetchDashboard = async () => {
    const {
      payload: {
        totalVendasXMes,
        ticketMedio,
        totalTransacaoXMes,
        top10Associados,
        QUADROS,
        perfilTransacoes,
        saldoInicioGraficoRede,
        saldoRede,
        totalAssociadosPorSegmento,
        totalMesCorrente,
        totalMesPassado,
        qtdNegociacoesMes,
        qtdNegociacoesMesPassado,
        ultimasNegociacoes,
      },
    } = await getDashboardFranquia({
      EMP_TIPO: empresa.EMP_TIPO,
      EMP_CODIGO: empresa.EMP_CODIGO,
      EMP_CODMATRIZ: empresa.EMP_CODMATRIZ,
      DATA_INICIO: filterStartDate.format('YYYY-MM'),
      DATA_FIM: filterEndDate.format('YYYY-MM'),
      DATA_INICIO_GRAFICO: startDate.format('YYYY-MM'),
      DATA_FIM_GRAFICO: endDate.format('YYYY-MM'),
    });

    return {
      totalVendasXMes,
      ticketMedio,
      totalTransacaoXMes,
      top10Associados,
      QUADROS,
      perfilTransacoes,
      saldoInicioGraficoRede,
      saldoRede,
      totalAssociadosPorSegmento,
      totalMesCorrente,
      totalMesPassado,
      qtdNegociacoesMes,
      qtdNegociacoesMesPassado,
      ultimasNegociacoes,
    };
  };

  const changeFilterEndDate = (state: Moment) => {
    setFilterEndDate(state);

    setStartDate(state.clone().subtract(5, 'months'));
    setEndDate(state.clone());
  };

  return (
    <ViewDashboard
      isLoadingState={{ isLoading, setIsLoading }}
      handleFetchDashboard={fetchDashboard}
      filterStartDate={filterStartDate}
      filterEndDate={filterEndDate}
      changeFilterStartDate={setFilterStartDate}
      changeFilterEndDate={changeFilterEndDate}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default Dashboard;
