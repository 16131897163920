import React, { useState, useContext, useEffect } from 'react';
import { baseURL } from '~/config/api';

import { compareStrings, downloadDocuments, formatNumberToMoney } from '~/utils/util';
import { BalanceSheetData, BalanceSheetFormatted, FilterBalanceSheet } from '~/types';
import { getBalanceSheetData } from '~/service/financeiro/BalanceSheetService';
import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger } from '~/components/notification';
import ViewBalancete from '~/views/relatorioBalancete';

const Balancete: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [filterState, setFilterState] = useState<FilterBalanceSheet>({
    mes: new Date().getMonth() + 1,
    ano: new Date().getFullYear(),
    baixados: 'S',
    tipo_data: 'LAN_DATARECEBIDO',
  });
  const [balanceSheetDatas, setBalanceSheetDatas] = useState<BalanceSheetFormatted[]>([]);

  const [cardPreviousBalance, setCardPreviousBalance] = useState('');
  const [cardIncome, setCardIncome] = useState('');
  const [cardExpense, setCardExpense] = useState('');
  const [cardFinalBalance, setCardFinalBalance] = useState('');

  useEffect(() => {
    fetchBalanceSheetData({
      mes: filterState.mes,
      ano: filterState.ano,
      baixados: filterState.baixados,
      tipo_data: filterState.tipo_data,
    });
  }, [filterState]);

  const fetchBalanceSheetData = async (params: FilterBalanceSheet) => {
    setIsLoading(true);
    const { success, error, payload } = await getBalanceSheetData({
      emp_codigo: company.EMP_CODIGO,
      mes: params.mes,
      ano: params.ano,
      baixados: params.baixados,
      tipo_data: params.tipo_data,
    });
    setIsLoading(false);

    if (success) {
      const { dadosEstruturaPc, saldoAnterior }: { dadosEstruturaPc: BalanceSheetData[]; saldoAnterior: string } =
        payload;

      const balanceAux = mountTable(dadosEstruturaPc);

      const income = balanceAux.find((balance) => balance.ECC_DESCRICAO === 'Receita')?.VALOR || 0;
      const expense = balanceAux.find((balance) => balance.ECC_DESCRICAO === 'Despesa')?.VALOR || 0;

      setCardPreviousBalance(formatNumberToMoney(+saldoAnterior));
      setCardIncome(formatNumberToMoney(income));
      setCardExpense(formatNumberToMoney(expense));
      setCardFinalBalance(formatNumberToMoney(+saldoAnterior + (income || 0) - (expense || 0)));

      setBalanceSheetDatas(balanceAux);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  /**
   * Mount the dataset for the balance sheet table
   * @param dadosEstruturaPc data fetched from back-end
   * @returns table-ready formatted dataset
   */
  const mountTable = (dadosEstruturaPc: BalanceSheetData[]): BalanceSheetFormatted[] => {
    //Converting values in string to number, replacing commas
    const balanceSheetDatasAux: BalanceSheetFormatted[] = dadosEstruturaPc
      .filter(
        (dado) =>
          (dado.ECC_DESATIVADO != 'S' || (dado.ECC_DESATIVADO == 'S' && dado.VALOR != '0,00')) &&
          dado.ECC_DESCRICAO !== ''
      )
      .map((dado) => ({
        ...dado,
        VALOR_TRES_MESES: +dado.VALOR_TRES_MESES.replace('.', '').replace(',', '.'),
        VALOR_TRES_MESES_PERCENTUAL: 0,
        VALOR_DOIS_MESES: +dado.VALOR_DOIS_MESES.replace('.', '').replace(',', '.'),
        VALOR_DOIS_MESES_PERCENTUAL: 0,
        VALOR_UM_MES: +dado.VALOR_UM_MES.replace('.', '').replace(',', '.'),
        VALOR_UM_MES_PERCENTUAL: 0,
        VALOR: +dado.VALOR.replace('.', '').replace(',', '.'),
        VALOR_PERCENTUAL: 0,
      }));

    //Make the sum of all the incomes
    const income: BalanceSheetFormatted = reduceValues(
      {
        ECC_NUMARVORE: '1',
        ECC_DESCRICAO: 'Receita',
        ECC_TIPO: 'R',
        VALOR_TRES_MESES: 0,
        VALOR_TRES_MESES_PERCENTUAL: 0,
        VALOR_DOIS_MESES: 0,
        VALOR_DOIS_MESES_PERCENTUAL: 0,
        VALOR_UM_MES: 0,
        VALOR_UM_MES_PERCENTUAL: 0,
        VALOR: 0,
        VALOR_PERCENTUAL: 0,
      } as BalanceSheetFormatted,
      balanceSheetDatasAux.filter((dado) => dado.ECC_TIPO === 'R')
    );

    //Make the sum of all the expenses
    const expense: BalanceSheetFormatted = reduceValues(
      {
        ECC_NUMARVORE: '2',
        ECC_DESCRICAO: 'Despesa',
        ECC_TIPO: 'D',
        VALOR_TRES_MESES: 0,
        VALOR_TRES_MESES_PERCENTUAL: 0,
        VALOR_DOIS_MESES: 0,
        VALOR_DOIS_MESES_PERCENTUAL: 0,
        VALOR_UM_MES: 0,
        VALOR_UM_MES_PERCENTUAL: 0,
        VALOR: 0,
        VALOR_PERCENTUAL: 0,
      } as BalanceSheetFormatted,
      balanceSheetDatasAux.filter((dado) => dado.ECC_TIPO === 'D')
    );

    //merge variables with an array
    const balanceSheetDatasToSortAux = [
      income,
      expense,
      //Make the sum for each category row
      ...balanceSheetDatasAux.map((balance) =>
        balance.ECC_NUMARVORE.split('.').length === 2
          ? reduceValues(
              balance,
              balanceSheetDatasAux.filter((subBalance) => balance.ECC_NUMARVORE == subBalance.ECC_SUPERIOR)
            )
          : balance
      ),
    ];

    //Sorting array and return then
    return balanceSheetDatasToSortAux
      .map((dado) => ({
        ...dado,
        VALOR_TRES_MESES_PERCENTUAL:
          dado.VALOR_TRES_MESES / (dado.ECC_TIPO === 'D' ? expense.VALOR_TRES_MESES : income.VALOR_TRES_MESES) || 0,
        VALOR_DOIS_MESES_PERCENTUAL:
          dado.VALOR_DOIS_MESES / (dado.ECC_TIPO === 'D' ? expense.VALOR_DOIS_MESES : income.VALOR_DOIS_MESES) || 0,
        VALOR_UM_MES_PERCENTUAL:
          dado.VALOR_UM_MES / (dado.ECC_TIPO === 'D' ? expense.VALOR_UM_MES : income.VALOR_UM_MES) || 0,
        VALOR_PERCENTUAL: dado.VALOR / (dado.ECC_TIPO === 'D' ? expense.VALOR : income.VALOR) || 0,
      }))
      .sort((a, b) => compareStrings(a.ECC_NUMARVORE, b.ECC_NUMARVORE));
  };

  //Reduce month values and return then to mount category rows
  const reduceValues = (initialValue: BalanceSheetFormatted, toReduce: BalanceSheetFormatted[]) => {
    return toReduce.reduce(
      (accumulator, current) => ({
        ...accumulator,
        VALOR_TRES_MESES: accumulator.VALOR_TRES_MESES + current.VALOR_TRES_MESES,
        VALOR_DOIS_MESES: accumulator.VALOR_DOIS_MESES + current.VALOR_DOIS_MESES,
        VALOR_UM_MES: accumulator.VALOR_UM_MES + current.VALOR_UM_MES,
        VALOR: accumulator.VALOR + current.VALOR,
      }),
      initialValue
    );
  };

  const exportToExcel = () => {
    const tipo_data = filterState.tipo_data;
    const mes = filterState.mes;
    const ano = filterState.ano;
    const baixados = filterState.baixados;

    const url = `${baseURL}/api/balancete/exportar/${tipo_data}/${mes}/${ano}/${baixados}/${company.EMP_CODIGO}`;
    downloadDocuments(url, 'Balancete - ' + ano + '_' + ano + '.xls', false);
  };

  return (
    <ViewBalancete
      isLoading={isLoading}
      filterState={filterState}
      balanceSheetDatas={balanceSheetDatas}
      cards={{
        cardPreviousBalance,
        cardIncome,
        cardExpense,
        cardFinalBalance,
      }}
      exportToExcel={exportToExcel}
      onSubmitFilters={(params) => setFilterState(params)}
    />
  );
};

export default Balancete;
