import React from 'react';
import PageLoading from '~/components/loading/PageLoading';
import { ModalProps } from '~/types';

const defaultProps = {
  closeOnEscape: true,
  dismissOnClickOut: true,
};

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  ({ id, ariaLabelledby, size, closeOnEscape, dismissOnClickOut, hiddenCloseButton, isLoading, cardStyle, header, body, footer }, ref) => {
    const getSize = (selectedSize: typeof size) => {
      if (selectedSize === 'sm') return '';
      else if (selectedSize === 'md') return 'modal-lg';
      else if (selectedSize === 'lg') return 'modal-lg modal-extra-lg';
      else if (selectedSize === 'xl') return 'modal-lg modal-extra-lg modal-extra-extra-lg';
    };

    return (
      <div
        className="modal fade"
        id={id}
        data-backdrop={dismissOnClickOut ? true : 'static'}
        data-keyboard={closeOnEscape}
        role="dialog"
        aria-labelledby={ariaLabelledby}
        aria-hidden="true"
        tabIndex={-1}
        ref={ref}
      >
        <div className={`modal-dialog ${getSize(size)}`} role="document">
          <div className="modal-content">
            {isLoading && <PageLoading center />}

            {cardStyle ? (
              <div className="modal-body" style={{ paddingTop: 0 }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card stacked-form">
                      <div className="card-header ">{typeof header === 'function' ? header() : <h4 className="card-title">{header}</h4>}</div>
                      <div className="card-body " style={{ paddingTop: 0 }}>
                        {body()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="modal-header">
                  {typeof header === 'function' ? header() : <h3 className="modal-title">{header}</h3>}
                  {!hiddenCloseButton && (
                    <button
                      type="button"
                      className="close clickable"
                      onClick={() => {
                        $(`#${id}`).modal('hide');
                      }}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  )}
                </div>
                <div className="modal-body">{body()}</div>
              </>
            )}

            <div className="modal-footer">{footer && footer()}</div>
          </div>
        </div>
      </div>
    );
  }
);

Modal.defaultProps = defaultProps;

export default Modal;
