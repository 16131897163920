import React from 'react';
import { ImagemFlutuanteInterface } from '~/types';
import './styles.css'

const ImagemFlutuante: React.FunctionComponent<ImagemFlutuanteInterface> = (props) => {
  return (
    <>
      {props.type === 'zoom' && (
        <div className="img-container div-zoom text-center">
          <img src={props.imageSRC} style={{ ...props.imageStyle, height: '100%', width: 'auto' }} alt="..." />
        </div>
      )}
      {props.type === 'hover' && (
        <div className="img-container div-hover text-center">
          <img src={props.imageSRC} style={{ ...props.imageStyle, height: '100%', width: 'auto' }} alt="..." />
          <div className="div-on-hover">
            <img src={props.imageSRC} alt="..." />
          </div>
        </div>
      )}
    </>
  );
};

export default ImagemFlutuante;
