import React, { useContext, useEffect, useState } from 'react';
import { ExcludeTypes, Finance, FormWriteOffFinance, ModalWriteOffFinanceProps } from '~/types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '~/context/AuthContext';
import SelectControlled from '~/components/input/SelectControlled';
import BasicInput from '~/components/input/BasicInput';
import moment from 'moment';
import { closeModal, correctNumberWithComma, formatNumberToMoney, formatNumberWithoutPrefix } from '~/utils/util';
import MaskMoneyControlled from '~/components/input/MaskMoneyControlled';
import BasicTextarea from '~/components/input/BasicTextarea';
import api from '~/config/api';
import { writeOffFinances } from '~/service/financeiro/FinanceService';
import { Badge } from 'react-bootstrap';
import ButtonDefault from '~/components/button/ButtonDefault';

const whenHas = (has: boolean, schema: yup.NumberSchema | yup.DateSchema) => (has ? schema.required() : schema);

const schema: yup.SchemaOf<FormWriteOffFinance> = yup.object().shape({
  cba_codigo_receita: yup.number().when('$hasIncome', whenHas),
  fpa_codigo_receita: yup.number().when('$hasIncome', whenHas),
  lan_datarecebido_receita: yup.date().when('$hasIncome', whenHas),

  cba_codigo_despesa: yup.number().when('$hasExpense', whenHas),
  fpa_codigo_despesa: yup.number().when('$hasExpense', whenHas),
  lan_datarecebido_despesa: yup.date().when('$hasExpense', whenHas),
});

const ModalWriteOffFinance: React.FunctionComponent<ModalWriteOffFinanceProps> = ({
  isLoading,
  onSubmit,
  financesChecked,
  bankAccounts,
  paymentOptions,
}) => {
  useEffect(() => {
    reset();
  }, [financesChecked]);

  const filterFinances =
    (type: 'R' | 'D') =>
    ({ ECC_TIPO }: Finance) =>
      ECC_TIPO === type;

  const reduceFinances = (acc: number, { ECC_TIPO, LAN_VALOR }: Finance) =>
    ECC_TIPO === 'R' ? acc + correctNumberWithComma(LAN_VALOR) : acc - correctNumberWithComma(LAN_VALOR);

  const { control, handleSubmit, errors, reset } = useForm<FormWriteOffFinance>({
    resolver: yupResolver(schema),
    context: {
      hasIncome: financesChecked.some(filterFinances('R')),
      hasExpense: financesChecked.some(filterFinances('D')),
    },
  });

  const handleOnSubmit = async (params: FormWriteOffFinance) => {
    await onSubmit(params);
  };

  return (
    <div className="modal fade" id="ModalWriteOffFinance" role="dialog" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card-header ">
                  <h4 className="card-title">
                    Baixar Lançamentos <Badge variant="secondary">{financesChecked.length}</Badge>
                  </h4>
                </div>

                <div className="card-body " style={{ paddingTop: 0 }}>
                  <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="ModalWriteOffFinanceFormId">
                    <h5 className="m-0 mt-4">
                      Receitas <Badge variant="secondary">{financesChecked.filter(filterFinances('R')).length}</Badge>
                    </h5>
                    <SelectControlled
                      isDisabled={!financesChecked.some(filterFinances('R'))}
                      isRequired
                      control={control}
                      defaultValue={undefined}
                      hasError={!!errors.cba_codigo_receita}
                      name="cba_codigo_receita"
                      label="Conta Bancária"
                      options={bankAccounts.map((bankAccount) => ({ value: bankAccount.CBA_CODIGO, label: bankAccount.CBA_DESCRICAO }))}
                    />
                    <SelectControlled
                      isDisabled={!financesChecked.some(filterFinances('R'))}
                      isRequired
                      control={control}
                      defaultValue={undefined}
                      hasError={!!errors.fpa_codigo_receita}
                      name="fpa_codigo_receita"
                      label="Forma Recebimento"
                      options={paymentOptions.map((paymentOption) => ({
                        value: paymentOption.FPA_CODIGO,
                        label: paymentOption.FPA_DESCRICAO,
                      }))}
                    />
                    <BasicInput
                      isDisabled={!financesChecked.some(filterFinances('R'))}
                      isRequired
                      control={control}
                      type="date"
                      defaultValue={moment().format('YYYY-MM-DD')}
                      hasError={!!errors.lan_datarecebido_receita}
                      name="lan_datarecebido_receita"
                      label="Data Recebimento"
                    />

                    <h5 className="m-0 mt-4">
                      Despesas <Badge variant="secondary">{financesChecked.filter(filterFinances('D')).length}</Badge>
                    </h5>
                    <SelectControlled
                      isDisabled={!financesChecked.some(filterFinances('D'))}
                      isRequired
                      control={control}
                      defaultValue={undefined}
                      hasError={!!errors.cba_codigo_despesa}
                      name="cba_codigo_despesa"
                      label="Conta Bancária"
                      options={bankAccounts.map((bankAccount) => ({ value: bankAccount.CBA_CODIGO, label: bankAccount.CBA_DESCRICAO }))}
                    />
                    <SelectControlled
                      isDisabled={!financesChecked.some(filterFinances('D'))}
                      isRequired
                      control={control}
                      defaultValue={undefined}
                      hasError={!!errors.fpa_codigo_despesa}
                      name="fpa_codigo_despesa"
                      label="Forma Recebimento"
                      options={paymentOptions.map((paymentOption) => ({
                        value: paymentOption.FPA_CODIGO,
                        label: paymentOption.FPA_DESCRICAO,
                      }))}
                    />
                    <BasicInput
                      isDisabled={!financesChecked.some(filterFinances('D'))}
                      isRequired
                      control={control}
                      type="date"
                      defaultValue={moment().format('YYYY-MM-DD')}
                      hasError={!!errors.lan_datarecebido_despesa}
                      name="lan_datarecebido_despesa"
                      label="Data Recebimento"
                    />

                    <div className="alert-alt alert-warning-alt mt-4" role="alert">
                      <strong>Atenção!</strong> Será feita a baixa de{' '}
                      <strong>{financesChecked.length === 1 ? '1 lançamento' : `${financesChecked.length} lançamentos`}.</strong>
                      <br />
                      Receitas: <strong>{formatNumberToMoney(financesChecked.filter(filterFinances('R')).reduce(reduceFinances, 0))}</strong>
                      <br />
                      Despesas: <strong>{formatNumberToMoney(financesChecked.filter(filterFinances('D')).reduce(reduceFinances, 0))}</strong>
                      <br />
                      Valor Total: <strong>{formatNumberToMoney(financesChecked.reduce(reduceFinances, 0))}</strong>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <ButtonDefault form="ModalWriteOffFinanceFormId" isLoading={isLoading} disabled={isLoading}>
              <i className="fas fa-check"></i> Baixar
            </ButtonDefault>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWriteOffFinance;
