import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AuthContext } from '~/context/AuthContext';
import { CidadeInterface, ViewModalFilterAssociatesProps, OptionProps, SegmentoInterface } from '~/types';
import { getFilterData } from '~/service/AssociateService';

const ModalFilter: React.FunctionComponent<ViewModalFilterAssociatesProps> = ({ filtersState, fetchAssociates }) => {
  const { company: empresa } = useContext(AuthContext);

  const [segmentos, setSegmentos] = useState([] as Array<OptionProps>);
  const [cidades, setCidades] = useState([] as Array<OptionProps>);

  useEffect(() => {
    getFilterData().then(({ payload }) => {
      setSegmentos(payload.segmentos.map((segmento: SegmentoInterface) => ({ value: segmento.SEG_CODIGO, label: segmento.SEG_DESCRICAO })));
      setCidades(payload.cidades.map((cidade: CidadeInterface) => ({ value: cidade.LEN_CIDIBGE, label: cidade.CIDADE })));
    });
  }, []);

  return (
    <div className="modal fade" id="filtro" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Buscar associados...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form method="#" action="#" id='formFilter'>
                      <div className="form-group">
                        <label>Segmentos</label>
                        <div className="col-md-12">
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Todos"
                            isMulti
                            isSearchable
                            options={segmentos}
                            onChange={(e) => {
                              filtersState.setFilters({ ...filtersState.filters, filterSegment: e });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Cidades</label>
                        <div className="col-md-12">
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Todas"
                            isMulti
                            isSearchable
                            options={cidades}
                            onChange={(e, a) => {
                              filtersState.setFilters({ ...filtersState.filters, filterCity: e });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Ofertas dos Associados</label>
                        <div className="form-check form-check-radio">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="radio"
                              value={empresa.EMP_CODIGO}
                              checked={filtersState.filters.filterFranchise === empresa.EMP_CODIGO.toString()}
                              onChange={(e) => {
                                e.target.checked && filtersState.setFilters({ ...filtersState.filters, filterFranchise: e.target.value });
                              }}
                            />
                            <span className="form-check-sign"></span>
                            Da minha Franquia
                          </label>
                        </div>

                        <div className="form-check form-check-radio">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="0"
                              checked={filtersState.filters.filterFranchise !== empresa.EMP_CODIGO.toString()}
                              onChange={(e) => {
                                e.target.checked && filtersState.setFilters({ ...filtersState.filters, filterFranchise: e.target.value });
                              }}
                            />
                            <span className="form-check-sign"></span>
                            De Todas franquias
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" data-dismiss="modal" onClick={fetchAssociates} form="formFilter">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilter;
