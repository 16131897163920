import React, {  } from 'react';

import IndicatorCard from '~/components/card/IndicatorCard';

import {
  correctNumberWithComma,
  formatNumberWithoutPrefix,
  getMonth,
  randomColorGoldenAngle,
} from '~/utils/util';
import TableWithLinks from '~/components/table/TableWithLinks';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Text, XAxis, YAxis } from 'recharts';
import LayoutMain from '~/layout/main';
import { ViewBaseProps } from '~/types';

const ViewBase: React.FunctionComponent<ViewBaseProps> = ({
  isLoading,
  cards: { activeAssociates, blockedAssociates, inactiveAssociates, newers, segments },
  associadosAtivosPorMes,
  maioresAssociados,
  principaisSegmentos,
  novosAssociados,
  headerAppend,
}) => {
  const CustomizedXAxisTick = ({ x, y, payload, color, valueChosen, ...rest }: any) => {
    return (
      <Text
        x={x}
        y={y}
        width={40}
        textAnchor="middle"
        verticalAnchor="start"
        style={{ fontSize: '8pt' }}
        stroke={randomColorGoldenAngle(color({ x, y, payload, ...rest }))}
        strokeWidth=".5"
      >
        {valueChosen({ x, y, payload, ...rest })}
      </Text>
    );
  };

  return (
    <LayoutMain isLoading={isLoading} title="Base" headerAppend={headerAppend}>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
          <IndicatorCard
            icon="nc-icon nc-single-02 text-primary"
            label="Associados Ativos"
            value={activeAssociates.value}
            title="Variação"
            valueSecondary={activeAssociates.variation}
          />
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
          <IndicatorCard
            icon="nc-icon nc-lock-circle-open text-secondary"
            label="Bloqueados"
            value={blockedAssociates.value}
            title="Variação"
            valueSecondary={blockedAssociates.variation}
          />
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
          <IndicatorCard
            icon="nc-icon nc-single-02 text-secondary"
            label="Inativos"
            value={inactiveAssociates.value}
            title="Variação"
            valueSecondary={inactiveAssociates.variation}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <IndicatorCard
            icon="nc-icon nc-single-02 text-success"
            label="Novos no Mês"
            value={newers.value}
            title="Variação"
            valueSecondary={newers.variation}
          />
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <IndicatorCard icon="fas fa-barcode text-secondary" label="Segmentos" value={segments} title="Segmentos" />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header ">
              <h4 className="card-title">Associados ativos por mês</h4>
              <p className="card-category">Quantidade de associados ativos por mês</p>
            </div>
            <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <ResponsiveContainer height={230} width={'100%'} className="graph-label">
                <BarChart data={associadosAtivosPorMes}>
                  <XAxis
                    dataKey="data"
                    tick={
                      <CustomizedXAxisTick
                        valueChosen={({ payload: { value } }: any) => `${getMonth(+value.split('-')[1])} 20${value.split('-')[0]}`}
                        color={({ payload: { value } }: any) => value.split('-')[0]}
                      />
                    }
                  />
                  <YAxis dataKey="value" />
                  {/* <Tooltip formatter={(e) => [e, 'Valor']} cursor={false} /> */}
                  <Bar dataKey="value" fill="#8884d8">
                    {associadosAtivosPorMes.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={randomColorGoldenAngle(+entry.data.split('-')[0])} />
                    ))}
                    <LabelList dataKey="value" position="top" offset={5} angle={0} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header ">
              <h4 className="card-title">Maiores Associados</h4>
              {/* <p className="card-category"></p> */}
            </div>
            <div className="card-body">
              <TableWithLinks
                emptyTableMessage="Nenhuma transação registrada"
                columns={[
                  { field: 'EMP_NOMEFANTASIA', label: '' },
                  { field: 'value', label: '', alignment: 'text-right' },
                ]}
                data={maioresAssociados.map((associado) => ({
                  ...associado,
                  value: <span style={{ color: 'blue' }}>{formatNumberWithoutPrefix(correctNumberWithComma(associado.VENDACOMPRA))}</span>,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header ">
              <h4 className="card-title">Principais Segmentos</h4>
              {/* <p className="card-category"></p> */}
            </div>
            <div className="card-body">
              <TableWithLinks
                emptyTableMessage="Nenhuma transação registrada"
                columns={[
                  { field: 'SEG_DESCRICAO', label: '' },
                  { field: 'TOTAL', label: '', alignment: 'text-right' },
                ]}
                data={principaisSegmentos}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header ">
              <h4 className="card-title">Novos Associados</h4>
              {/* <p className="card-category"></p> */}
            </div>
            <div className="card-body">
              <TableWithLinks
                emptyTableMessage="Não houveram novos associados este mês"
                columns={[
                  { field: 'EMP_NOMEFANTASIA', label: '' },
                  { field: 'EMP_DATACADASTRO', label: '', alignment: 'text-right' },
                ]}
                data={novosAssociados}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewBase;
