import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { formatCNPJ, formatCPF } from '~/utils/util';
import SelectControlled from '~/components/input/SelectControlled';
import { Associate, FormTransferAssociates, Franchise, ModalTransferAssociatesProps } from '~/types';
import TableWithLinks from '~/components/table/TableWithLinks';
import { AuthContext } from '~/context/AuthContext';
import ButtonDefault from '~/components/button/ButtonDefault';
import SweetAlert from 'react-bootstrap-sweetalert';

const ModalTransferAssociates: React.FunctionComponent<ModalTransferAssociatesProps> = ({
  isLoading,
  associates,
  franchises,
  isAwaitResponse,
  onSubmit,
}) => {
  const { user, company } = useContext(AuthContext);

  const FormTransferAssociatesSchema: yup.SchemaOf<FormTransferAssociates> = yup.object().shape({
    associates: yup.array().of(yup.number().required()).required(),
    franchiseId: yup.number().required(),
  });

  const { control, register, handleSubmit, errors, reset, setValue, getValues } = useForm<FormTransferAssociates>({
    resolver: yupResolver(FormTransferAssociatesSchema),
  });

  const [sweetAlert, setSweetAlert] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    register('associates');
  }, []);

  useEffect(() => {
    setValue(
      'associates',
      associates.map(({ EMP_CODIGO }) => EMP_CODIGO)
    );
  }, [associates]);

  const handleOnSubmit = async ({ associates, franchiseId }: FormTransferAssociates) => {
    setSweetAlert(
      <SweetAlert
        warning
        title="Atenção!"
        onConfirm={() => confirmSubmit({ associates, franchiseId })}
        onCancel={() => setSweetAlert(undefined)}
        allowEscape={true}
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Sim"
        cancelBtnText="Não"
      >
        Tem certeza que deseja transferir esses associados para outra franquia?
      </SweetAlert>
    );
  };

  const confirmSubmit = async ({ associates, franchiseId }: FormTransferAssociates) => {
    setSweetAlert(undefined);
    onSubmit({ associates, franchiseId });
  };

  return (
    <div
      className="modal fade"
      id="ModalTransferAssociates"
      role="dialog"
      aria-labelledby="Modal-Filter"
      aria-hidden="true"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card-header ">
                  <h4 className="card-title">Transferir associado(s)</h4>
                </div>
              </div>
            </div>

            <TableWithLinks
              isLoading={isLoading}
              emptyTableMessage="Nenhum associado encontrado. Tente alterar os filtros."
              columns={[
                { field: 'CPFCNPJFormatted', label: 'CPF ou CNPJ', alignment: 'text-center', width: '150px' },
                { field: 'EMP_RAZAOSOCIAL', label: 'Razão Social' },
                { field: 'EMP_NOMEFANTASIA', label: 'N.fantasia' },
                { field: 'MATRIZ_NOMEFANTASIA', label: 'Franquia', alignment: 'text-center' },
                { field: 'ITI_DESCRICAO', label: 'Situação', alignment: 'text-center', width: '95px' },
              ]}
              data={associates.map((associate) => ({
                ...associate,
                CPFCNPJFormatted:
                  associate.EMP_CPFCNPJ.length === 11
                    ? formatCPF(associate.EMP_CPFCNPJ)
                    : formatCNPJ(associate.EMP_CPFCNPJ),
              }))}
            />

            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <form id="ModalTransferAssociatesFormId" onSubmit={handleSubmit(handleOnSubmit, console.log)}>
                    <div className="form-group">
                      <SelectControlled
                        isRequired
                        label="Franquia de destino"
                        name="franchiseId"
                        control={control}
                        defaultValue={undefined}
                        hasError={!!errors.franchiseId}
                        placeholder="Escolha..."
                        options={franchises.map((franchise) => ({
                          value: franchise.EMP_CODIGO,
                          label: franchise.EMP_NOMEFANTASIA,
                        }))}
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <ButtonDefault
              className="btn btn-success"
              isLoading={isLoading || isAwaitResponse}
              type="submit"
              form="ModalTransferAssociatesFormId"
            >
              <i className="fas fa-exchange-alt" /> Transferir
            </ButtonDefault>
          </div>
        </div>
      </div>
      {sweetAlert}
    </div>
  );
};

export default ModalTransferAssociates;
