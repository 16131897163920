import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { closeModal } from '~/utils/util';
import SelectControlled from '~/components/input/SelectControlled';
import moment from 'moment';
import Datetime from 'react-datetime';
import { FormFilterCashFlow, ModalFilterCashFlowProps } from '~/types';

const ViewModalFilterCashFlow: React.FunctionComponent<ModalFilterCashFlowProps> = ({ bankAccounts, onSubmit }) => {
  const { control, handleSubmit } = useForm<FormFilterCashFlow>({});

  const handleOnSubmit = async (params: FormFilterCashFlow) => {
    onSubmit(params);
    closeModal('ModalFilterCashFlow');
  };

  return (
    <div className="modal fade" id="ModalFilterCashFlow" role="dialog" aria-labelledby="Modal-Filter" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Filtrar fluxo de caixa</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="FormFilterCashFlowId">
                      <SelectControlled
                        isRequired
                        control={control}
                        defaultValue={'0'}
                        options={[
                          { value: '0', label: 'Todas as contas' },
                          ...bankAccounts.map((bankAccount) => ({ value: bankAccount.CBA_CODIGO, label: bankAccount.CBA_DESCRICAO })),
                        ]}
                        name="cba_codigo"
                        label="Conta Bancária"
                      />

                      <div className="form-group">
                        <label>Mês e ano</label>
                        <Controller
                          name="date"
                          control={control}
                          defaultValue={moment()}
                          render={({ onChange, value }) => (
                            <Datetime dateFormat="MMMM [de] YYYY" timeFormat={false} value={value} onChange={onChange} />
                          )}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-default btn-wd" form="FormFilterCashFlowId" type="submit">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModalFilterCashFlow;
