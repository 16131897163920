export enum UserType {
  ASSOCIADO,
  FRANQUIA,
}

export enum EmpType {
  ASSOCIADO = 'A',
  FRANQUIA = 'F',
  FRANQUEADORA = 'FF',
  MASTER_FRANQUIA = 'MF',
}

export enum OfferSituation {
  ABERTA = 'A',
  BLOQUEADA = 'B',
  LIBERADA = 'D',
  PENDENTE = 'P',
  ENCERRADA = 'E',
  REENVIADA = 'R',
}

export enum BasicResponse {
  SIM = 'S',
  NAO = 'N',
}

export enum NegotiationSituation {
  Negociando = 'E',
  Fechado = 'F',
  Recusado = 'R',
}

export enum VoucherStatus {
  ABERTO = 'Aberto',
  BAIXADO = 'Baixado',
}

export enum OfferType {
  VP = 'PADRAO',
  VPMIX = 'VPMIX',
}

export enum NegotiationType {
  COMPRA = 'Compra',
  VENDA = 'Venda',
}
