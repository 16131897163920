import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/app.css';
import 'react-datetime/css/react-datetime.css';
// import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/pt-br';
import '~/components/input/styles.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);