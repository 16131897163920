import React, {  } from 'react';

import TableWithLinks from '~/components/table/TableWithLinks';
import CardHeader from '~/components/card/CardHeader/index';
import { formatNumberWithoutPrefix, openModal } from '~/utils/util';
import NumberFormat from 'react-number-format';
import ModalFilterCashFlow from './ModalFilterCashFlow';
import { ViewFluxoCaixaProps } from '~/types';
import moment from 'moment';
import ModalRegistersTable from './ModalRegistersTable';
import LayoutMain from '~/layout/main';

const ViewFluxoCaixa: React.FunctionComponent<ViewFluxoCaixaProps> = ({
  isLoading,
  isLoadingRegister,
  previousBalanceState,
  cashFlowDataTable,
  bankAccounts,
  register,
  exportToExcel,
  showRegister,
  onSubmitFilters,
  onBalanceChange,
}) => {
  return (
    <LayoutMain isLoading={isLoading} title="Fluxo de Caixa">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <CardHeader
              title="Fluxo de Caixa"
              subtitle="Aplique os filtros para alterar a visualização do fluxo de caixa"
              filterAction={() => openModal('ModalFilterCashFlow')}
              extraActions={[{ title: 'Exportar para Excel', iconClass: 'far fa-file-excel', action: exportToExcel }]}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              <div className="form-group">
                <NumberFormat
                  className="form-control"
                  placeholder="Saldo"
                  isNumericString
                  allowNegative
                  value={previousBalanceState}
                  onValueChange={onBalanceChange}
                />
              </div>
            </div>

            <ModalFilterCashFlow bankAccounts={bankAccounts} onSubmit={onSubmitFilters} />

            <ModalRegistersTable isLoading={isLoadingRegister} incomes={register.incomes} expenses={register.expenses} />

            <div className="card-body table-full-width">
              <TableWithLinks
                tableBigBoy
                isLoading={isLoading}
                boldRow={(data) => data.dia === 'Saldo'}
                emptyTableMessage="Tente alterar os filtros."
                highlightOnHover
                striped
                onClickingRow={showRegister}
                highlightRow={(row) => moment(new Date(row.year, +row.mes - 1, +row.dia)).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')}
                tooltipInRow={(row) => {
                  const selectedDate = moment(new Date(row.year, +row.mes - 1, +row.dia));
                  const today = moment();

                  if (today.format('YYYY-MM') === selectedDate.format('YYYY-MM') && selectedDate.format('YYYY-MM-DD') >= today.format('YYYY-MM-DD'))
                    return 'REGRA: Com ou Sem baixa.';
                  else if (
                    today.format('YYYY-MM') === selectedDate.format('YYYY-MM') &&
                    selectedDate.format('YYYY-MM-DD') < today.format('YYYY-MM-DD')
                  )
                    return 'REGRA: Baixado(s).';
                  else return undefined;
                }}
                columns={[
                  { field: 'dia', label: 'Dia', alignment: 'text-left', width: '100px' },
                  { field: 'incomeFormatted', label: 'Receita', alignment: 'text-right', width: '200px' },
                  { field: 'expenseFormatted', label: 'Despesa', alignment: 'text-right', width: '200px' },
                  { field: 'previousBalanceFormatted', label: 'Saldo Anterior', alignment: 'text-right' },
                ]}
                data={cashFlowDataTable.map((data) => ({
                  ...data,
                  incomeFormatted: formatNumberWithoutPrefix(data.income),
                  expenseFormatted: formatNumberWithoutPrefix(data.expense),
                  previousBalanceFormatted: formatNumberWithoutPrefix(data.previousBalance),
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewFluxoCaixa;
