import React, { ButtonHTMLAttributes } from 'react';

type ButtonDefaultProps = {
  isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonDefault: React.FunctionComponent<ButtonDefaultProps> = ({
  children,
  isLoading,
  type,
  className,
  ...props
}) => {
  return (
    <button {...props} className={className ?? 'btn btn-default btn-wd'} type={type || 'submit'} disabled={isLoading}>
      {isLoading ? (
        <>
          <i className="fas fa-spinner rotating" /> Processando...
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default ButtonDefault;
