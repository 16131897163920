import React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './styles.css'

const Carousel: React.FunctionComponent = ({ children }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
      <Slider  {...settings}>
        {children}
      </Slider>
  );
};

export default Carousel;
